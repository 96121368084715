import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';

import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';
import { useAuth } from './useAuthV2';
import { useLocalStorage } from './useLocalStorage';

export interface ProfileContextProps {
  profile: API.IProfile;
  generationsRemaining: number;
  importsRemaining: number;
  loading: boolean;
  isSaving: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  fetchProfile: (background?: boolean) => Promise<void>;
  updateProfile: (firstName: string, lastName: string) => Promise<void>;
  uploadPhoto: (file: File) => Promise<void>;
  createSubscription: () => void;
}

const ProfileContext = createContext<ProfileContextProps>({} as ProfileContextProps);

export default ({ children }) => {
  const { logout } = useAuth();
  const [profile, setProfile] = useLocalStorage("user", null);
  const [generationsRemaining, setGenerationsRemaining] = useState<number>(0);
  const [importsRemaining, setImportsRemaining] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProfile = async (background: boolean = false) => {
    try {
      setLoading(!background);
      const userProfile = await API.Profile.getMe();
      setProfile(userProfile);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
        }
      }
      setError("Sorry, we weren't able to fetch your profile. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const updateProfile = async (firstName: string, lastName: string) => {
    try {
      setSaving(true);
      const updatedUser = await API.Profile.updateMe(firstName, lastName);
      setProfile(updatedUser);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
        }
      }
      setError("Sorry, we weren't able to update your profile. Please try again.");
    } finally {
      setSaving(false);
    }
  }

  const uploadPhoto = async (file: File) => {
    try {
      setSaving(true);
      setError(null);

      const updatedProfile = await API.Profile.uploadPhoto(file);
      setProfile(updatedProfile);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      setError("Whoops! Something went wrong while uploading your photo. Please try again.");
    } finally {
      setSaving(false);
    }
  }

  const createSubscription = async () => {
    try {
      setLoading(true);

      // Only for Free subscriptions
      await API.Profile.createUserSubscription();
      await fetchProfile();
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      setError("Whoops! Something went wrong while skipping the subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (profile?.usage) {
      setGenerationsRemaining(Math.max(profile.usage.total_grants - profile.usage.total_generations_used, 0));
      setImportsRemaining(Math.max(profile.usage.total_imports - profile.usage.total_imports_used, 0));
    }
  }, [ profile?.usage ]);

  const value = useMemo(() => ({
    profile,
    generationsRemaining,
    importsRemaining,
    loading,
    isSaving,
    error,
    setError,
    fetchProfile,
    updateProfile,
    uploadPhoto,
    createSubscription,
  }), [profile, loading, isSaving, error]);

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
}

export const useProfile = () =>
  useContext(ProfileContext);
