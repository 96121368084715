import React, { useMemo, memo } from 'react';

import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/joy';
import Card from '../../../components/containers/Card';
import FormInput from '../../../components/FormFields/Input';
import {
  IconSchool,
  IconTrash,
  IconChevronRight,
  IconChevronDown,
} from '@tabler/icons-react';

import * as API from '../../../integrations/api';
import { useCareerCanvas } from '../../../hooks/useCareerCanvas';
import Accomplishments from '../Accomplishments';

interface Props {
  education: API.IEducation;
  educationIndex: number;
}

export default memo((props: Props): React.ReactNode => {
  const { institution, degree } = useMemo(() => ({
    degree: props.education.degree,
    institution: props.education.institution,
  }), []);

  const [ education, setEducation ] = React.useState<API.IEducation>(props.education);
  const [hideEducation, setHideEducation] = React.useState<boolean>(
    degree === "New Education" ? false : true
  );
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleUpdateToCareerCanvas = (name: string, value: string) => {
    setEducation(edu => {
      const newEducation = {
        ...edu,
        [name]: value,
      }

      const newEducationList = careerCanvas.education.map((edu, i) => {
        if (i === props.educationIndex) {
          return newEducation;
        }
        return edu;
      })

      setCareerCanvas({
        ...careerCanvas,
        education: newEducationList,
      });

      return newEducation;
    });
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateToCareerCanvas(e.target.name, e.target.value);
  }

  const handleDeleteEducation = () => {
    const newEducationList = careerCanvas.education
      .filter(edu => edu.id !== education.id);

    setCareerCanvas({
      ...careerCanvas,
      education: newEducationList,
    });
  }

  return (
    <Card>
      <Stack
        direction="column"
        gap={2}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          sx={{
            pb: 2,
            borderBottom: "1px solid #d1d1d1",
          }}
        >
          <IconSchool stroke={1} />
          <Typography level="title-lg">
            {degree}
          </Typography>
          <Typography level="body-md" color="neutral">
            |
          </Typography>
          <Typography
            level="body-sm"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {institution}
          </Typography>

          <IconButton
            sx={{
              ml: "auto",
            }}
            onClick={() => setHideEducation(!hideEducation)}
          >
            {
              hideEducation
                ? <IconChevronRight stroke={1} />
                : <IconChevronDown stroke={1} />
            }
          </IconButton>
        </Stack>

        {
          !hideEducation && (
            <>
              <FormInput
                label="Degree"
                name="degree"
                value={education.degree}
                onChange={handleUpdateField}
              />

              <FormInput
                label="Institution"
                name="institution"
                value={education.institution}
                onChange={handleUpdateField}
              />

              <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Start Date"
                    type="date"
                    name="start_date"
                    value={education.start_date}
                    onChange={handleUpdateField}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="End Date"
                    type="date"
                    name="end_date"
                    value={education.end_date}
                    onChange={handleUpdateField}
                  />
                </Grid>
              </Grid>

              <Accomplishments
                accomplishments={education.accomplishments}
                index={props.educationIndex}
                typeKey="education"
              />

              <Stack
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  color="danger"
                  sx={{
                    borderRadius: 24,
                  }}
                  startDecorator={
                    <IconTrash stroke={1} />
                  }
                  onClick={handleDeleteEducation}
                >
                  Delete Education
                </Button>
              </Stack>
            </>
          )
        }

      </Stack>
    </Card>
  );
});