import React, { useEffect } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  Sheet,
  Box,
  LinearProgress,
} from "@mui/joy";
import Dropzone from 'react-dropzone';
import { IconCameraPlus, IconX } from "@tabler/icons-react";

interface Props {
  show: boolean;
  close: () => void;
  photoUrl: string | null;
  uploadPhoto: (file: File) => void;
  setError: (message: string) => void;
  isSaving: boolean;
}

export default (props: Props) => {
  const [showUploader, setShowUploader] = React.useState(false);
  const handleUploadFile = async (acceptedFiles: File[]) => {
    const acceptedFileTypes = [
      'image/png',
      'image/jpeg',
    ]
    if (acceptedFiles.length === 0) {
      props.setError("Looks like we didn't get a file. Please try again.");
      return;
    }
    if (acceptedFiles.length > 1) {
      props.setError("Please upload only one file at a time.");
      return;
    }
    if (acceptedFiles[0].size > 40000) {
      props.setError("The size of your file is too large, please upload a file under 40KB.");
      return;
    }
    if (!acceptedFileTypes.includes(acceptedFiles[0].type)) {
      props.setError("We can only accept PNG and JPG files, please convert your photo into one of those formats.");
      return;
    }
    const file = acceptedFiles[0];
    await props.uploadPhoto(file);
    setShowUploader(false);
  }

  const renderDropzoneInnerContent = () => {
    if (props.isSaving) {
      return (
        <Box>
          <Typography level="h3">
            Uploading your photo
          </Typography>

          <LinearProgress
            size="lg"
            variant="solid"
            sx={{
              my: 3,
            }}
          />
        </Box>
      );
    }
    if (!props.photoUrl || showUploader) {
      return (
        <>
          <IconButton
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: 50,
              p: 3,
            }}
            onClick={() => { }}
          >
            <IconCameraPlus stroke={1} />
          </IconButton>
          <Typography level="body-sm">
            Drag & drop a photo here, or click the button above to select it
          </Typography>
          <Typography level="body-sm">
            PNG or JPG only (max 100x100px)
          </Typography>
          <Typography level="body-sm">
            Max size: 40KB
          </Typography>
        </>
      );
    }

    return (
      <Stack gap={1} alignItems="center">
        <img
          src={props.photoUrl}
          alt="Uploaded profile photo"
          style={{
            width: 75,
            height: 75,
            borderRadius: 50,
          }}
        />
        <Typography
          level="body-sm"
          endDecorator={
            <IconButton
              onClick={() => setShowUploader(true)}
            >
              <IconX stroke={1} />
            </IconButton>
          }
        >
          {`profile_photo.${props.photoUrl.split('.').pop()}`}
        </Typography>
      </Stack>
    );
  }

  return (
    <Modal
      open={props.show}
      onClose={props.close}
    >
      <ModalDialog
        sx={{
          minWidth: 375,
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            width: '100%',
          }}
          gap={2}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%',
            }}
          >
            <Typography level="h3">
              Edit Profile
            </Typography>
            <IconButton
              variant="plain"
              disabled={props.isSaving}
              sx={{
                ml: 'auto',
              }}
              onClick={props.close}
            >
              <IconX stroke={1} />
            </IconButton>
          </Stack>

          <Divider />

          <Box
            sx={{
              width: '100%',
            }}
          >
            {/* Dropzone: https://react-dropzone.js.org/ */}
            <Dropzone onDrop={handleUploadFile}>
              {({ getRootProps, getInputProps }) => (
                <Sheet
                  variant='outlined'
                  sx={{
                    py: 5,
                    borderStyle: 'dashed',
                    borderWidth: 2,
                    borderRadius: 10,
                  }}
                >
                  <div {...getRootProps()}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      rowGap={1}
                      sx={{
                        py: 2,
                        px: 3,
                      }}
                    >
                      <input {...getInputProps()} />
                      { renderDropzoneInnerContent() }
                    </Stack>
                  </div>
                </Sheet>
              )}
            </Dropzone>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            disabled={props.isSaving}
            onClick={props.close}
            sx={{
              ml: 'auto',
              borderRadius: 24,
            }}
          >
            Cancel
          </Button>
          
        </Stack>
      </ModalDialog>
    </Modal>
  );
}