import React from 'react';
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/joy';
import { IconPlus, IconSortAscending, IconSortDescending } from '@tabler/icons-react';

import { useJobListings } from '../../hooks/useJobListings';
import { useProfile } from '../../hooks/useProfile';
import { useImportJobListing } from '../../hooks/useImportJobListing';

import ImportJobModal from './JobImportModal/';

export default () => {
  const {
    shouldShowModal,
    setShouldShowModal,
  } = useImportJobListing();
  const {
    availableJobListings,
    selectedJobListings,
    handleSelectAllJobListings,
    handleDeselectAllJobListings,
    sortDirection,
    setSortDirection,
  } = useJobListings();
  const { fetchProfile } = useProfile();
  const [isLoadingJobImportModal, setIsLoadingJobImportModal] = React.useState(false);

  const handleShowJobImportModal = async () => {
    setIsLoadingJobImportModal(true);
    await fetchProfile();
    setIsLoadingJobImportModal(false);
    setShouldShowModal(true);
  }

  const SortButton = () => {
    const action = () => 
      sortDirection === "asc" ? setSortDirection("desc") : setSortDirection("asc");
    const Icon = () =>
      sortDirection === "asc" ?
      <IconSortAscending size={16} stroke={1} /> :
      <IconSortDescending size={16} stroke={1} />;
  
    return (
      <Box
        sx={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
        onClick={() => action()}
      >
        <Icon />
      </Box>
    );
  }

  return (
    <thead>
      <tr>
        <th
          style={{
            borderTopLeftRadius: 12,
            width: 50,
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1"
          }}
          aria-label="Select All"
        >
          <Checkbox
            checked={selectedJobListings.length > 0}
            onChange={() => {
              selectedJobListings.length > 0 ?
                handleDeselectAllJobListings() :
                handleSelectAllJobListings();
            }}
          />
        </th>
        <th
          style={{
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1"
          }}
          aria-label="Job Title"
        >
          Job Title ({availableJobListings})
        </th>
        <th
          style={{
            width: 150,
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1"
          }}
          aria-label="Company"
        >
          Company
        </th>
        <th
          style={{
            width: 100,
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1"
          }}
          aria-label="Saved At"
        >
          <Typography
            level="body-sm"
            component="div"
            endDecorator={<SortButton />}
          >
            Saved At
          </Typography>
        </th>
        <th
          style={{
            width: 150,
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1"
          }}
          aria-label="Status"
        >
          Status
        </th>
        <th
          style={{
            width: 110,
            backgroundColor: "#F0F4F8",
            borderBottom: "2px solid #cdd7e1",
            textAlign: "center"
          }}
          aria-label="Actions"
        >
          <Tooltip
            title="Add Job Listing"
            arrow
            variant="outlined"
          >
            <IconButton
              variant='solid'
              color="primary"
              size='sm'
              sx={{
                width: "80%"
              }}
              loading={isLoadingJobImportModal}
              onClick={handleShowJobImportModal}
            >
              <IconPlus stroke={2} />
            </IconButton>
          </Tooltip>
        </th>
      </tr>
      <ImportJobModal
        open={shouldShowModal}
        close={() => setShouldShowModal(false)}
      />
    </thead>
  );
}
