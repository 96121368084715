import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useAuth } from './useAuthV2';
import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';

export interface JobListingHook {
  jobListing: API.IJobListing;
  isFetching: boolean;
  isDeleting: boolean;
  isImporting: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  fetchJobListing: () => Promise<void>;
  reImportJobListing: () => Promise<void>;
  deleteJobListing: () => Promise<void>;
}

export const useJobListing = (initialState: API.IJobListing): JobListingHook => {
  const { logout } = useAuth();
  const [jobListing, setJobListing] = useState<API.IJobListing>(initialState);
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [wasDeleted, setWasDeleted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);

  const fetchJobListing = async () => {
    if (wasDeleted) {
      return;
    }
    setIsFetching(true);

    try {
      const jl = await API.JobListing.getJobListing(jobListing._id);
      setJobListing(jl);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      setError(
        "Whoops! Looks like something went wrong trying to fetch your job listing. Try refreshing the page."
      );
    } finally {
      setIsFetching(false);
    }
  }

  const reImportJobListing = async () => {
    if (jobListing.state !== API.JobListingState.IMPORT_ERROR ||
      jobListing.import_retry_attempts > 3) {
      return;
    }

    setIsImporting(true);
    try {
      await API.JobListing.reImportJobListing(jobListing._id);
      await fetchJobListing();
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      setError(
        "Whoops! Looks like something went wrong trying to re-import your job listing. Try refreshing the page."
      );
    }
  }

  const deleteJobListing = async () => {
    setIsDeleting(true);

    try {
      await API.JobListing.deleteJobListing(jobListing._id);
      setWasDeleted(true);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      setError(
        "Whoops! Looks like something went wrong trying to delete your job listing. Try refreshing the page."
      );
    } finally {
      setIsDeleting(false);
    }
  }

  const watchJobListingStatus = async () => {
    if (wasDeleted || eventSource) {
      return;
    }
    setIsImporting(true);
    try {
      const evtSrc = new EventSource(
        API.JobListing.generateJobListingStatusURL(jobListing._id),
        { withCredentials: true }
      );
      setEventSource(evtSrc);

      evtSrc.onmessage = async (event) => {
        if (["completed", "error"].includes(event.data)) {
          
          evtSrc.close();
          setEventSource(null);
          
          await fetchJobListing();
          setIsImporting(false);
        } else {
          Sentry.captureMessage(`Unexpected event data: ${event.data}`);
        }
      }

      evtSrc.onerror = async (error) => {
        Sentry.captureException(error);
        setError("Whoops! Looks like something went wrong while watching your Job Listing. Try refreshing the page.");
        
        evtSrc.close();
        setEventSource(null);
        setIsImporting(false);

        await fetchJobListing();
      }
    } catch (error) {
      setIsImporting(false);
      Sentry.captureException(error);
      setError("Whoops! Looks like something went wrong while watching your Job Listing. Try refreshing the page.");
    }
  }

  useEffect(() => {
    if (!jobListing._id) {
      fetchJobListing();
    }

    if (jobListing.state === API.JobListingState.IMPORTING) {
      watchJobListingStatus();
    }

    return () => {
      if (eventSource) {
        eventSource.close();
        setEventSource(null);
      }
    };
  }, [jobListing]);

  return {
    jobListing,
    isFetching,
    isDeleting,
    isImporting,
    error,
    setError,
    fetchJobListing,
    reImportJobListing,
    deleteJobListing,
  };
}