import React from 'react';
import {
  Box,
  Button,
  Link,
  Typography
} from '@mui/joy';
import { IconPlus } from '@tabler/icons-react';

import { useImportJobListing } from '../../hooks/useImportJobListing';
import ImportJobModal from './JobImportModal';

export default () => {
  const {
    shouldShowModal,
    setShouldShowModal,
  } = useImportJobListing();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: 1,
        height: '70vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: 650 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: 2,
        }}
      >
        <Typography level="h3">
          No Job Listings Found
        </Typography>
        <Typography level="body-md" sx={{ textAlign: "center" }}>
          It looks like you don't have any imported job listings.
        </Typography>

        <Button
          variant="solid"
          color="primary"
          sx={{
            borderRadius: 24,
          }}
          startDecorator={
            <IconPlus stroke={1} />
          }
          onClick={() => setShouldShowModal(true)}
        >
          Import My First Job Listing
        </Button>

      </Box>
      <ImportJobModal
        open={shouldShowModal}
        close={() => setShouldShowModal(false)}
      />
    </Box>
  );
}