import React, { PropsWithChildren} from 'react';
import { Snackbar, Button } from '@mui/joy';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

interface Props {
  open: boolean
  close: () => void
}

export default (props: PropsWithChildren<Props>) => {
  if (!props.open) return null;

  return (
    <Snackbar
      open={props.open}
      color="success"
      variant="soft"
      onClose={() => props.close()}
      startDecorator={
        <IconInfoCircle stroke={1} />
      }
      endDecorator={
        <Button
          onClick={() => props.close()}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <IconX stroke={1} />
        </Button>
      }
    >
      {props.children}
    </Snackbar>
  );
}
