import React from 'react';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import { IconPlus } from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import * as API from '../../integrations/api';

import ProjectCard from './Cards/ProjectCard';

export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleAddProject = () => {
    // @ts-ignore
    setCareerCanvas(cc => ({
      ...cc,
      projects: [
        ...cc.projects,
        API.genEmptyProject(),
      ]
    }));
  }

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Projects</Typography>
        </Stack>

        {
          careerCanvas?.projects?.map((proj, index) => (
            <ProjectCard
              key={proj.id}
              project={proj}
              projectIndex={index}
            />
          ))
        }

        <Stack direction="row" gap={1} justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            startDecorator={
              <IconPlus stroke={1} />
            }
            sx={{
              borderRadius: 24,
            }}
            onClick={handleAddProject}
          >
            Add Project
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}