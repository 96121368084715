import React, { PropsWithChildren} from 'react';
import {
  Box,
  Stack,
} from '@mui/joy';

interface Props {
  drawerOpen?: boolean;
}

// This component controls the overall horizontal layout of the application
export default (props: PropsWithChildren<Props>) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minWidth: '100%',
        overflow: props.drawerOpen ? 'hidden' : 'auto',
      }}
    >
      <Box
        sx={{
          px: 1,
          width: { xs: '100%', sm: '90%', md: '90%', lg: '80%', xl: '70%' }
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
}