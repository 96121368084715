import React, { PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Sheet, Stack, Table, Typography } from '@mui/joy';

import Card from '../../components/containers/Card';

import { useConfig } from '../../hooks/useConfig';
import { useProfile } from '../../hooks/useProfile';

import { getFormattedDate } from '../../util/dates';

export default () => {

  const { profile: { usage } } = useProfile();
  const subScriptionIsActive = new Date(usage.period_end) > new Date();

  const { appEnv, liveStripeLink, testStripeLink } = useConfig();
  const [stripeLink, setStripeLink] = React.useState<string | null>(null);

  useEffect(() => {
    // If the user is an internal user, use the test stripe link
    // Otherwise, use the live stripe link
    setStripeLink(appEnv === "development" ? testStripeLink : liveStripeLink);
  }, [liveStripeLink, testStripeLink]);

  const headerStyle = {
    fontWeight: "bold",
    borderBottom: "1px solid #E0E0E0",
  };

  interface CenterAlignedCellProps {
    header?: boolean;
  }

  const CenterAlignedCell = (props: PropsWithChildren<CenterAlignedCellProps>) => {
    let style = {
      textAlign: "center",
    };
    
    if (props.header) {
      style = { ...style, ...headerStyle };
    }

    return (
      <td style={style}>
        {props.children}
      </td>
    );
  }

  return (
    <Card>
      <Stack
        direction="column"
        alignContent="flex-start"
        gap={1}
      >
        <Typography level="title-lg">
          Subscription
        </Typography>
        <Typography level="body-sm">
          Your subscription is currently { subScriptionIsActive ? "active" : "inactive" }.
        </Typography>
        <Typography level="title-md">
          Current Cycle:{" "}
          <Typography level="body-sm">
            {getFormattedDate(new Date(usage.period_start))} - {getFormattedDate(new Date(usage.period_end))}
          </Typography>
        </Typography>
        
        <Sheet
          variant="outlined"
          style={{
            backgroundColor: "white",
            borderRadius: 12,
          }}
        >
          <Table borderAxis="xBetween">
            <thead>
              <tr>
                <CenterAlignedCell header>Type</CenterAlignedCell>
                <CenterAlignedCell header>Allowed</CenterAlignedCell>
                <CenterAlignedCell header>Used</CenterAlignedCell>
                <CenterAlignedCell header>Remaining</CenterAlignedCell>
              </tr>
            </thead>
            <tbody>
              <tr>
                <CenterAlignedCell header>Imported Jobs</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_grants}</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_imports_used}</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_grants - usage.total_imports_used}</CenterAlignedCell>
              </tr>
              <tr>
                <CenterAlignedCell header>Generated Resumes</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_grants}</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_generations_used}</CenterAlignedCell>
                <CenterAlignedCell>{usage.total_grants - usage.total_generations_used}</CenterAlignedCell>
              </tr>
            </tbody>
          </Table>
        </Sheet>
        
        <Typography level="body-sm">
          Want to upgrade, downgrade or cancel your subscription? Click the button below to manage your subscription.
        </Typography>
        {stripeLink && (
          <Typography>
            <Link target="_blank" to={stripeLink}>
              <Button
                variant="plain"
                color="primary"
              >
                Manage Subscription
              </Button>
            </Link>
          </Typography>
        )}
      </Stack>
    </Card>
  );
}
