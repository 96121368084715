import React, { useState, useEffect } from 'react';
import {
  Stack,
} from '@mui/joy';

import JobListingCard from './JobListingRow';

import { useJobListings } from '../../hooks/useJobListings';

export default () => {
  const {
    jobListings,
    resultsPerPage,
    setResultsPerPage,
    totalJobListings,
  } = useJobListings();
  const [reachedBottom, setReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        setReachedBottom(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (reachedBottom) {
      if (jobListings.length < totalJobListings) {
        setResultsPerPage(resultsPerPage + 10);
      }
      setReachedBottom(false); // Reset the flag
    }
  }, [reachedBottom]);

  return (
    <Stack
      direction="column"
      gap={2}
      justifyContent="flex-start"
      alignItems="center"
    >
      { jobListings.map(jobListing => (
        <JobListingCard
          key={jobListing._id}
          jobListing={jobListing}
        />
      ))}
    </Stack>
  );
}
