import React from 'react';
import {
  Button,
  IconButton,
  Stack,
  Typography,
  Select,
  Option,
} from '@mui/joy';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import { useJobListings } from '../../hooks/useJobListings';

export default () => {
  const {
    resultsPerPage,
    setResultsPerPage,
    currentPage,
    pages,
    setCurrentPage,
    availableJobListings,
    firstVisible,
    lastVisible,
  } = useJobListings();

  // if there are more than 5 pages, show the first, the last, the current page, and one page before and after the current page
  const pageButtons = Array.from({ length: pages }, (_, i) => i + 1) // 1 indexed
    .filter((pageNumber) => {
      if (pageNumber === 1) return true; // show the first page
      if (pageNumber === currentPage - 2) return true; // show the page before the current page
      if (pageNumber === currentPage - 1) return true; // show the page before the current page
      if (pageNumber === currentPage) return true; // show the current page
      if (pageNumber === currentPage + 1) return true; // show the page after the current page
      if (pageNumber === currentPage + 2) return true; // show the page after the current page
      if (pageNumber === pages) return true; // show the last page

      return false;
    }).map(p => p.toString());
  
  // Splice in the ellipsis
  if (pages >= 5 && currentPage > 4) {
    pageButtons.splice(1, 0, '...');
  }
  if (pages >= 5 && currentPage < pages - 3) {
    pageButtons.splice(pageButtons.length - 1, 0, '...');
  }
  
  const renderPageButton = (pageNumber: string, index: number) => {
    if (pageNumber === '...') {
      return (
        <Typography key={index} level="body-md">
          ...
        </Typography>
      );
    }

    return (
      <IconButton
        key={index}
        variant={pageNumber === currentPage.toString() ? 'solid' : 'outlined'}
        color={pageNumber === currentPage.toString() ? 'primary' : 'neutral'}
        // disabled={pageNumber === currentPage.toString()}
        onClick={() => setCurrentPage(parseInt(pageNumber))}
        sx={{
          borderRadius: 100,
        }}
      >
        {pageNumber}
      </IconButton>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        pt: 2,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography level="body-sm">
          Job Listings per page
        </Typography>
        <Select
          value={resultsPerPage}
          onChange={(event, newValue) => setResultsPerPage(newValue || 10)}
          size="sm"
        >
          <Option value={10}>10</Option>
          <Option value={20}>25</Option>
          <Option value={50}>50</Option>
        </Select>
        <Typography level="body-sm">
          {firstVisible}-{lastVisible} of {availableJobListings} Items
        </Typography>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
          startDecorator={
            <IconChevronLeft stroke={1} />
          }
          sx={{
            borderRadius: 24,
          }}
        >
          Previous
        </Button>

        <Stack
          direction="row"
          spacing={0.5}
          alignItems="flex-end"
        >
          {
            pageButtons.map(renderPageButton)
          }
        </Stack>

        <Button
          variant="outlined"
          color="primary"
          disabled={currentPage === pages || pages <= 1}
          onClick={() => setCurrentPage(currentPage + 1)}
          endDecorator={
            <IconChevronRight stroke={1} />
          }
          sx={{
            borderRadius: 24,
          }}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}