import React from 'react';
import { Stack, Typography } from '@mui/joy';
import { IconExclamationCircle } from '@tabler/icons-react';

interface Props {
  error: string | null;
  canRetry: boolean;
  reGenerateResume: () => void;
}

export default (props: Props) => {
  if (!props.error) {
    return null;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        mt: 1,
      }}
    >
      <Typography color="danger" level="title-lg" sx={{ textAlign: 'center' }}>
        Something went wrong generating your resume
      </Typography>
      {
        props.canRetry ? (
          <Typography sx={{ textAlign: 'center' }}>
            Click the button below if you'd like to retry.
          </Typography>
        ) : (
          <Typography sx={{ textAlign: 'center' }}>
            Try re-importing the job from Linked In and trying to generate the resume again.
          </Typography>
        )
      }
    </Stack>
  );
}
