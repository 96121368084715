import React from 'react';
import {
  Stack,
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemContent,
  Tooltip,
  Chip,
  Grid,
  IconButton,
} from '@mui/joy';

import {
  IconExternalLink,
  IconInfoCircle,
} from '@tabler/icons-react'

import Card from '../../components/containers/Card';
import SectionHeader from './SectionHeader';
import StyledSheet from '../../components/containers/StyledSheet';
import MinMaxButton from './MinMaxButton';

import { useResumeBuilder } from '../../hooks/useResumeBuilder';

const TooltipBox = (props: { title: string }) => {
  return (
    <Stack
      direction="column"
      gap={1}
      sx={{
        p: 2,
        maxWidth: { xs: '100%', sm: '525px' },
      }}
    >
      <Typography
        level="title-md"
      >
        Resume Rover Summary
      </Typography>

      <Typography
        level="body-sm"
      >
        {props.title}
      </Typography>
    </Stack>
  )
}

const ListBox = (props: { label: string, items: string[] }) => {
  return (
    <Box>
      <Typography level="title-lg">
        {props.label}
      </Typography>
      <List size="sm" marker="disc">
        {props.items?.map((item, index) => (
          <ListItem key={index}>
            <ListItemContent>
              <Typography level="body-md">
                {item}
              </Typography>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default () => {
  const { jobListing } = useResumeBuilder();

  return (
    <StyledSheet px={2} py={2}>
      <Stack direction="column" spacing={2}>
        <Card
          px={2}
          py={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >

            <MinMaxButton
              component="jobListing"
            />

            <SectionHeader
              text="Job Listing"
            />

            <Tooltip
              title="View Job Listing on LinkedIn"
              variant="outlined"
            >
              <Link
                href={jobListing.url}
                target="_blank"
                rel="noopener"
              >
                <IconButton
                  variant='outlined'
                  color="primary"
                >
                  <IconExternalLink stroke={1} />
                </IconButton>
              </Link>
            </Tooltip>
          </Stack>
        </Card>

        <Card>
          <Stack direction="column" spacing={2}>
            <Box>
              <Typography level="h3">
                {jobListing.title}
              </Typography>
              <Typography level="body-md">
                at {jobListing.company}
              </Typography>
            </Box>

            <Box>
              <Typography
                level="title-lg"
                sx={{
                  mb: 1,
                }}
                endDecorator={
                  <Tooltip
                    title={<TooltipBox title={jobListing.ai_summary} />}
                    arrow
                    placement="bottom"
                    variant="outlined"
                  >
                    <IconInfoCircle stroke={1} />
                  </Tooltip>
                }
              >
                Job Description
              </Typography>
              <Typography level="body-sm">
                {jobListing.description}
              </Typography>
            </Box>

            <ListBox
              label="Responsibilities"
              items={jobListing?.responsibilities}
            />

            <ListBox
              label="Requirements"
              items={jobListing?.requirements}
            />

            {jobListing?.nice_to_haves?.length > 0 && (
              <ListBox
                label="Nice to Haves"
                items={jobListing?.nice_to_haves}
              />
            )}

            <Box>
              <Typography level="title-lg" sx={{ mb: 1 }}>
                ATS Keywords to include on your Resume
              </Typography>
              <Grid
                container
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap={1}
                rowGap={1}
              >
                {jobListing?.ai_keywords?.map((keyword, index) => (
                  <Chip
                    key={index}
                    size="lg"
                    variant="soft"
                    color="primary"
                  >
                    <Typography level="body-md" color="primary">
                      {keyword}
                    </Typography>
                  </Chip>
                ))}
              </Grid>
            </Box>

            <ListBox
              label="Sample Bullet Points to Standout"
              items={jobListing?.ai_sample_bullets}
            />

          </Stack>
        </Card>
      </Stack>
    </StyledSheet>
  );
}