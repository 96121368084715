import React from 'react';
import { Typography } from '@mui/joy';

import StatusIcon from './StatusIcon';

import { ResumeGenerationStatus } from '../../../integrations/api';
import type { Status } from '../../../hooks/useGenerateResume';

export default (props: { step: Status }) => {
  return (
    <Typography
      alignSelf="flex-start"
      level="body-md"
      component="div"
      // @ts-ignore
      color={
        props.step.status === ResumeGenerationStatus.PENDING ? 'neutral' : 'plain'
      }
      sx={{
        fontWeight: props.step.status === ResumeGenerationStatus.COMPLETE ? 'bold' : 'normal',
      }}
      startDecorator={<StatusIcon status={props.step.status} />}
    >
      {props.step.title}
    </Typography>
  );
}