export enum EnvironmentVariables {
  COOKIE_DOMAIN = "COOKIE_DOMAIN",
  APP_ENV = "APP_ENV",
  API_BASE_URL = "API_BASE_URL",
  STYTCH_TOKEN = "STYTCH_TOKEN",
  SENTRY_DSN = "SENTRY_DSN",
  LIVE_STRIPE_PUBLISHABLE_KEY = "LIVE_STRIPE_PUBLISHABLE_KEY",
  LIVE_STRIPE_PRICING_TABLE_ID = "LIVE_STRIPE_PRICING_TABLE_ID",
  LIVE_STRIPE_LINK = "LIVE_STRIPE_LINK",
  TEST_STRIPE_PUBLISHABLE_KEY = "TEST_STRIPE_PUBLISHABLE_KEY",
  TEST_STRIPE_PRICING_TABLE_ID = "TEST_STRIPE_PRICING_TABLE_ID",
  TEST_STRIPE_LINK = "TEST_STRIPE_LINK",
  STRIPE_MODE = "STRIPE_MODE",
}

export interface ConfigProps {
  cookieDomain: string;
  appEnv: string;
  apiBaseUrl: string;
  stytchToken: string;
  sentryDSN: string;
  liveStripePublishableKey: string;
  testStripePublishableKey: string;
  liveStripePricingTableId: string;
  testStripePricingTableId: string;
  liveStripeLink: string;
  testStripeLink: string;
  stripeMode: string;
}

export const useConfig = (): ConfigProps => {
  return {
    cookieDomain: process.env[EnvironmentVariables.COOKIE_DOMAIN],
    appEnv: process.env[EnvironmentVariables.APP_ENV] || "production",
    apiBaseUrl: process.env[EnvironmentVariables.API_BASE_URL],
    stytchToken: process.env[EnvironmentVariables.STYTCH_TOKEN],
    sentryDSN: process.env[EnvironmentVariables.SENTRY_DSN],
    liveStripePublishableKey: process.env[EnvironmentVariables.LIVE_STRIPE_PUBLISHABLE_KEY],
    testStripePublishableKey: process.env[EnvironmentVariables.TEST_STRIPE_PUBLISHABLE_KEY],
    liveStripePricingTableId: process.env[EnvironmentVariables.LIVE_STRIPE_PRICING_TABLE_ID],
    testStripePricingTableId: process.env[EnvironmentVariables.TEST_STRIPE_PRICING_TABLE_ID],
    liveStripeLink: process.env[EnvironmentVariables.LIVE_STRIPE_LINK],
    testStripeLink: process.env[EnvironmentVariables.TEST_STRIPE_LINK],
    stripeMode: process.env[EnvironmentVariables.STRIPE_MODE],
  };
}
