export function newDate(str: string): Date {
  return new Date(`${str}Z`);
}

export function timeSince(date) {
  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);
  
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " year(s) ago";
  }
  
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month(s) ago";
  }
  
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " day(s) ago";
  }
  
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hour(s) ago";
  }
  
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min(s) ago";
  }
  
  return Math.max(0, (1 + Math.floor(seconds))) + " sec(s) ago";
}

export function isEndBeforeBegining(startDate: string, endDate: string): boolean {
  return newDate(startDate) > newDate(endDate);
}

export const getFormattedDate = (date) => {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}