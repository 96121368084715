import React from 'react';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import {  IconPlus, } from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import CertificationCard from './Cards/CertificationCard';

import * as API from '../../integrations/api';

export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleAddCertification = () => {
    setCareerCanvas({
      ...careerCanvas,
      certifications: [
        ...careerCanvas.certifications,
        API.genEmptyCertification(),
      ]
    });
  }

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Certifications</Typography>
        </Stack>

        {
          careerCanvas?.certifications?.map((cert, index) => (
            <CertificationCard
              key={cert.id}
              certification={cert}
              certificationIndex={index}
            />
          ))
        }

        <Stack direction="row" gap={1} justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            startDecorator={
              <IconPlus stroke={1} />
            }
            sx={{
              borderRadius: 24,
            }}
            onClick={handleAddCertification}
          >
            Add Certification
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}