import { useState } from 'react';
import * as Sentry from '@sentry/react';
import * as API from '../integrations/api';

type StorageType = API.IProfile |
  API.IUserPreferences |
  { token: string } |
  null;

export const useLocalStorage = (key: string, initialValue: StorageType) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }

      window.localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    } catch (error) {
      Sentry.captureException(error);
      return initialValue;
    }
  });

  const setValue = (value: {}) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      Sentry.captureException(error);
    }

    setStoredValue(value);
  };

  return [storedValue, setValue];
}
