import React, { useEffect } from "react";
import {
  Button,
  Divider,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { IconDeviceFloppy, IconX } from "@tabler/icons-react";

import FormInput from "../../components/FormFields/Input";

interface Props {
  show: boolean;
  close: () => void;
  firstName: string;
  lastName: string;
  updateProfile: (firstName: string, lastName: string) => void;
  isSaving: boolean;
}


export default (props: Props) => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  useEffect(() => {
    setFirstName(props.firstName);
    setLastName(props.lastName);
  }, [props.firstName, props.lastName]);

  const handleSaveProfile = async () => {
    await props.updateProfile(firstName, lastName);
    props.close();
  }

  return (
    <Modal
      open={props.show}
      onClose={props.close}
    >
      <ModalDialog
        sx={{
          minWidth: 375,
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            width: '100%',
          }}
          gap={2}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%',
            }}
          >
            <Typography level="h3">
              Edit Profile
            </Typography>
            <IconButton
              variant="plain"
              disabled={props.isSaving}
              sx={{
                ml: 'auto',
              }}
              onClick={props.close}
            >
              <IconX stroke={1} />
            </IconButton>
          </Stack>

          <Divider />

          <Stack
            direction="column"
            justifyContent="flex-start"
            gap={1}
            sx={{
              width: '100%',
            }}
          >
            <FormInput
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormInput
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Stack>

          <Stack
            direction="row"
            justifyContent="flex-end"
            gap={1}
            sx={{
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={props.isSaving}
              onClick={props.close}
              sx={{
                borderRadius: 24,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              color="primary"
              loading={props.isSaving}
              onClick={handleSaveProfile}
              sx={{
                borderRadius: 24,
              }}
              startDecorator={
                <IconDeviceFloppy stroke={1} />
              }
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  )
}