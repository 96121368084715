import Cookies from 'js-cookie';

let LOGOUT_TIMER: number | null = null;

export const restartLogoutTimer = () => {
  if (LOGOUT_TIMER) {
    clearTimeout(LOGOUT_TIMER)
  }

  LOGOUT_TIMER = setTimeout(() => {
    unauthenticateUser()
  }, 1000 * 60 * 60); // one hour
}

export const unauthenticateUser = () => {
  localStorage.removeItem('profile');
  Cookies.remove(
    'auth',
    {
      path: '',
      domain: process.env['COOKIE_DOMAIN'],
    },
  );
  location.href = '/login';
}