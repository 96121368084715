import ResumeRoverAPIError from '../../util/error';
import * as Sentry from '@sentry/browser';

import type { CareerCanvasStatus } from './careerCanvas';

export interface IJobListing {
  _id: string;
  user_id: string;
  resume_builder_id: string;
  company: string;
  title: string;
  url: string;
  description: string;
  requirements: string[];
  nice_to_haves: string[];
  responsibilities: string[];
  ai_summary: string;
  ai_keywords: string[];
  ai_sample_bullets: string[];
  status: string;
  state: JobListingState;
  import_retry_attempts: number;
  generation_retry_attempts: number;
  error_message: string;
  archived: boolean;
  created_at: string;
  updated_at: string;
}

export enum JobListingStatus {
  CREATED = 'created',
  PARSING = 'parsing',
  GENERATING_INSIGHTS = 'generating_insights',
  NEW = 'new',
  VIEWED = 'viewed',
  ERROR = 'error',
}

export enum JobListingState {
  CREATED = 'created',
  IMPORTING = 'importing',
  IMPORTED = 'imported',
  IMPORT_ERROR = 'import_error',
  GENERATING = 'generating',
  GENERATED = 'generated',
  GENERATE_ERROR = 'generate_error',
  ERROR = 'error',
}

export interface JobListingsResponse {
  listings: IJobListing[];
  career_canvas_status: CareerCanvasStatus;
}

export const genEmptyJobListing = (): IJobListing => ({
  _id: '',
  user_id: '',
  resume_builder_id: '',
  company: '',
  title: '',
  url: '',
  description: '',
  requirements: [],
  nice_to_haves: [],
  responsibilities: [],
  ai_summary: '',
  ai_keywords: [],
  ai_sample_bullets: [],
  status: '',
  state: '' as JobListingState,
  error_message: '',
  import_retry_attempts: 0,
  generation_retry_attempts: 0,
  archived: false,
  created_at: '',
  updated_at: '',
});

export default class JobListing {
  //@ts-ignore
  static baseUrl: string = `${process.env['API_BASE_URL']}/api/v1/job_listing`;

  static generateJobListingStatusURL(jobListingId: string): string {
    return `${JobListing.baseUrl}/${jobListingId}/status`;
  }

  static async createJobListing(
    url: string,
    title: string,
    company: string,
    jobListingContent: string,
  ): Promise<void> {
    try {
      const response = await fetch(JobListing.baseUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
          title,
          company, 
          job_listing_content: jobListingContent,
        }),
      });
      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error creating job listing');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      console.error(error);
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error creating job listing"', 500);
    }
  }

  static async getJobListings(): Promise<JobListingsResponse> {
    try {
      const response = await fetch(JobListing.baseUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error fetching job listings');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error fetching job listings', 500);
    }
  }

  static async getJobListing(id: string): Promise<IJobListing> {
    try {
      const response = await fetch(`${JobListing.baseUrl}/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error fetching job listing');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error fetching job listing', 500);
    }
  }

  static async reImportJobListing(id: string): Promise<void> {
    try {
      const response = await fetch(`${JobListing.baseUrl}/${id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error re-importing job listing');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error re-importing job listing', 500);
    }
  }

  static async deleteJobListing(id: string): Promise<void> {
    try {
      const response = await fetch(`${JobListing.baseUrl}/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error deleting job listing');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error deleting job listing', 500);
    }
  }
}
