import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Button,
  FormLabel,
} from '@mui/joy';

import { useAuth } from '../../../hooks/useAuthV2';

interface Props {
  email: string;
  setEmail: (email: string) => void;
  emailIsValid: boolean;
  switchToSignin: () => void;
  setError: (error: string | null) => void;
}

export default (props: Props) => {
  const [sentEmail, setSentEmail] = useState<boolean>(false);

  const {
    isAuthenticating,
    sendPasswordResetEmail,
  } = useAuth();

  const handleSendPasswordResetEmail = async () => {
    if (props.email.length <= 0 || !props.emailIsValid) {
      return;
    }

    await sendPasswordResetEmail(props.email);
    setSentEmail(true);
  }

  return (
    <Stack
      direction="column"
      rowGap={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
      }}
    >
      <FormControl
        error={!props.emailIsValid}
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter your Email
        </FormLabel>
        <Input
          type="email"
          value={props.email}
          disabled={isAuthenticating || sentEmail}
          onChange={(e) => props.setEmail(e.target.value)}
          placeholder="Email"
        />
        { sentEmail && (
          <FormHelperText>
            If an account with this email exists, we will send you an email with instructions on how to reset your password.
          </FormHelperText>
        )}
        {
          !props.emailIsValid && (
            <FormHelperText>
              Please enter a valid email address.
            </FormHelperText>
          )
        }
      </FormControl>

      <Button
        variant="solid"
        color="primary"
        onClick={handleSendPasswordResetEmail}
        loading={isAuthenticating}
        disabled={props.email.length === 0 || !props.emailIsValid || sentEmail}
        sx={{
          borderRadius: 24,
          width: '100%',
          py: 1,
        }}
      >
        Reset My Password
      </Button>

    </Stack>
  );
}
