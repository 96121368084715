import React, { useState, useEffect } from 'react';

import { StytchHeadlessClient } from '@stytch/vanilla-js/headless';
import { useConfig } from '../../../hooks/useConfig';

interface PasswordProps {
  password: string;
  setPassword: (password: string) => void;
  passwordIsValid: boolean;
  passwordFeedback: string | null;
  passwordSuggestions: string[];
}

export const usePassword = (validatePassword: boolean = true): PasswordProps => {
  const { stytchToken } = useConfig();
  const stytchClient = new StytchHeadlessClient(stytchToken);

  const [password, setPassword] = useState<string>('');
  const [checkingPasswordStrength, setCheckingPasswordStrength] = useState<boolean>(false);
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);
  const [passwordFeedback, setPasswordFeedback] = useState<string | null>(null);
  const [passwordSuggestions, setPasswordSuggestions] = useState<string[]>([]);
  const [passwordCheckDebounce, setPasswordCheckDebounce] = useState<number | null>(null);

  const handlePasswordStrengthCheck = () => {
    if (password.length <= 0) {
      setPasswordIsValid(true);
      return;
    }

    try {
      setCheckingPasswordStrength(true);
      if (passwordCheckDebounce) {
        clearTimeout(passwordCheckDebounce);
      }

      const debounce = setTimeout(async () => {
        const passwordStrengthResult = await stytchClient.passwords.strengthCheck({
          password,
        });
        setPasswordIsValid(passwordStrengthResult.valid_password);
        if (!passwordStrengthResult.valid_password) {
          setPasswordFeedback(passwordStrengthResult.feedback.warning);
          setPasswordSuggestions(passwordStrengthResult.feedback.suggestions);
        }
        setCheckingPasswordStrength(false);
      }, 300);

      setPasswordCheckDebounce(debounce);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (validatePassword) {
      handlePasswordStrengthCheck();
    }
  }, [password]);

  return {
    password,
    setPassword,
    passwordIsValid,
    passwordFeedback,
    passwordSuggestions,
  };
}