import React, { useEffect } from 'react';
import {
  Link,
  Stack,
  Typography,
} from '@mui/joy';

import { useProfile } from '../../../hooks/useProfile';
import { useConfig } from '../../../hooks/useConfig';

import { getFormattedDate } from '../../../util/dates';

export default () => {
  const { profile: { usage } } = useProfile();
  const { liveStripeLink, testStripeLink, appEnv } = useConfig();
  const [ importsRemaining, setImportsRemaining ] = React.useState(0);
  const [stripeLink, setStripeLink] = React.useState<string>("");

  const periodEnd = new Date(usage.period_end);
  periodEnd.setDate(periodEnd.getDate() + 1);
  const moreAvailableDate = getFormattedDate(periodEnd);
  
  useEffect(() => {
    setStripeLink(appEnv === "development" ? testStripeLink : liveStripeLink);
  }, [liveStripeLink, testStripeLink, appEnv]);

  useEffect(() => {
    setImportsRemaining(Math.max(usage.total_grants - usage.total_imports_used, 0));
  }, [
    usage.total_grants,
    usage.total_imports_used,
  ]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        width: '100%',
      }}
    >
      <Typography level="body-sm" sx={{color: "#555E68" }}>
        You have {importsRemaining} job listing imports remaining. More available on {moreAvailableDate}.
      </Typography>

      {usage.plan_name === "Free Plan" && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
          }}
        >
          <Typography level="body-sm">
            Need to import more job listings?
          </Typography>
          <Link
            color="primary"
            href={stripeLink}
            target="_blank"
          >
            Upgrade to a paid plan
          </Link>
        </Stack>
      )}
    </Stack>
  );
}