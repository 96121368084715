import React from 'react';

import {
  Button,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import {
  IconHelpCircle,
  IconInfoCircle,
  IconInfoTriangle,
  IconNote,
  IconPencil,
  IconQuestionMark,
  IconRosetteDiscountCheckFilled,
  IconUpload,
} from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import * as API from '../../integrations/api';

interface Props {
  openOnboardingModal: () => void;
}

export default (props: Props) => {
  const { careerCanvas } = useCareerCanvas();
  const status = careerCanvas?.status;

  const renderText = () => {
    switch (status) {
      case API.CareerCanvasStatus.IMPORT_ERROR:
        return "Error";
      case undefined:
        return "";
      default:
        // propercase the status
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
  }

  const renderIcon = () => {
    switch (status) {
      case API.CareerCanvasStatus.EMPTY:
        return (
          <Typography color="neutral">
            <IconNote stroke={1} />
          </Typography>
        );
      case API.CareerCanvasStatus.IMPORTING:
        return (
          <Typography color="neutral">
            <IconUpload stroke={1} />
          </Typography>
        );
      case API.CareerCanvasStatus.EDITED:
        return (
          <Typography color="neutral">
            <IconPencil stroke={1} />
          </Typography>
        );
      case API.CareerCanvasStatus.COMPLETED:
        return (
          <Typography color="success">
            <IconRosetteDiscountCheckFilled stroke={1} />
          </Typography>
        );
      default:
        return (
          <Typography color="danger">
            <IconInfoTriangle stroke={1} />
          </Typography>
        );
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Button
        variant="plain"
        color="primary"
        size="sm"
        onClick={props.openOnboardingModal}
        startDecorator={
          <IconHelpCircle stroke={1} />
        }
      >
        How does this work?
      </Button>

      <Typography
        level="title-sm"
        startDecorator={
          <Tooltip
            title={
              <Stack
                direction="column"
                gap={1}
                sx={{
                  p: 2,
                  maxWidth: { xs: '100%', sm: '525px' },
                }}
              >
                <Typography level="title-sm">Career Canvas Status</Typography>
                <Typography level="body-sm">
                  Before you can create a customized resume, you need to have enough information in your career canvas. This includes a name, email, and at least one skill on your profile plus one or more jobs listed in your experience.
                </Typography>
              </Stack>
            }
            arrow
            placement="bottom"
            variant="outlined"
          >
            <IconInfoCircle stroke={1} />
          </Tooltip>
        }
        endDecorator={renderIcon()}
      >
        Status: {renderText()}
      </Typography>
    </Stack>
  );
}
