import React, { PropsWithChildren } from 'react';
import { Link as Anchor } from 'react-router-dom';
import {
  Typography,
} from '@mui/joy';

interface Props {
  isAuthenticated: boolean;
  currentPath: string;
  targetPath: string;
  title: string;
}

export default (props: PropsWithChildren<Props>) => {
  if (!props.isAuthenticated) {
    return null;
  }

  return (
    <Anchor
      to={props.targetPath}
      style={{
        textDecoration: 'none',
        paddingTop: '5px',
        minWidth: 140,
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography
        level="title-sm"
        color={props.currentPath === props.targetPath ? "primary" : undefined}
        startDecorator={props.children}
      >
        {props.title}
      </Typography>
    </Anchor>
  )
}