import ResumeRoverAPIError from "../../util/error";
import * as Sentry from "@sentry/browser";

import type { IExperience, IEducation, ICertification, IProject } from "./careerCanvas";
import type { IJobListing } from "./jobListing";

export interface IResumeBuilderProfile {
  name: string;
  title: string;
  email: string;
  phone: string;
  location: string;
  linked_in: string;
}

export interface ResumeBuilderSkill {
  name: string;
  source: string;
  number_of_references: number;
}

export enum ResumeBuilderStatus {
  EMPTY = "empty",
  CREATED = "created",
  GENERATING_RESUME = "generating_resume",
  GENERATED = "generated",
  ERROR = "error",
}

export enum ResumeGenerationStep {
  CREATING_RESUME_BUILDER = "creating_resume_builder",
  ANALYZING_JOB_LISTING = "analyzing_job_listings",
  EXTRACTING_KEY_EXPERIENCE_ACCOMPLISHMENTS = "extracting_key_experience_accomplishments",
  MATCHING_RELEVANT_SKILLS = "matching_relevant_skills",
  WRITING_PROFESSIONAL_SUMMARY = "writing_professional_summary",
  GENERATING_RESUME = "generating_resume",
  COMPLETE = "complete",
}

export enum ResumeGenerationStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETE = "complete",
  FAILED = "failed",
}

export interface ResumeGenerationResponse {
  step: ResumeGenerationStep;
  status: ResumeGenerationStatus;
}

export interface IResumeBuilder {
  _id: string;
  user_id: string;
  job_listing_id: string;
  career_canvas_id: string;
  profile: IResumeBuilderProfile;
  summary: string;
  skills: ResumeBuilderSkill[];
  experience: IExperience[];
  education: IEducation[];
  certifications: ICertification[];
  projects: IProject[];
  ai_experience_missing_keywords: string[];
  ai_experience_missing_requirements: string[];
  status: ResumeBuilderStatus;
  generated_resume_file_id: string;
  error_message: string;
  html_resume_content: string;
  created_at: string;
  updated_at: string;
}

export const genEmptyResumeBuilder = (): IResumeBuilder => ({
  _id: '',
  user_id: '',
  job_listing_id: '',
  career_canvas_id: '',
  profile: {
    name: '',
    title: '',
    email: '',
    phone: '',
    location: '',
    linked_in: '',
  },
  summary: '',
  skills: [],
  experience: [],
  education: [],
  certifications: [],
  projects: [],
  ai_experience_missing_keywords: [],
  ai_experience_missing_requirements: [],
  status: ResumeBuilderStatus.EMPTY,
  generated_resume_file_id: '',
  error_message: '',
  html_resume_content: '',
  created_at: '',
  updated_at: '',
});

export default class ResumeBuilder {
  // @ts-ignore
  static baseUrl: string = `${process.env.API_BASE_URL}/api/v1/resume_builder`;
  
  static generateResumeBuilderEventSourceUrl(resumeBuilderId: string): string {
    return `${ResumeBuilder.baseUrl}/${resumeBuilderId}/status`;
  }

  static async createResumeBuilder(jobListingId: string): Promise<IResumeBuilder> {
    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ job_listing_id: jobListingId }),
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError("Unauthorized", response.status);
      }

      throw new Error("Failed to create resume builder");
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error fetching resume builder:', error);
      throw new ResumeRoverAPIError("Failed to create resume builder", 500);
    }
  }

  static async getResumeBuilder(id: string): Promise<IResumeBuilder> {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      if (response.status === 404) {
        throw new ResumeRoverAPIError('Resume Builder not found', response.status);
      }

      throw new Error('Error fetching resume builder');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error fetching resume builder:', error);
      throw new ResumeRoverAPIError('Failed to get resume builder', 500);
    }
  }

  static async updateResumeBuilder(id: string, resumeBuilder: IResumeBuilder): Promise<IResumeBuilder> {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(resumeBuilder),
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error updating resume builder');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error updating resume builder:', error);
      throw new ResumeRoverAPIError('Failed to update resume builder', 500);
    }
  }

  static async deleteResumeBuilder(id: string): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error deleting resume builder');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      console.error('Error deleting resume builder:', error);
      throw new ResumeRoverAPIError('Failed to delete resume builder', 500);
    }
  }

  static async generateResumePDF(id: string, html_resume_content: string): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/${id}/custom_resume/pdf`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ html_resume_content }),
      });

      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error generating resume PDF');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error generating resume PDF:', error);
      throw new ResumeRoverAPIError('Failed to generate resume PDF', 500);
    }
  }

  static async downloadGeneratedPDF(id: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}/${id}/custom_resume/pdf`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (response.ok) {
        return response.blob();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      if (response.status == 204) {
        throw new ResumeRoverAPIError('PDF still being generated', response.status);
      }

      throw new Error('Error fetching generated PDF');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error fetching generated PDF:', error);
      throw new ResumeRoverAPIError('Failed to download Resume PDF', 500);
    }
  }

  static async getJobListingByResumeBuilderId(id: string): Promise<IJobListing> {
    try {
      const response = await fetch(`${ResumeBuilder.baseUrl}/${id}/job_listing`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      if (response.status === 404) {
        throw new ResumeRoverAPIError('Resume Builder not found', response.status);
      }

      throw new Error('Error fetching job listing');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to get Job Listing', 500);
    }
  }
}