import React from 'react';
import { Typography, CircularProgress } from '@mui/joy';
import { IconCheck, IconExclamationCircle, IconHourglassEmpty } from '@tabler/icons-react';

import { ResumeGenerationStatus } from '../../../integrations/api';

export default (props: { status: ResumeGenerationStatus }) => {
  switch (props.status) {
    case ResumeGenerationStatus.COMPLETE:
      return (
        <Typography color="success">
          <IconCheck stroke={1} />
        </Typography>
      );
    case ResumeGenerationStatus.IN_PROGRESS:
      return <CircularProgress color="primary" thickness={1} size="sm" />;
    case ResumeGenerationStatus.PENDING:
      return <IconHourglassEmpty stroke={1} />;
    default:
      return (
        <Typography color="danger">
          <IconExclamationCircle stroke={1} />
        </Typography>
      );
  }
}