import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  IconButton,
  Tooltip,
} from '@mui/joy';
import {
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconClipboardText,
  IconFileDescription,
  IconToggleLeftFilled,
  IconToggleRightFilled,
} from '@tabler/icons-react';

import { useMaximize } from './hooks/useMaximize';
import type { Component } from './hooks/useMaximize';

interface Props {
  component: Component;
}

export default (props: Props) => {
  const { viewState, isMaximized, toggleViewState } = useMaximize();

  const isXSScreen = useMediaQuery('(max-width:600px)');

  const renderIcon = () => {
    if (isXSScreen) {
      if (props.component === 'editor') {
        return <IconToggleRightFilled stroke={1} />;
      } else if (props.component === 'jobListing') {
        return <IconToggleLeftFilled stroke={1} />;
      }
    }

    return isMaximized('editor', viewState) ?
      <IconArrowsMinimize stroke={1} /> :
      <IconArrowsMaximize stroke={1} />
  }

  const renderToolTipText = (): string => {
    if (isXSScreen) {
      if (props.component === 'editor') {
        return "View Job Listing";
      } else if (props.component === 'jobListing') {
        return "View Editor";
      }
    }

    return isMaximized(props.component, viewState) ? 'Minimize' : 'Maximize'
  }

  return (
    <Tooltip
      arrow
      title={renderToolTipText()}
      variant="outlined"
    >
      <IconButton
        variant={isXSScreen ? 'plain' : 'outlined'}
        color="neutral"
        onClick={() => toggleViewState(props.component)}
      >
        { renderIcon() }
      </IconButton>
    </Tooltip>
  );
}