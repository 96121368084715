import React from 'react';

import { Button, Stack } from '@mui/joy';

import {
  IconChevronLeft,
  IconChevronRight,
  IconDeviceFloppy,
} from '@tabler/icons-react';

import { useWizard } from './hooks/useWizard';
import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import * as API from '../../integrations/api';


export default () => {
  const { activeStep, decrementStep, incrementStep } = useWizard();
  const { careerCanvas, isEdited, saveCareerCanvas, shouldDisableActions } = useCareerCanvas();

  const handleSaveAndContinue = async () => {
    await saveCareerCanvas();
    incrementStep();
  }

  const shouldRenderPrimaryNext = () => {
    return activeStep === 1 && careerCanvas.status !== API.CareerCanvasStatus.EMPTY;
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderTop: '1px solid #d1d1d1',
        py: 3,
      }}
    >

      <Button
        variant="outlined"
        color="primary"
        disabled={activeStep <= 1 || shouldDisableActions}
        startDecorator={
          <IconChevronLeft stroke={1} />
        }
        sx={{
          borderRadius: 24,
        }}
        onClick={() => decrementStep()}
      >
        Back
      </Button>

      <Stack
        direction="row"
        justifyContent="flex-end"
        gap={2}
      >
        <Button
          variant={shouldRenderPrimaryNext() ? "solid" : "outlined"}
          color="primary"
          disabled={activeStep >= 7 || shouldDisableActions}
          endDecorator={
            <IconChevronRight stroke={1} />
          }
          sx={{
            borderRadius: 24,
          }}
          onClick={() => incrementStep()}
        >
          {
            shouldRenderPrimaryNext() ? 'Next' : 'Skip'
          }
        </Button>

        {
          activeStep > 1 ? (
            <Button
              variant="solid"
              color="primary"
              disabled={!isEdited || shouldDisableActions}
              startDecorator={
                <IconDeviceFloppy stroke={1} />
              }
              sx={{
                borderRadius: 24,
              }}
              onClick={handleSaveAndContinue}
            >
              Save{activeStep < 7 ? " and Continue" : ""}
            </Button>
          ) : null
        }
      </Stack>
    </Stack>
  );
}