import React from 'react';
import {
  Typography,
  Button,
  Link,
} from '@mui/joy';
import { IconBrandGoogleFilled } from '@tabler/icons-react';

import { useConfig } from '../../../hooks/useConfig';

interface Props {
  nextRoute: string | null;
}

export default (props: Props) => {
  const { stytchToken, appEnv } = useConfig();

  const makeLoginRedirectURL = (): string =>
    props.nextRoute ? `${location.origin}/login?next_route=${props.nextRoute}` :
      `${location.origin}/login`;
  const makeSignupRedirectURL = (): string =>
    props.nextRoute ? `${location.origin}/login?next_route=${props.nextRoute}` :
      `${location.origin}/login`;

  const prefix = appEnv === 'production' ? "api" : "test";

  return (
    <Link
      href={`https://${prefix}.stytch.com/v1/public/oauth/google/start?public_token=${stytchToken}&login_redirect_url=${makeLoginRedirectURL()}&signup_redirect_url=${makeSignupRedirectURL()}`}
      underline="none"
      sx={{ width: '100%' }}
    >
      <Button
        component="button"
        variant="outlined"
        color="primary"
        startDecorator={<IconBrandGoogleFilled stroke={2} />}
        sx={{
          borderRadius: 24,
          width: '100%',
          py: 1,
        }}
      >
        <Typography level="body-md">
          Continue with Google
        </Typography>
      </Button>
    </Link>
  );
}