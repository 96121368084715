export default {
  colors: {
    primary: "#23548B", // Deep Blue
    secondary: "#EDEDE6", // Light Grey
    warning: "#E18B25", // Orange
    danger: "#C41C1C", // Red
    lightAccent: "#FFD700", // Gold
    nonHighlightColor: "white",
  },
  archive: {
    colors: {
      primary: "#23548B", // Deep Blue
      secondary: "#EDEDE6", // Light Grey
      warning: "#E18B25", // Orange
      danger: "#C41C1C", // Red
      lightAccent: "#FFD700", // Gold
      nonHighlightColor: "white",
    },
  }
}