import React, { useEffect } from 'react';
import {
  Stack,
  Typography,
  Link,
  Box,
} from '@mui/joy';

import { useConfig } from '../../hooks/useConfig';
import { useProfile } from '../../hooks/useProfile';

import { getFormattedDate } from '../../util/dates';

export default () => {
  const { profile: { usage }, generationsRemaining } = useProfile();
  const { liveStripeLink, testStripeLink, appEnv } = useConfig();
  const [stripeLink, setStripeLink] = React.useState<string>("");

  const periodEnd = new Date(usage.period_end);
  periodEnd.setDate(periodEnd.getDate() + 1);
  const moreAvailableDate = getFormattedDate(periodEnd);

  useEffect(() => {
    setStripeLink(appEnv === "development" ? testStripeLink : liveStripeLink);
  }, [liveStripeLink, testStripeLink, appEnv]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        width: '100%',
      }}
    >
      <Typography level="body-xs" sx={{ textAlign: "right", color: "#555E68" }}>
        You have {generationsRemaining} resume generations remaining. More available on {moreAvailableDate}.
        {usage.plan_name === "Free Plan" && (
          <Box>
            {" "}
            Need more?
            <Link
              color="primary"
              href={stripeLink}
              target="_blank"
              sx={{
                ml: 1,
              }}
            >
              Upgrade your plan.
            </Link>
          </Box>
        )}
      </Typography>
    </Stack>
  );
}