import React, { useMemo, memo } from 'react';

import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/joy';
import Card from '../../../components/containers/Card';
import FormInput from '../../../components/FormFields/Input';
import {
  IconBriefcase,
  IconTrash,
  IconChevronRight,
  IconChevronDown,
} from '@tabler/icons-react';

import * as API from '../../../integrations/api';
import { useCareerCanvas } from '../../../hooks/useCareerCanvas';
import Accomplishments from '../Accomplishments';

interface Props {
  experience: API.IExperience;
  experienceIndex: number;
}

export default memo((props: Props): React.ReactNode => {
  const { title, company } = useMemo(() => ({
    title: props.experience.title,
    company: props.experience.company,
  }), []);

  const [ experience, setExperience ] = React.useState<API.IExperience>(props.experience);
  const [hideExperience, setHideExperience] = React.useState<boolean>(
    title === "New Experience" ? false : true
  );
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExperience(exp => {
      const newExperience = {
        ...exp,
        [e.target.name]: e.target.value,
      }

      const newExperienceList = careerCanvas.experience.map((exp, i) => {
        if (i === props.experienceIndex) {
          return newExperience;
        }
        return exp;
      })

      setCareerCanvas({
        ...careerCanvas,
        experience: newExperienceList,
      });
    
      return newExperience;
    });
  }

  const handleICurrentlyWorkHere = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExperience(exp => {
      const newExperience: API.IExperience = {
        ...exp,
        current: e.target.checked,
        end_date: e.target.checked ? null : experience.end_date,
      }

      const newExperienceList = careerCanvas.experience.map((exp, i) => {
        if (i === props.experienceIndex) {
          return newExperience;
        }
        return exp;
      })

      setCareerCanvas({
        ...careerCanvas,
        experience: newExperienceList,
      });

      return newExperience;
    });
  }

  const handleDeleteExperience = () => {
    const newExperienceList = careerCanvas.experience
      .filter(exp => exp.id !== experience.id);

    setCareerCanvas({
      ...careerCanvas,
      experience: newExperienceList,
    });
  }

  return (
    <Card>
      <Stack
        direction="column"
        gap={2}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          sx={{
            pb: 2,
            borderBottom: "1px solid #d1d1d1",
          }}
        >
          <IconBriefcase stroke={1} />
          <Typography level="title-lg">
            {title}
          </Typography>
          <Typography level="body-md" color="neutral">
            |
          </Typography>
          <Typography
            level="body-sm"
            sx={{
              textTransform: "uppercase",
            }}
          >
            {company}
          </Typography>

          <IconButton
            sx={{
              ml: "auto",
            }}
            onClick={() => setHideExperience(!hideExperience)}
          >
            {
              hideExperience
                ? <IconChevronRight stroke={1} />
                : <IconChevronDown stroke={1} />
            }
          </IconButton>
        </Stack>
        
        {
          !hideExperience && (
            <>
              <FormInput
                label="Title"
                name="title"
                value={experience.title}
                onChange={handleUpdateField}
              />

              <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Company"
                    name="company"
                    value={experience.company}
                    onChange={handleUpdateField}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Location"
                    name="location"
                    value={experience.location}
                    onChange={handleUpdateField}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Start Date"
                    type="date"
                    name="start_date"
                    value={experience.start_date}
                    onChange={handleUpdateField}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  {
                    !experience.current && (
                      <FormInput
                        label="End Date"
                        type="date"
                        name="end_date"
                        value={experience.end_date}
                        onChange={handleUpdateField}
                      />
                    )
                  }
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="flex-end"
                  xs={12}
                >
                  <Checkbox
                    label="I currently work here"
                    checked={experience.current}
                    onChange={handleICurrentlyWorkHere}
                  />
                </Grid>
              </Grid>

              <Accomplishments
                accomplishments={experience.accomplishments}
                index={props.experienceIndex}
                typeKey="experience"
              />

              <Stack
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  color="danger"
                  sx={{
                    borderRadius: 24,
                  }}
                  startDecorator={
                    <IconTrash stroke={1} />
                  }
                  onClick={handleDeleteExperience}
                >
                  Delete Experience
                </Button>
              </Stack>
            </>
          )
        }

      </Stack>
    </Card>
  );
});