import React from 'react';
import { Button, Stack } from '@mui/joy';
import { IconChevronRight, IconRefresh } from '@tabler/icons-react';

interface Props {
  closeModal: () => void;
  isGenerating: boolean;
  wasError: boolean;
  canRetry: boolean;
  successAction: () => void;
  errorAction: () => void;
}

export default (props: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      alignItems="center"
    >
      <Button
        variant="outlined"
        color="neutral"
        sx={{
          borderRadius: 24,
        }}
        onClick={props.closeModal}
      >
        Close
      </Button>
      {
        !props.wasError && (
          <Button
            variant="solid"
            color="primary"
            disabled={props.isGenerating}
            sx={{
              borderRadius: 24,
            }}
            onClick={props.successAction}
            endDecorator={
              <IconChevronRight stroke={1} />
            }
          >
            View Generated Resume
          </Button>
        )
      }
      {
        props.wasError && (
          <Button
            variant="outlined"
            color="primary"
            disabled={!props.canRetry}
            sx={{
              borderRadius: 24,
            }}
            onClick={props.errorAction}
            endDecorator={
              <IconRefresh stroke={1} />
            }
          >
            Retry Resume Generation
          </Button>
        )
      }
    </Stack>
  );
}