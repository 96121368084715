import React from 'react';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import EducationCard from './Cards/EducationCard';
import { IconPlus, } from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import * as API from '../../integrations/api';

export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleAddEducation = () => {
    // @ts-ignore
    setCareerCanvas(cc => ({
      ...cc,
      education: [
        ...cc.education,
        API.genEmptyEducation(),
      ]
    }));
  }

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Education</Typography>
        </Stack>

        {
          careerCanvas?.education?.map((edu, index) => (
            <EducationCard
              key={edu.id}
              education={edu}
              educationIndex={index}
            />
          ))
        }

        <Stack direction="row" gap={1} justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            startDecorator={
              <IconPlus stroke={1} />
            }
            sx={{
              borderRadius: 24,
            }}
            onClick={handleAddEducation}
          >
            Add Education
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}