import React, { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';
import { useAuth } from './useAuthV2';
import { useProfile } from './useProfile';

export interface ResumeGenerationContextProps {
  jobListing: API.IJobListing | null;
  generatingResumeBuilderId: string | null;
  isGenerating: boolean;
  isGeneratingForJobListing: (jobListingId: string) => boolean;
  generateResume: (jobListing: API.IJobListing) => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
  success: boolean;
  reset: () => void;
  responseStepStatus: Status[];
  navigateToResumeBuilder: () => void;

  // Career Canvas Required
  showCareerCanvasRequiredModal: boolean;
  setShowCareerCanvasRequiredModal: (show: boolean) => void;
}

export interface Status {
  key: string;
  title: string;
  status: API.ResumeGenerationStatus;
}

export const RESUME_GENERATION_STEPS = [
  {
    key: API.ResumeGenerationStep.CREATING_RESUME_BUILDER,
    title: "Creating Resume Builder",
    status: API.ResumeGenerationStatus.IN_PROGRESS,
  },
  {
    key: API.ResumeGenerationStep.ANALYZING_JOB_LISTING,
    title: "Analyzing Job Listing",
    status: API.ResumeGenerationStatus.PENDING,
  },
  {
    key: API.ResumeGenerationStep.EXTRACTING_KEY_EXPERIENCE_ACCOMPLISHMENTS,
    title: "Extracting Key Experience Accomplishments",
    status: API.ResumeGenerationStatus.PENDING,
  },
  {
    key: API.ResumeGenerationStep.MATCHING_RELEVANT_SKILLS,
    title: "Matching Relevant Skills",
    status: API.ResumeGenerationStatus.PENDING,
  },
  {
    key: API.ResumeGenerationStep.WRITING_PROFESSIONAL_SUMMARY,
    title: "Writing Professional Summary",
    status: API.ResumeGenerationStatus.PENDING,
  },
  {
    key: API.ResumeGenerationStep.GENERATING_RESUME,
    title: "Generating Resume",
    status: API.ResumeGenerationStatus.PENDING,
  },
  {
    key: API.ResumeGenerationStep.COMPLETE,
    title: "Finalizing Resume",
    status: API.ResumeGenerationStatus.PENDING,
  },
];

const ResumeGenerationContext = createContext<ResumeGenerationContextProps>({} as ResumeGenerationContextProps);

export default ({ children }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { fetchProfile } = useProfile();

  // Data
  const [jobListing, setJobListing] = useState<API.IJobListing | null>(null);
  const [generatingResumeBuilderId, setGeneratingResumeBuilderId] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string | null>(null); // Testing
  const [success, setSuccess] = useState<boolean>(false);
  const [responseStepStatus, setResponseStepStatus] = useState<Status[]>(RESUME_GENERATION_STEPS);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const [showCareerCanvasRequiredModal, setShowCareerCanvasRequiredModal] = useState(false);

  const handleEmittedEvent = (response: API.ResumeGenerationResponse) => {
    let nextInProgress = -1;

    setResponseStepStatus((status) => {
      const updatedStatus = status.map((step, stepIdx) => {
        if (step.key === response.step) {
          nextInProgress = stepIdx + 1;
          return {
            ...step,
            status: response.status, // Will either be "completed" or "Failed"
          }
        }

        const nextStatus = step.status === API.ResumeGenerationStatus.COMPLETE ?
          API.ResumeGenerationStatus.COMPLETE :
          API.ResumeGenerationStatus.IN_PROGRESS;

        if (nextInProgress === stepIdx) {
          return {
            ...step,
            status: nextStatus,
          }
        }

        return step;
      });

      return [...updatedStatus];
    });
  }

  const watchResumeGenerationStatus = async (resumeBuilderId: string) => {
    // only allow one event source at a time
    if (eventSource) {
      return;
    }

    let successful = false;

    RESUME_GENERATION_STEPS.forEach((step, index) => {
      if (step.key === API.ResumeGenerationStep.COMPLETE) {
        return;
      }

      setTimeout(() => {
        handleEmittedEvent({
          step: step.key,
          status: API.ResumeGenerationStatus.COMPLETE,
        });

        // add time for the length of experience???
      }, index * 5000 + Math.floor(Math.random() * 4000));
    });

    const evtSrc = new EventSource(
      API.ResumeBuilder.generateResumeBuilderEventSourceUrl(resumeBuilderId),
      { withCredentials: true }
    );

    setEventSource(evtSrc);

    evtSrc.onopen = (event) => {
      // console.log(new Date().toISOString(), 'Event stream opened:', event);
    }

    evtSrc.onmessage = (event) => {
      const response: API.ResumeGenerationResponse = JSON.parse(event.data);
      // handleEmittedEvent(response);

      if (response.step === API.ResumeGenerationStep.COMPLETE) {
        successful = true;
        fetchProfile(true)
          .finally(() => {
            handleEmittedEvent(response);
            evtSrc.close();
            setIsGenerating(false);
            setSuccess(true);
          })
      }
      
      if (response.status === API.ResumeGenerationStatus.FAILED) {
        handleEmittedEvent(response);
        evtSrc.close();
        setError("Whoops! Something went wrong while generating your resume. Please try again.");
        Sentry.captureMessage("Resume generation failed", {
          extra: {
            resumeBuilderId,
            response,
          },
        });
      }
    }

    evtSrc.onerror = (error) => {
      if (!successful) {
        console.error('Event stream error:', error);
        setError("An error occurred while generating your resume. Please try again later.");
        Sentry.captureException(error, {
          extra: {
            resumeBuilderId,
          },
        });
      }
    }
  }

  const navigateToResumeBuilder = () => {
    navigate(`/resume-builder/${generatingResumeBuilderId}`);
  }

  const generateResume = async (jobListing: API.IJobListing) => {
    setJobListing(jobListing);
    setShowModal(true);
    setIsGenerating(true);
    if (generatingResumeBuilderId && isGenerating) {
      setError("A resume is already being generated. Please wait for the current resume to finish generating.");
      return;
    }

    try {
      const resumeBuilder = await API.ResumeBuilder.createResumeBuilder(jobListing._id);
      setGeneratingResumeBuilderId(resumeBuilder._id);

      watchResumeGenerationStatus(resumeBuilder._id);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      
      // console.error('Error creating resume builder:', error);
      setError("Whoops! Something went wrong while generating your resume. Please try again.");
      Sentry.captureException(error);
    }
  }

  const reset = () => {
    setJobListing({} as API.IJobListing);
    setGeneratingResumeBuilderId(null);
    setShowModal(false);
    setResponseStepStatus(RESUME_GENERATION_STEPS);
    setIsGenerating(false);
  }
  
  const isGeneratingForJobListing = (jobListingId: string): boolean => {
    return isGenerating && jobListing !== null && jobListingId === jobListing?._id
  }

  const value: ResumeGenerationContextProps = useMemo(() => ({
    jobListing,
    isGenerating,
    isGeneratingForJobListing,
    generatingResumeBuilderId,
    generateResume,
    showModal,
    setShowModal,
    error,
    setError,
    success,
    reset,
    responseStepStatus,
    navigateToResumeBuilder,
    showCareerCanvasRequiredModal,
    setShowCareerCanvasRequiredModal,
  }), [
    jobListing,
    isGenerating,
    generatingResumeBuilderId,
    showModal,
    error,
    responseStepStatus,
    showCareerCanvasRequiredModal,
    success,
  ]);

  return (
    <ResumeGenerationContext.Provider value={value}>
      {children}
    </ResumeGenerationContext.Provider>
  );
}

export const useGenerateResume = () =>
  useContext(ResumeGenerationContext);