import ResumeRoverAPIError from "../../util/error";
import * as Sentry from "@sentry/browser";

import type { IProfile } from "./profile";

export interface AuthRequest {
  stytch_token_type: string;
  token: string;
}

export interface AuthResponse {
  user: IProfile;
  session_token: string;
  session_jwt: string;
}

export default class Authentication {
  // @ts-ignore
  static baseUrl: string = `${process.env['API_BASE_URL']}/api/v1/authenticate`;

  static async authenticate(stytch_token_type: string, token: string): Promise<AuthResponse> {
    try {
      const response = await fetch(Authentication.baseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ stytch_token_type, token }),
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error Fetching Profile, please try again.');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error Fetching Profile, please try again.', 500);
    }
  }
}