import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuthV2';

import ProfileProvider from '../hooks/useProfile';
import PreferencesProvider from '../hooks/usePreferences';
import ShouldOnboardUser from './ShouldOnboardUser';


export default ({ children }) => {
  const {
    isAuthenticated,
  } = useAuth();
  const { pathname } = useLocation();

  if (!isAuthenticated) {
    return (
      <>
        <Navigate
          to="/login"
          state={{ next_route: pathname }}
        />
      </>
    );
  }

  return (
    <React.StrictMode>
      <ProfileProvider>
        <PreferencesProvider>
          <ShouldOnboardUser>
            {children}
          </ShouldOnboardUser>
        </PreferencesProvider>
      </ProfileProvider>
    </React.StrictMode>
  );
}
