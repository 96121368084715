import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
  useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useAuth } from './useAuthV2';
import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';

export interface ResumeBuilderHook {
  jobListing: API.IJobListing;
  isFetchingJobListing: boolean;
  jobListingError: string | null;
  setJobListingError: (error: string | null) => void;

  resumeBuilder: API.IResumeBuilder;
  isFetchingResumeBuilder: boolean;
  isSaving: boolean;
  isDownloading: boolean;
  resumeBuilderError: string | null;
  setResumeBuilderError: (error: string | null) => void;
  showHallucinationWarning: boolean;
  setShowHallucinationWarning: (show: boolean) => void;

  saveResumeBuilder: (html: string) => Promise<void>;
  downloadResume: (html: string) => Promise<void>;
}

const ResumeBuilderContext = createContext<ResumeBuilderHook>({} as ResumeBuilderHook);

interface Props extends PropsWithChildren {
  resumeBuilderId: string;
}

export default (props: Props) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  // job Listing
  const [jobListing, setJobListing] = useState<API.IJobListing>({} as API.IJobListing);
  const [isFetchingJobListing, setIsFetchingJobListing] = useState(true);
  const [jobListingError, setJobListingError] = useState<string | null>(null);

  // Resume
  const [resumeBuilder, setResumeBuilder] = useState<API.IResumeBuilder>({} as API.IResumeBuilder);
  const [isFetchingResumeBuilder, setIsFetchingResumeBuilder] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [resumeBuilderError, setResumeBuilderError] = useState<string | null>(null);
  const [showHallucinationWarning, setShowHallucinationWarning] = useState(false);

  const fetchJobListingFromResumeBuilder = async () => {
    setIsFetchingJobListing(true);

    try {
      const jl = await API.ResumeBuilder.getJobListingByResumeBuilderId(props.resumeBuilderId);
      setJobListing(jl);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        } else if (error.status === 404) {
          navigate('/not-found');
          return;
        }
      }

      setJobListingError(
        "Whoops! Looks like something went wrong trying to fetch your job listing. Try refreshing the page."
      );
    } finally {
      setIsFetchingJobListing(false);
    }
  }

  const fetchResumeBuilder = async () => {
    setIsFetchingResumeBuilder(true);

    try {
      const rb = await API.ResumeBuilder.getResumeBuilder(props.resumeBuilderId);
      setResumeBuilder(rb);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        } else if (error.status === 404) {
          navigate('/not-found');
          return;
        }
      }

      setResumeBuilderError(
        "Whoops! Looks like something went wrong trying to fetch your resume. Try refreshing the page."
      );
    } finally {
      setIsFetchingResumeBuilder(false);
      setShowHallucinationWarning(true);
    }
  }

  const saveResumeBuilder = async (html_resume_content: string) => {
    setIsSaving(true);

    try {
      await API.ResumeBuilder.updateResumeBuilder(
        resumeBuilder._id,
        {
          ...resumeBuilder,
          html_resume_content,
        },
    );
      setResumeBuilder(resumeBuilder);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }

      setResumeBuilderError(
        "Whoops! Looks like something went wrong trying to save your resume builder. Try refreshing the page."
      );
    } finally {
      setIsSaving(false);
    }
  }

  const downloadResume = async (html_resume_content: string) => {
    setIsDownloading(true);

    try {
      // Update the HTML content and generate the PDF
      await API.ResumeBuilder.generateResumePDF(
        props.resumeBuilderId,
        html_resume_content,
      );

      // Download the PDF
      const pdfBlob = await API.ResumeBuilder.downloadGeneratedPDF(props.resumeBuilderId);
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${resumeBuilder.profile.name} - ${jobListing.company} ${jobListing.title}.pdf`;
      a.click();
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      console.error(error);
      Sentry.captureException(error); // Just incase something goes wrong
      setResumeBuilderError(
        "Whoops! Looks like something went wrong trying to download your resume. Try refreshing the page."
      );
    } finally {
      setIsDownloading(false);
    }
  }

  useEffect(() => {
    fetchResumeBuilder();
    fetchJobListingFromResumeBuilder();
  }, []);

  const value: ResumeBuilderHook = useMemo(() => ({
    jobListing,
    isFetchingJobListing,
    jobListingError,
    resumeBuilder,
    isFetchingResumeBuilder,
    isSaving,
    isDownloading,
    resumeBuilderError,
    setJobListingError,
    setResumeBuilderError,
    saveResumeBuilder,
    downloadResume,
    showHallucinationWarning,
    setShowHallucinationWarning,
  }), [
    jobListing,
    isFetchingJobListing,
    jobListingError,
    resumeBuilder,
    isFetchingResumeBuilder,
    isSaving,
    isDownloading,
    resumeBuilderError,
    showHallucinationWarning,
  ]);

  return (
    <ResumeBuilderContext.Provider value={value}>
      {props.children}
    </ResumeBuilderContext.Provider>
  );
}

export const useResumeBuilder = () =>
  useContext(ResumeBuilderContext);
