import React from 'react';
import {
  CssBaseline,
} from '@mui/material';

import {
  Box,
} from '@mui/joy';

import Drawer from './mobile/';
import Appbar from './appbar';

interface Props {
  openFeedbackModal: () => void;
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
}

export default (props: Props) => {

  return (
    <Box sx={{ mb: 15 }}>
      <CssBaseline />
      <Appbar
        openFeedbackModal={props.openFeedbackModal}
        drawerOpen={props.drawerOpen}
        handleDrawerToggle={props.handleDrawerToggle}
      />

      <Drawer
        drawerOpen={props.drawerOpen}
        handleDrawerToggle={props.handleDrawerToggle}
      />

    </Box>
  )
}
