import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalDialog,
  ModalClose,
  ModalOverflow,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  Stack,
  Button,
  Alert,
  FormHelperText,
  ToggleButtonGroup,
  CircularProgress,
} from '@mui/joy';

import { useProfile } from '../../hooks/useProfile';
import * as API from '../../integrations/api'
import ResumeRoverAPIError from '../../util/error';
import { validateEmail } from '../../util/email';

interface Props {
  open: boolean;
  close: () => void;
}

export default (props: Props) => {
  const { profile } = useProfile();
  const [feedback, setFeedback] = useState<API.IFeedback>(
    {
      ...API.genEmptyFeedback(),
      name: profile?.first_name || "",
      email: profile?.email,
    }
  );
  const [files, setFiles] = useState<File[]>([]);
  const [emailValid, setEmailValid] = useState(true);
  const [formComplete, setFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setFormComplete(isFormComplete());
  })

  const handleFeedbackInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmailValid(value === "" || validateEmail(value));
    }

    setFeedback({
      ...feedback,
      [name]: value,
    });
  }

  const isFormComplete = () => {
    return (
      (feedback.email === "" || emailValid) &&
      feedback.feedback_type !== '' &&
      feedback.description !== '' &&
      feedback.nps !== 0
    );
  }

  const handleFileChange = (event) => {
    if (event.target.files) {
      setFiles(Array.from(event.target.files));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await API.Communication.sendFeedback(feedback, files);

      setSuccess(true);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.message) {
        setError(error.message);
      } else {
        setError('Failed to send feedback. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Box>
          <Typography level="h2" component="h2">
            Submitting Your Feedback
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ mt: 5, mb: 5 }}
          >
            <CircularProgress />
          </Stack>
        </Box>
      )
    }

    if (success) {
      setTimeout(() => {
        props.close();
      }, 10000);

      return (
        <Box>
          <Typography level="h2" component="h2">
            Thank you for your feedback!
          </Typography>
          <Typography sx={{ mt: 5, mb: 5 }}>
            Your feedback has been submitted successfully.
          </Typography>
        </Box>
      )
    }

    return (
      <>
        <Typography level="h2" component="h2">
          Submit Feedback
        </Typography>
        {error && (
          <Alert color="danger" variant="outlined">
            {error}
          </Alert>
        )
        }
        <form onSubmit={handleSubmit}>
          {/* Contact Information */}
          {/* <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Name (optional):</FormLabel>
            <Input
              name="name"
              value={feedback.name}
              onChange={handleFeedbackInputChange}
            />
          </FormControl> */}

          {/* <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Email (optional):</FormLabel>
            <Input
              type="email"
              name="email"
              value={feedback.email}
              onChange={handleFeedbackInputChange}
              color={emailValid ? 'neutral' : 'danger'}
            />
            {!emailValid && (
              <FormHelperText color="danger">
                <Typography color="danger">
                  <WarningOutlined sx={{ mr: 1 }} />
                  Please provide a valid email.
                </Typography>
              </FormHelperText>
            )}
            <FormHelperText>
              <InfoOutlined />
              We'll only use your email to follow up on your feedback.
            </FormHelperText>
          </FormControl> */}

          {/* Feedback Type */}
          <FormControl>
            <FormLabel>Feedback Type:</FormLabel>
            <RadioGroup
              value={feedback.feedback_type}
              onChange={handleFeedbackInputChange}
              name="feedback_type"
            >
              <Radio
                value="bug"
                label="Bug Report"
                slotProps={{ input: { 'aria-describedby': 'bug-helper-text' } }}
              />
              <FormHelperText id="bug-helper-text">
                Something isn't working as expected.
              </FormHelperText>
              <Radio
                value="feature_request"
                label="Feature Request"
                slotProps={{ input: { 'aria-describedby': 'feature-helper-text' } }}
              />
              <FormHelperText id="feature-helper-text">
                I have a suggestion for a new feature.
              </FormHelperText>
              <Radio
                value="general_feedback"
                label="General Feedback"
                slotProps={{ input: { 'aria-describedby': 'general-helper-text' } }}
              />
              <FormHelperText id="general-helper-text">
                I have feedback that doesn't fit into the other categories.
              </FormHelperText>
            </RadioGroup>
          </FormControl>

          {/* Feedback Description */}
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Description:</FormLabel>
            <textarea
              name="description"
              value={feedback.description}
              onChange={handleFeedbackInputChange}
              rows={6}
              required
            />
          </FormControl>

          {/* Conditional Inputs for Bug Reports */}
          {/* {feedback.feedback_type === 'bug' && (
            <Box sx={{ marginBottom: 2 }}>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Severity:</FormLabel>
                <Select name="severity">
                  <Option value="low">Low</Option>
                  <Option value="medium">Medium</Option>
                  <Option value="high">High</Option>
                  <Option value="critical">Critical</Option>
                </Select>
              </FormControl>

              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Steps to Reproduce:</FormLabel>
                <textarea name="steps" rows={4} />
              </FormControl>

              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Expected Behavior:</FormLabel>
                <textarea name="expected" rows={2} />
              </FormControl>

              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Actual Behavior:</FormLabel>
                <textarea name="actual" rows={2} />
              </FormControl>
            </Box>
          )} */}

          {/* Device Information */}
          {/* <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Device:</FormLabel>
            <Input
              name="device"
              value={feedback.user_data.os}
              variant="soft"
              disabled
            />
          </FormControl> */}

          {/* Browser Information */}
          {/* <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Browser:</FormLabel>
            <Input
              name="Browser"
              value={feedback.user_data.browser}
              variant="soft"
              disabled
            />
          </FormControl> */}

          {/* Attachments */}
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Attachments (optional):</FormLabel>
            <input
              className="MuiInput-input css-1gw9vc6-JoyInput-input"
              style={{ alignItems: 'center', marginTop: 5 }}
              type="file"
              name="files"
              onChange={handleFileChange}
              accept=".png,.jpg,.jpeg"
              multiple
              />
            <FormHelperText>
              Attach screenshots or other images to help us understand your feedback (jpg, png supported).
            </FormHelperText>
          </FormControl>

          {/* NPS */}
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Overall Satisfaction:</FormLabel>
            <ToggleButtonGroup
              value={feedback.nps.toString()}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <Button
                  key={value}
                  value={value}
                  variant={feedback.nps === value ? 'solid' : 'outlined'}
                  color="primary"
                  onClick={() => {
                    setFeedback({
                      ...feedback,
                      nps: value,
                    });
                  }}
                >
                  {value}
                </Button>
              ))
              }
            </ToggleButtonGroup>
            <FormHelperText>
              On a scale of 1-10, how likely are you to recommend Resume Rover to a friend or colleague:
            </FormHelperText>
          </FormControl>

          {/* Additional Comments */}
          <FormControl sx={{ marginBottom: 2 }}>
            <FormLabel>Additional Comments (optional):</FormLabel>
            <textarea
              name="comments"
              value={feedback.comments}
              onChange={handleFeedbackInputChange}
              rows={4} />
          </FormControl>

          <Button type="submit" disabled={!formComplete}>Submit Feedback</Button>
        </form>
      </>
    )
  }

  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalOverflow>
        <ModalDialog
          color="neutral"
          variant="soft"
          sx={{
            minWidth: 600, // TODO: Make responsive
          }}
        >
          <ModalClose />
          {renderContent()}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}