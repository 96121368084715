import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
} from '@mui/joy';

import MainNew from '../../components/containers/MainNew'
import JobListing from './JobListing';
import Editor from './Editor';
import HallucinationWarning from './HallucinationWarning';
import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';

import ResumeBuilderProvider, { useResumeBuilder } from '../../hooks/useResumeBuilder';
import MaximizeProvider, { useMaximize } from './hooks/useMaximize';

const ResumeBuilder = () => {
  const { viewState } = useMaximize();
  const {
    isFetchingJobListing,
    isFetchingResumeBuilder,
    jobListingError,
    setJobListingError,
    resumeBuilderError,
    setResumeBuilderError,
    showHallucinationWarning,
    setShowHallucinationWarning,
  } = useResumeBuilder();

  const renderJobListings = () => {
    if (viewState === 'editorMaximized') {
      return null;
    }

    if (viewState === 'jobListingMaximized') {
      return (
        <Grid xs={12}>
          <JobListing />
        </Grid>
      );
    }
    
    return (
      <Grid xs={12} sm={6}>
        <JobListing />
      </Grid>
    );
  }

  const renderEditor = () => {
    if (viewState === 'jobListingMaximized') {
      return null;
    }

    if (viewState === 'editorMaximized') {
      return (
        <Grid xs={12}>
          <Editor />
        </Grid>
      );
    }

    return (
      <Grid xs={12} sm={6}>
        <Editor />
      </Grid>
    );
  }

  return (
    <MainNew loading={isFetchingJobListing || isFetchingResumeBuilder}>
      <Grid
        container
        display="flex"
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid xs={12}>
          <Typography level="h1">
            Resume Builder
          </Typography>
        </Grid>

        { renderJobListings() }
        { renderEditor()}
      </Grid>
      
      <ErrorSnackbar
        error={jobListingError}
        setError={setJobListingError}
      />
      <ErrorSnackbar
        error={resumeBuilderError}
        setError={setResumeBuilderError}
      />

      <HallucinationWarning
        show={showHallucinationWarning}
        hide={() => setShowHallucinationWarning(false)}
      />
    </MainNew>
  );
}


export default () => {
  return (
    <ResumeBuilderProvider resumeBuilderId={useParams().id!}>
      <MaximizeProvider>
        <ResumeBuilder />
      </MaximizeProvider>
    </ResumeBuilderProvider>
  );
}