import React from 'react'
import { Box, Stack, IconButton, LinearProgress, Modal, ModalDialog, Typography } from '@mui/joy'
import { IconX } from '@tabler/icons-react'

interface Props {
  open: boolean;
  close: () => void;
  message: string;
  status: string;
}

export default (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalDialog>
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="flex-end"
          sx={{
            width: '100%',
          }}
        >
          <IconButton
            variant="plain"
            onClick={() => close()}
          >
            <IconX stroke={1} />
          </IconButton>
        </Stack>
        <Box>
          <Typography level="h3">{props.message}</Typography>

          <LinearProgress
            size="lg"
            variant="solid"
            sx={{
              my: 3,
            }}
          />

          <Typography level="body-md">
            {props.status}
          </Typography>
        </Box>
      </ModalDialog>
    </Modal>
  );
}