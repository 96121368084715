export default class ResumeRoverAPIError extends Error {
  status: number;
  name: string;
  message: string;

  constructor(message: string, status: number) {
    super(message);
    this.name = 'ResumeRoverAPIError';
    this.status = status;
  }
}

const DEFAULT_AUTH_ERROR_MESSAGE = "Sorry, we weren't able to sign you in. Please try again.";

export const getErrorMessageByCode = (code: string) => {
  switch (code) {
    case "AE01":
      return DEFAULT_AUTH_ERROR_MESSAGE;
    case "AE02":
      return DEFAULT_AUTH_ERROR_MESSAGE;
    case "AE03":
      return "It looks like you already have account, try signing in instead.";
    case "AE04":
      return DEFAULT_AUTH_ERROR_MESSAGE;
    default:
      return "Opps! Something went wrong. Please try again.";
  }
}