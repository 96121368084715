import React, { PropsWithChildren} from 'react';

import {
  Sheet,
} from '@mui/joy';

interface Props {
  borderColor?: string;
  px?: number;
  py?: number;
}

export default (props: PropsWithChildren<Props>) => {
  return (
    <Sheet
      sx={{
        backgroundColor: "white",
        borderRadius: 12,
        borderColor: props.borderColor ? props.borderColor : '##FBFCFE',
        borderWidth: props.borderColor ? 1 : 0,
        borderStyle: 'solid',
        width: '100%',
        minHeight: 375,
        px: props.px !== undefined ? props.px : 4,
        py: props.py !== undefined ? props.py : 4,
      }}
    >
      {props.children}
    </Sheet>
  );
}