import React, { PropsWithChildren } from 'react';
import {
  IconButton,
  Tooltip,
} from '@mui/joy';

import {
  IconFileDownload, // Import Job Listing
  IconFileTextAi, // Generate Resume
  IconFileDescription, // View Resume
  IconFileAlert, // Error
} from '@tabler/icons-react';

import { IJobListing, JobListingState } from '../../integrations/api';

import { useProfile } from '../../hooks/useProfile';

interface ActionButtonProps {
  tooltipText: string;
  disabled: boolean;
  color?: "primary" | "neutral" | "danger";
  onClick: () => void;
}

const ActionButton = (props: PropsWithChildren<ActionButtonProps>) => {
  return (
    <Tooltip
      arrow
      title={props.tooltipText}
      variant="outlined"
    >
      <IconButton
        color={props.color || "primary"}
        variant="plain"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  );
}

interface Props {
  jobListing: IJobListing;
  isGenerating: boolean;
  importJobListing: () => void;
  generateResume: () => void;
  viewGenerateModal: () => void;
  viewResume: () => void;
}

export default (props: Props) => {
  const { generationsRemaining } = useProfile();

  if ([JobListingState.CREATED, JobListingState.IMPORT_ERROR]
      .includes(props.jobListing.state) && props.jobListing.import_retry_attempts <= 3) {
    return (
      <ActionButton
        tooltipText="Import Job Listing"
        disabled={false}
        onClick={props.importJobListing}
      >
        <IconFileDownload stroke={1} />
      </ActionButton>
    );
  }

  if ([JobListingState.IMPORTING].includes(props.jobListing.state)) {
    return (
      <ActionButton
        tooltipText="Importing Job Listing"
        disabled={true}
        onClick={() => { }}
      >
        <IconFileDownload stroke={1} />
      </ActionButton>
    );
  }

  if ([JobListingState.IMPORTED, JobListingState.GENERATE_ERROR].includes(props.jobListing.state)
    && props.jobListing.generation_retry_attempts <= 3) {
    return (
      <ActionButton
        tooltipText="Generate Resume"
        disabled={props.isGenerating || generationsRemaining <= 0}
        onClick={props.generateResume}
      >
        <IconFileTextAi stroke={1} />
      </ActionButton>
    );
  }

  if ([JobListingState.GENERATING].includes(props.jobListing.state)) {
    return (
      <ActionButton
        tooltipText="Generating Resume"
        disabled={false}
        onClick={props.viewGenerateModal}
      >
        <IconFileTextAi stroke={1} />
      </ActionButton>
    );
  }

  if ([JobListingState.GENERATED].includes(props.jobListing.state)) {
    return (
      <ActionButton
        tooltipText="View Resume"
        disabled={false}
        onClick={props.viewResume}
      >
        <IconFileDescription stroke={1} />
      </ActionButton>
    );
  }

  if ([JobListingState.ERROR].includes(props.jobListing.state)) {
    return (
      <ActionButton
        tooltipText="Error"
        disabled={true}
        color="danger"
        onClick={() => { }} // TODO: contact support??
      >
        <IconFileAlert stroke={1} />
      </ActionButton>
    );
  }
  
  return null;
}