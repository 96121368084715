import React from 'react';
import ReactDOM, {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-dom/client';

import { CssVarsProvider, extendTheme } from '@mui/joy/styles';

import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';

import * as Sentry from '@sentry/react';

import Router from './router';
import { useConfig } from "./hooks/useConfig";



const { stytchToken, sentryDSN } = useConfig();
const stytchClient = new StytchUIClient(stytchToken);

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/resumerover\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const theme = extendTheme({
  fontFamily: {
    display: 'Inter, var(--joy-fontFamily-fallback)',
    body: 'Inter, var(--joy-fontFamily-fallback)',
  },
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  <StytchProvider stytch={stytchClient}>
    {/* <CssVarsProvider theme={theme}> */}
      <Router />
    {/* </CssVarsProvider> */}
  </StytchProvider>
)
