import React, { useEffect } from 'react';

import Quill from 'quill';
import DOMPurify from 'dompurify';
import "quill/dist/quill.snow.css";

import {
  IconButton,
  Stack,
  Tooltip,
} from '@mui/joy';
import { IconDeviceFloppy, IconDownload } from '@tabler/icons-react';

import Card from '../../components/containers/Card';
import StyledSheet from '../../components/containers/StyledSheet';
import SectionHeader from './SectionHeader';
import MinMaxButton from './MinMaxButton';

import { useResumeBuilder } from '../../hooks/useResumeBuilder';

export default () => {
  const {
    resumeBuilder,
    isSaving,
    isDownloading,
    saveResumeBuilder,
    downloadResume,
  } = useResumeBuilder();

  const [quill, setQuill] = React.useState<Quill | null>(null);
  const [contentChanged, setContentChanged] = React.useState(false);
  const [minTop, setMinTop] = React.useState(0);
  const [autoSaveDebouncer, setAutoSaveDebouncer] = React.useState<number | null>(null);

  useEffect(() => {
    if (!resumeBuilder || !resumeBuilder.html_resume_content) {
      return;
    }

    const quillRef = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean']
        ],
      },
    });

    quillRef.on('text-change', () => {
      setContentChanged(true);
      if (autoSaveDebouncer) {
        clearTimeout(autoSaveDebouncer);
      }

      setAutoSaveDebouncer(setTimeout(async () => {
        await saveResumeBuilder(DOMPurify.sanitize(quillRef.getSemanticHTML()));
        setContentChanged(false);
      }, 3000)); // auto save after 3 seconds
    });

    setQuill(quillRef);
  }, [resumeBuilder]);

  useEffect(() => {
    const elements = document.getElementsByClassName('ql-toolbar');
    const toolbar = elements[0];
    if (!toolbar) {
      return;
    }

    if (minTop === 0) {
      setMinTop(toolbar.clientTop);
    }

    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const toolbarTop = toolbar.clientTop;
      

      if (scrollTop > toolbarTop) {
        toolbar.style.position = 'fixed';
        toolbar.style.top = '75px';
        // Place the toolbar in the middle of the window
        const windowMidPoint = window.innerWidth / 2;
        const toolbarMidPoint = toolbar.clientWidth / 2;
        toolbar.style.left = `${windowMidPoint - toolbarMidPoint}px`;
        toolbar.style["z-index"] = 1000;
        toolbar.style["background-color"] = 'white';
        toolbar.style["border-radius"] = "24px";
        toolbar.style["border-width"] = "2px";
        toolbar.style["padding"] = "8px";

      } else {
        toolbar.style.position = 'relative';
        toolbar.style.top = "0px";
        toolbar.style.left = "0px";
        toolbar.style["z-index"] = 0;
        toolbar.style["background-color"] = 'transparent';
        toolbar.style["border-radius"] = "0px";
        toolbar.style["border-width"] = "1px";
        toolbar.style["padding"] = "4px";
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <StyledSheet px={2} py={2}>
      <Stack direction="column" spacing={2} sx={{ width: '100%'}}>
        <Card px={2} py={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">

            <Stack
              direction="row"
              alignItems="center"
              gap={1}
            >
              <MinMaxButton component="editor" />

              <Tooltip
                title="Save Resume Content"
                variant="outlined"
              >
                <IconButton
                  color="primary"
                  variant="outlined"
                  disabled={!contentChanged}
                  loading={isSaving}
                  onClick={() => saveResumeBuilder(
                    DOMPurify.sanitize(quill!.getSemanticHTML()),
                  )}
                >
                  <IconDeviceFloppy stroke={1} />
                </IconButton>
              </Tooltip>
            </Stack>

            <SectionHeader text="Editor" />

            <Tooltip title="Download PDF" variant="outlined">
              <IconButton
                color="primary"
                variant="outlined"
                loading={isDownloading}
                onClick={() => downloadResume(
                  DOMPurify.sanitize(quill!.getSemanticHTML()),
                )}
              >
                <IconDownload stroke={1} />
              </IconButton>
            </Tooltip>

          </Stack>
        </Card>

        <div
          id="container"
          style={{ backgroundColor: 'white' }}
        >
          <div
            id="editor"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(resumeBuilder.html_resume_content)
            }}
          />
        </div>
      </Stack>
    </StyledSheet>
  );
}