import React from 'react';
import {
  Typography,
} from '@mui/joy';

interface Props {
  text: string;
}

export default (props: Props) => {
  return (
    <Typography
      level="title-md"
      component="div"
    >
      {props.text}
    </Typography>
  );
}