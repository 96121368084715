import React, { useEffect } from 'react';
import { Box } from '@mui/joy';
import { useSearchParams } from 'react-router-dom';

import { useProfile } from '../../hooks/useProfile';

import Main from '../../components/containers/MainNew';
import StyledSheet from '../../components/containers/StyledSheet';
import HorizontalLayout from '../../components/containers/HorizontalLayout';
import PageLoader from '../../components/PageLoader';

import Subscription from './Subscription';
import ImportResume from './ImportResume';
import Checkout from './Checkout';

// General Onboarding
export default () => {
  const [searchParams] = useSearchParams();
  const [showCheckout, setShowCheckout] = React.useState(false);
  // const [completedFlow, setCompletedFlow] = React.useState(false);
  // const [signInAttempts, setSignInAttempts] = React.useState(0);


  const { profile, loading, fetchProfile } = useProfile();
  const [subscriptionFlowComplete, setSubscriptionFlowComplete] = React.useState(false);

  // const from = searchParams.get('from');
  
  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    setSubscriptionFlowComplete(profile.onboarding.subscription_flow_completed !== null);
  }, [profile]);

  const renderStep = () => {
    if (loading) {
      return <PageLoader />;
    } else if (showCheckout) {
      return <Checkout hideCheckout={() => setShowCheckout(false)}/>;
    } else if (!subscriptionFlowComplete) {
      return <Subscription showCheckout={() => setShowCheckout(true)}/>;
    }
    // else if (!onboardingStatus.chrome_extension_authenticated) {
    //   return (
    //     <ChromeExtension
    //       fetchOnboardingStatus={handleFetchOnboardingStatus}
    //       completedFlow={completedFlow}
    //       setCompletedFlow={setCompletedFlow}
    //       signInAttempts={signInAttempts}
    //       setSignInAttempts={setSignInAttempts}
    //     />
    //   );
    // }

    return (
      <ImportResume />
    );
  }

  return (
    <Main>
      <StyledSheet>
        <HorizontalLayout>
          <Box sx={{ py: 2 }}>
            {renderStep()}
          </Box>
        </HorizontalLayout>
      </StyledSheet>
    </Main>
  )
}
