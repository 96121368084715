import React from 'react';

import {
  Box,
  Stack,
  Typography,
  Grid,
  Chip,
  ChipDelete,
  IconButton,
} from '@mui/joy';
import { IconPlus } from '@tabler/icons-react';

import Card from '../../components/containers/Card';
import FormInput from '../../components/FormFields/Input';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';

export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();
  const [newSkill, setNewSkill] = React.useState('');
  const [error, setError] = React.useState('');
  
  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCareerCanvas({
      ...careerCanvas,
      [e.target.name]: e.target.value,
    })
  }

  const handleSkillInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSkill(e.target.value);
  }

  const handleAddSkill = () => {
    if (careerCanvas.skills.includes(newSkill)) {
      setError("This skill already exists on your profile. Please enter a different skill.");
      return;
    }
    if (newSkill) {
      setCareerCanvas({
        ...careerCanvas,
        skills: [...careerCanvas.skills, newSkill],
      });
      setNewSkill('');
    }
  }

  const handleRemoveSkill = (index: number) => {
    const newSkills = [...careerCanvas.skills];
    newSkills.splice(index, 1);
    setCareerCanvas({
      ...careerCanvas,
      skills: newSkills,
    });
  }

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Profile</Typography>
          <Typography level="body-md">
            Enter your personal information here as you want it to be displayed on your resume.
          </Typography>
        </Stack>

        <Card>
          <form>
            <Stack
              direction="column"
              gap={2}
            >
              <FormInput
                label="Full Name"
                name="name"
                value={careerCanvas.name}
                onChange={handleUpdateField}
              />

              <FormInput
                label="Title"
                name="title"
                value={careerCanvas.title}
                onChange={handleUpdateField}
              />

              <Typography level="title-lg">
                Contact Information
              </Typography>

              <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Email"
                    name="email"
                    value={careerCanvas.email}
                    onChange={handleUpdateField}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <FormInput
                    label="LinkedIn"
                    name="linked_in"
                    value={careerCanvas.linked_in}
                    onChange={handleUpdateField}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Phone"
                    name="phone"
                    value={careerCanvas.phone}
                    onChange={handleUpdateField}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <FormInput
                    label="Location"
                    name="location"
                    value={careerCanvas.location}
                    onChange={handleUpdateField}
                  />
                </Grid>
              </Grid>

              <Typography level="title-lg">
                Skills
              </Typography>

              <Grid container spacing={1}>
                {
                  careerCanvas?.skills?.map((skill, index) => (
                    <Grid key={skill}>
                      <Chip
                        size="lg"
                        variant="soft"
                        color="primary"
                        sx={{
                          py: 1,
                          px: 2,
                        }}
                        endDecorator={
                          <ChipDelete
                            onDelete={() => handleRemoveSkill(index)}
                          />
                        }
                      >
                        {skill}
                      </Chip>
                    </Grid>
                  ))
                }
              </Grid>

              <FormInput
                label="Add Skill"
                name='skill'
                value={newSkill}
                error={error}
                onChange={handleSkillInputChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    if (newSkill.trim()) {
                      handleAddSkill();
                    }
                  }
                }}
                helperText="Enter a skill and press enter, or click the button to add it to your profile."
                endDecorator={
                  <IconButton
                    disabled={!newSkill.trim()}
                    variant="solid"
                    color="primary"
                    onClick={handleAddSkill}
                  >
                    <IconPlus stroke={1} />
                  </IconButton>
                }
              />
              
            </Stack>
          </form>
        </Card>
      </Stack>
    </Box>
  );
}