import React from 'react';

import {
  Dropdown,
  MenuButton,
  Menu,
  Typography,
  MenuItem,
} from '@mui/joy';
import { IconLogout, IconMail, IconUserCircle } from '@tabler/icons-react';

import { useAuth } from '../../../hooks/useAuthV2';
import { useProfile } from '../../../hooks/useProfile';
import StandardNavItem from './StandardNavItem';

interface Props {
  isAuthenticated: boolean;
  currentPath: string;
  openFeedbackModal: () => void;
}

export default (props: Props) => {
  if (!props.isAuthenticated) {
    return null;
  }

  const { setShowLogoutConfirmationModal } = useAuth();
  const { profile } = useProfile();

  const displayName = profile?.first_name && profile?.last_name ?
    `${profile.first_name} ${profile.last_name}` :
    'Account';

  const ProfileIcon = () => {
    if (profile?.photo_url) {
      return (
        <img
          src={profile.photo_url}
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '25px',
            height: '25px',
          }}
        />
      );
    }

    return (
      <IconUserCircle stroke={1} />
    );
  }

  return (
    <Dropdown
    >
      <MenuButton
        sx={{
          border: 0,
        }}
        startDecorator={
          <ProfileIcon />
        }
      >
        {displayName}
      </MenuButton>

      <Menu
        variant="plain"
      >
        <MenuItem
          sx={{
            mt: '10px',
            py: '10px',
          }}
        >
          <StandardNavItem
            isAuthenticated={props.isAuthenticated}
            currentPath={props.currentPath}
            targetPath="/profile"
            title="Profile"
          >
            <ProfileIcon />
          </StandardNavItem>
        </MenuItem>

        <MenuItem
          sx={{
            py: '10px',
          }}
        >
          <Typography
            level="title-sm"
            sx={{
              minWidth: '200px',
            }}
            onClick={props.openFeedbackModal}
            startDecorator={
              <IconMail stroke={1} />
            }
          >
            Submit Feedback
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            py: '10px',
          }}
        >
          <Typography
            level="title-sm"
            sx={{
              minWidth: '200px',
            }}
            onClick={() => setShowLogoutConfirmationModal(true)}
            startDecorator={
              <IconLogout stroke={1} />
            }
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}