import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';

import { useAuth } from './useAuthV2';
import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';

import { newDate } from '../util/dates';

export type StatusFilter = API.JobListingState | 'all';
export type SortDirection = 'asc' | 'desc';

export interface JobListingsProvider {
  jobListings: API.IJobListing[];
  availableJobListings: number;
  careerCanvasCompleted: boolean;
  noJobListingsAvailable: boolean;
  loading: boolean;
  isFetching: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  fetchJobListings: (force?: boolean) => Promise<void>;
  isGeneratingResume: boolean;
  setIsGeneratingResume: (isGenerating: boolean) => void;

  // Pagination
  totalJobListings: number;
  resultsPerPage: number;
  firstVisible: number;
  lastVisible: number;
  setResultsPerPage: (resultsPerPage: number) => void;
  pages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;

  // Search
  searchQuery: string;
  setSearchQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSearching: boolean;

  // Status Filter
  statusFilter: StatusFilter;
  setStatusFilter: (status: StatusFilter) => void;

  // Sort
  sortDirection: SortDirection;
  setSortDirection: (direction: SortDirection) => void;

  // Bulk Actions
  selectedJobListings: string[];
  handleAddSelectedJobListing: (id: string) => void;
  handleRemoveSelectedJobListing: (id: string) => void;
  handleSelectAllJobListings: () => void;
  handleDeselectAllJobListings: () => void;
  isJobListingSelected: (id: string) => boolean;
  deleteSelectedListings: () => Promise<void>;
}

const JobListingsContext = createContext<JobListingsProvider>({} as JobListingsProvider);

export default ({ children }) => {
  const { logout } = useAuth();
  const [allJobListings, setAllJobListings] = useState<API.IJobListing[]>([]);
  const [availableJobListings, setAvailableJobListings] = useState<number>(0);
  const [jobListings, setJobListings] = useState<API.IJobListing[]>([]);
  const [careerCanvasCompleted, setCareerCanvasCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isGeneratingResume, setIsGeneratingResume] = useState(false);

  // Pagination
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [firstVisible, setFirstVisible] = useState<number>(1);
  const [lastVisible, setLastVisible] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);

  // Search Functionality
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [searchQueryDebouncer, setSearchQueryDebouncer] = useState<number | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // Status Filter
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('all');

  // Sort
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  // Bulk Actions
  const [selectedJobListings, setSelectedJobListings] = useState<string[]>([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    if (!isSearching) setIsSearching(true);

    setSearchQuery(query);
    if (searchQueryDebouncer) {
      clearTimeout(searchQueryDebouncer);
    }

    setSearchQueryDebouncer(setTimeout(() => {
      setDebouncedSearchQuery(query);
      setIsSearching(false);
    }, 1000)); // Debounce the search query by 1 second
  }

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    // reset selected job listings
    handleDeselectAllJobListings();
  }

  const handleAddSelectedJobListing = (id: string) => {
    setSelectedJobListings([...selectedJobListings, id]);
  }

  const handleRemoveSelectedJobListing = (id: string) => {
    const newSelectedJobListings = selectedJobListings.filter((listingId) => listingId !== id);
    setSelectedJobListings(newSelectedJobListings);
  }

  const handleSelectAllJobListings = () => {
    const allIds = jobListings.map((listing) => listing._id);
    setSelectedJobListings(allIds);
  }

  const handleDeselectAllJobListings = () => {
    setSelectedJobListings([]);
  }

  const isJobListingSelected = (id: string) => {
    return selectedJobListings.includes(id);
  }

  const fetchJobListings = async (force?: boolean) => {
    setIsFetching(true);
    if (force === false) {
      // Do nothing
    } else if (isInitialFetch || force) {
      setLoading(true);
    }

    try {
      const { career_canvas_status, listings } = await API.JobListing.getJobListings();
      setAllJobListings(listings);
      setCareerCanvasCompleted(career_canvas_status === API.CareerCanvasStatus.COMPLETED);

      // In the future, this pagination logic should be moved to the backend
      setPages(Math.ceil(listings.length / 10));
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      Sentry.captureException(error);
      setError(
        "Whoops! Looks like something went wrong trying to fetch your job listings. Try clicking the refresh bottom above the job listings table to refetch the listings."
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
        setIsInitialFetch(false);
        setIsFetching(false);
      }, 500);
    }
  }

  const deleteSelectedListings = async () => {
    try {
      await Promise.all(selectedJobListings.map(API.JobListing.deleteJobListing));
      setSelectedJobListings([]);
      await fetchJobListings(true);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
          return;
        }
      }
      Sentry.captureException(error);
      setError(
        "Whoops! Looks like something went wrong trying to delete your job listings. Try refreshing the page and try again."
      );
    }
  }

  const calculateJobListingsToDisplay = () => {
    const totalAvailableJobListings = allJobListings
      .filter((listing) => {
        if (statusFilter === 'all') return true;

        return statusFilter === listing.state;
      }).filter((listing) => {
        if (searchQuery === '') return true;

        // Search the title of the job listing
        if (listing.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())) {
          return true;
        }

        // Search the company of the job listing
        if (listing.company.toLowerCase().includes(debouncedSearchQuery.toLowerCase())) {
          return true;
        }
        
        return false;
      })
      .sort((a, b) => {
        const r1 = newDate(a.created_at);
        const r2 = newDate(b.created_at);
        if (sortDirection === 'asc') {
          // @ts-ignore
          return r1 - r2;
        }

        // @ts-ignore
        return r2 - r1;
      })
    
    const first = (currentPage - 1) * resultsPerPage;
    const last = Math.min(currentPage * resultsPerPage, totalAvailableJobListings.length);
      
    const jobListingsToShow = totalAvailableJobListings.slice(
        first, last
      );
    
    setFirstVisible(first + 1);
    setLastVisible(last);
    setJobListings(jobListingsToShow);
    setAvailableJobListings(totalAvailableJobListings.length);
    const newPages = calculatePages(totalAvailableJobListings);
    setPages(newPages);
    if (currentPage > newPages) {
      setCurrentPage(1);
    }
  }

  const calculatePages = (results: API.IJobListing[]) => {
    return Math.ceil(results.length / resultsPerPage);
  }

  // TODO: Implement SSE for job listings

  useEffect(() => {
    fetchJobListings();
  }, []);

  // re-calculate jobListings
  useEffect(() => {
    calculateJobListingsToDisplay();
  }, [
    resultsPerPage,
    allJobListings,
    currentPage,
    debouncedSearchQuery,
    statusFilter,
    sortDirection,
  ]);

  const value: JobListingsProvider = useMemo(() => ({
    jobListings,
    availableJobListings,
    careerCanvasCompleted,
    noJobListingsAvailable: allJobListings.length === 0,
    loading,
    isFetching,
    error,
    setError,
    isGeneratingResume,
    setIsGeneratingResume,
  
    totalJobListings: allJobListings.length,
    pages,
    resultsPerPage,
    setResultsPerPage,
    firstVisible,
    lastVisible,
    currentPage,
    setCurrentPage: handleSetCurrentPage,
    searchQuery,
    setSearchQuery: handleSearch,
    isSearching,

    fetchJobListings,
    statusFilter,
    setStatusFilter,
    sortDirection,
    setSortDirection,
    selectedJobListings,
    handleAddSelectedJobListing,
    handleRemoveSelectedJobListing,
    handleSelectAllJobListings,
    handleDeselectAllJobListings,
    isJobListingSelected,
    deleteSelectedListings,
  }), [
    allJobListings,
    jobListings,
    availableJobListings,
    careerCanvasCompleted,
    loading,
    isFetching,
    error,
    resultsPerPage,
    currentPage,
    searchQuery,
    isSearching,
    debouncedSearchQuery,
    statusFilter,
    setStatusFilter,
    sortDirection,
    selectedJobListings,
  ]);

  return (
    <JobListingsContext.Provider value={value}>
      {children}
    </JobListingsContext.Provider>
  );
}

export const useJobListings = () => useContext(JobListingsContext);