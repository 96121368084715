import React from 'react';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// import Login from './routes/login/index';
import Login from './routes/Authentication/'

// Main pages
import JobListings from './routes/JobListings/'
import Profile from './routes/Profile/index.tsx'
import CareerCanvas from './routes/CareerCanvas/index.tsx';
import ResumeBuilder from './routes/ResumeBuilder/index.tsx';

import ErrorPage from './routes/ErrorPage';
import NotFound from './routes/404';
import ProtectedRoute from './routes/ProtectedRoute';

// Hooks
import AuthProviderV2 from './hooks/useAuthV2';
import JobListingsProvider from './hooks/useJobListings';
import ResumeGenerationProvider from './hooks/useGenerateResume';
import CareerCanvasProvider from './hooks/useCareerCanvas';
import WizardProvder from './routes/CareerCanvas/hooks/useWizard.tsx'
import Onboarding from './routes/Onboarding'
import ImportJobListingProvider from './hooks/useImportJobListing';
import ResetPassword from './routes/Authentication/ResetPassword.tsx';

// Wrap the createBrowserRouter function with Sentry's wrapCreateBrowserRouter
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

const ErrorHandler = () => (
  <ProtectedRoute>
    <ErrorPage />
  </ProtectedRoute>
)

const router = sentryCreateBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <JobListingsProvider>
          <ResumeGenerationProvider>
            <ImportJobListingProvider>
              <JobListings />
            </ImportJobListingProvider>
          </ResumeGenerationProvider>
        </JobListingsProvider>
      </ProtectedRoute>
    ),
    errorElement: <ErrorHandler />,
  },
  {
    path: '/onboarding',
    element: (
      <ProtectedRoute>
        <Onboarding />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    errorElement: <ErrorHandler />,
  },
  {
    path: '/career-canvas',
    element: (
      <ProtectedRoute>
        <CareerCanvasProvider>
          <WizardProvder>
            <CareerCanvas />
          </WizardProvder>
        </CareerCanvasProvider>
      </ProtectedRoute>
    ),
    errorElement: <ErrorHandler />,
  },
  {
    path: '/resume-builder/:id',
    element: (
      <ProtectedRoute>
        <ResumeBuilder />
      </ProtectedRoute>
    ),
    errorElement: <ErrorHandler />,
  },
  {
    path: '/not-found',
    element: <NotFound />
  },
]);

export default () => {
  return (
    <AuthProviderV2>
      <RouterProvider router={router} />
    </AuthProviderV2>
  );
}
