import React, { createContext, useContext, useState, useMemo } from 'react';

export interface WizardHook {
  activeStep: number;
  setActiveStep: (step: number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  steps: Step[];
}

const WizardContext = createContext<WizardHook>({} as WizardHook);

interface Step {
  step: number;
  label: string;
  status: 'not-started' | 'in-progress' | 'completed';
}

const initialSteps: Step[] = [
  { step: 1, label: 'Import', status: 'not-started' },
  { step: 2, label: 'Profile', status: 'not-started' },
  { step: 3, label: 'Experience', status: 'not-started' },
  { step: 4, label: 'Education', status: 'not-started' },
  { step: 5, label: 'Certifications', status: 'not-started' },
  { step: 6, label: 'Projects', status: 'not-started' },
  { step: 7, label: 'Summary', status: 'not-started' },
]

export default ({ children }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [steps, setSteps] = useState<Step[]>(initialSteps);

  const value: WizardHook = useMemo(() => ({
    activeStep,
    setActiveStep,
    incrementStep: () => setActiveStep(activeStep + 1),
    decrementStep: () => setActiveStep(activeStep - 1),
    steps,
  }), [activeStep]);

  return (
    <WizardContext.Provider value={value}>
      {children}
    </WizardContext.Provider>
  );
};

export const useWizard = () =>
  useContext(WizardContext);
