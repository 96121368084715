import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Button } from '@mui/joy';

import Main from '../components/containers/MainNew';
import StyledSheet from '../components/containers/StyledSheet';
import PageTitle from '../components/PageTitle';

export default () => {
  return (
    <Main>
      <StyledSheet>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={3}
          sx={{ minHeight: '500px' }}
        >
          <PageTitle>Oops! It looks like the page you were looking for doesn't exist!</PageTitle>

          <Link to="/">
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderRadius: 24,
              }}
            >
              Return to the home page
            </Button>
          </Link>
        </Stack>
      </StyledSheet>
    </Main>
  )
}