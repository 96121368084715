import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { Box, Button, IconButton, Snackbar, Stack, } from '@mui/joy';

// Components
import MainNew from '../../components/containers/MainNew';
import CentralSheet from '../../components/containers/CentralSheet';
import Status from './Status';
import Stepper from './Stepper';
import DropdownSelector from './DropdownSelector';
import Footer from './Footer';
import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';
import SuccessSnackbar from '../../components/snackBars/SuccessSnackbar';
import AsyncAction from '../../components/modals/AsyncAction'

import Import from './Import';
import Profile from './Profile';
import Summary from './Summary';
import Experience from './Experience';
import Education from './Education';
import Certifications from './Certifications';
import Projects from './Projects';

import OnboardingModal from './Onboarding';

import { useWizard } from './hooks/useWizard';
import useOnboarding from '../../hooks/useOnboarding';
import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import { Typography } from '@mui/joy';
import { IconDeviceFloppy, IconInfoTriangle, IconX } from '@tabler/icons-react';

export default () => {
  const { activeStep } = useWizard();
  const { onboardingStatus } = useOnboarding();
  const [ _, setShowSavingModal ] = useState(false);
  const [ showUnsavedChangesWarning, setshowUnsavedChangesWarning ] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  useEffect(() => {
    setShowOnboardingModal(onboardingStatus.career_canvas_completed === null);
  }, [onboardingStatus.career_canvas_completed]);

  const { 
    isFetching,
    isSaving,
    isEdited,
    isUploading,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    saveCareerCanvas,
  } = useCareerCanvas();

  const renderStep = () => {

    switch (activeStep) {
      case 1:
        return <Import />;
      case 2:
        return <Profile />;
      case 3:
        return <Experience />;
      case 4:
        return <Education />;
      case 5:
        return <Certifications />;
      case 6:
        return <Projects />;
      case 7:
        return <Summary />;
      default:
        return <Import />;
    }
  }

  useEffect(() => {
    setShowSavingModal(isSaving);
  }, [isSaving])

  useEffect(() => {
    setShowSavingModal(isUploading);
  }, [isUploading]);

  useEffect(() => {
    setshowUnsavedChangesWarning(isEdited);
  }, [isEdited]);

  const isXSScreen = useMediaQuery('(max-width: 600px)');

  return (
    <MainNew loading={isFetching}>
      <CentralSheet
        pageTitle="Career Canvas"
        borderColor={isEdited ? "#EA9A3E" : undefined}
      >
        <Stack
          direction="column"
          gap={3}
        >
          <Status openOnboardingModal={() => setShowOnboardingModal(true)}/>
          {isXSScreen ? (
            <DropdownSelector />
          ) : (
            <Stepper />
          )}

          {renderStep()}

          <Footer />
        </Stack>
      </CentralSheet>
      <OnboardingModal
        open={showOnboardingModal}
        close={() => setShowOnboardingModal(false)}
      />

      <Snackbar
        open={showUnsavedChangesWarning}
        color="warning"
        variant="outlined"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        startDecorator={
          <IconInfoTriangle stroke={1} />
        }
        endDecorator={
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            onClick={() => setshowUnsavedChangesWarning(false)}
          >
            <IconX stroke={1} />
          </IconButton>
        }
      >
        <Stack direction="column" gap={1} justifyContent="center" alignContent="center">
          <Typography level="body-md">
            Warning! You have unsaved changes.
          </Typography>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              size="sm"
              variant="solid"
              color="primary"
              onClick={saveCareerCanvas}
              sx={{
                borderRadius: 24,
              }}
              startDecorator={
                <IconDeviceFloppy stroke={1} />
              }
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Snackbar>

      <ErrorSnackbar
        error={error}
        setError={setError}
      />
      
      <SuccessSnackbar
        open={!!successMessage}
        close={() => setSuccessMessage(null)}
      >
        <Typography level="body-md">
          {successMessage}
        </Typography>
      </SuccessSnackbar>
      <AsyncAction
        open={isSaving}
        close={() => setShowSavingModal(false)}
        message="Saving your Career Canvas"
        status="Please wait while we save your Career Canvas."
      />

      <AsyncAction
        open={isUploading}
        close={() => setShowSavingModal(false)}
        message="Uploading your resume"
        status="Please wait while we upload your resume."
      />
    </MainNew>
  );
}