import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useProfile } from '../hooks/useProfile';

export default ({ children }) => {
  const { profile } = useProfile();
  const { pathname } = useLocation();

  if (pathname !== "/onboarding" && profile?.onboarding?.subscription_flow_completed === null) {
    return <Navigate to="/onboarding" />
  }

  return (
    <>
      {children}
    </>
  );
}
