import React, { useEffect } from 'react'
import { Box, LinearProgress, Typography } from '@mui/joy'

import type { Status } from '../../../hooks/useGenerateResume';

interface Props {
  error: string | null;
  responseStepStatus: Status[];
}

export default (props: Props) => {
  const [progress, setProgress] = React.useState(0);
  const [text, setText] = React.useState('LOADING...');

  useEffect(() => {
    if (!!props.error) {
      setProgress(64);
      setText(`ERROR... ${progress}%`);
    } else {
      const numComplete = props.responseStepStatus.reduce((acc, step) => {
        if (step.status === 'complete') {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      const percentComplete = Math.floor((numComplete / props.responseStepStatus.length) * 100);
      setProgress(percentComplete);
      setText(`LOADING... ${percentComplete}%`);
    }
  }, [props.responseStepStatus, props.error]);


  return (
    <Box>
      <LinearProgress
        determinate
        variant="outlined"
        thickness={25}
        value={progress}
        color={!!props.error ? "danger" : "primary"}
      >
        <Typography
          level="body-xs"
          textColor={progress > 48 ? "common.white" : "common.black"}
          sx={{
            zIndex: 1,
          }}
        >
          {text}
        </Typography>
      </LinearProgress>
    </Box>
  );
}