import React from 'react';
import {
  Chip,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/joy';

import * as API from '../../integrations/api';

export default (props: { jobListing: API.IJobListing }) => {
  switch (props.jobListing.state) {
    case API.JobListingState.CREATED:
      return (
        <Chip size="sm" variant="soft" color="primary">
          <Typography level="body-sm">
            Ready to Import
          </Typography>
        </Chip>
      );
    case API.JobListingState.IMPORTING:
      return (
        <Tooltip
          arrow
          title="Importing Job Listing"
          variant="outlined"
        >
          <LinearProgress variant="soft" color="primary" />
        </Tooltip>
      );
    case API.JobListingState.IMPORT_ERROR:
      return (
        <Tooltip
          title={props.jobListing.error_message}
          variant="outlined"
          arrow
        >
          <Chip size="sm" variant="soft" color="danger">
            Import Failed
          </Chip>
        </Tooltip>
      );
    case API.JobListingState.IMPORTED:
      return (
        <Chip size="sm" variant="soft" color="primary">
          <Typography level="body-sm">
            Ready To Generate
          </Typography>
        </Chip>
      );
    case API.JobListingState.GENERATING:
      return (
        <Tooltip
          arrow
          title="Generating Resume"
          variant="outlined"
        >
          <LinearProgress variant="soft" color="neutral" />
        </Tooltip>
      );
    case API.JobListingState.GENERATE_ERROR:
      return (
        <Tooltip
          title={props.jobListing.error_message}
          variant="outlined"
          arrow
        >
          <Chip size="sm" variant="soft" color="danger">
            Generate Failed
          </Chip>
        </Tooltip>
      );
    case API.JobListingState.GENERATED:
      return (
        <Chip size="sm" variant="soft" color="success">
          <Typography level="body-sm">
            Resume Generated
          </Typography>
        </Chip>
      );
    case API.JobListingState.ERROR:
      return (
        <Tooltip
          title={props.jobListing.error_message}
          variant="outlined"
          arrow
        >
          <Chip size="sm" variant="soft" color="danger">
            Error
          </Chip>
        </Tooltip>
      );
    default:
      return null;
  }
}