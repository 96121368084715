import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';

import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';
import { useAuth } from './useAuthV2';
import { useProfile } from './useProfile';

export interface ImportJobListingContextProps {
  shouldShowModal: boolean;
  setShouldShowModal: (show: boolean) => void;

  isSubmitting: boolean;
  error: string | null;
  setError: (msg: string | null) => void;
  success: boolean;

  // URL
  url: string;
  setUrl: (url: string) => void;
  urlIsValid: boolean;

  // Job Title
  jobTitle: string;
  setJobTitle: (jobTitle: string) => void;
  jobTitleIsValid: boolean;

  // Company Name
  companyName: string;
  setCompanyName: (companyName: string) => void;
  companyNameIsValid: boolean;

  // Job Listing Content
  jobListingContent: string;
  setJobListingContent: (jobListingContent: string) => void;

  canSubmit: boolean;
  importJobListing: () => Promise<void>;

  resetImportJobListing: () => void;
}

const ImportJobListingContext = createContext<ImportJobListingContextProps>({} as ImportJobListingContextProps);

export default ({ children }) => {
  const { logout } = useAuth();
  const { fetchProfile } = useProfile();

  const [shouldShowModal, setShouldShowModal] = useState(false);

  const [url, setUrl] = useState('');
  const [urlIsValid, setUrlIsValid] = useState(true);

  const [jobTitle, setJobTitle] = useState('');
  const [jobTitleIsValid, setJobTitleIsValid] = useState(true);

  const [companyName, setCompanyName] = useState('');
  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);

  const [jobListingContent, setJobListingContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [canSubmit, setCanSubmit] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const allowedTextExpression = /[^a-zA-Z0-9&-_.~,:() ]/g;

  const handleValidateURL = (url: string) => {
    const validHTTPExpression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(validHTTPExpression);
    
    setUrlIsValid(url.trim() === '' || !!url.match(regex));
    setUrl(url);
  }

  const handleValidateJobTitle = (jobTitle: string) => {
    const regex = new RegExp(allowedTextExpression);

    setJobTitleIsValid(!jobTitle.match(regex) || jobTitle.trim() === '');
    setJobTitle(jobTitle);
  }

  const handleValidateCompanyName = (companyName: string) => {
    const regex = new RegExp(allowedTextExpression);

    setCompanyNameIsValid(!jobTitle.match(regex) || companyName.trim() === '');
    setCompanyName(companyName);
  }

  const importJobListing = async () => {
    setIsSubmitting(true);

    try {
      // Validate
      if (!canSubmit) {
        return;
      }

      // Import Job Listing
      await API.JobListing.createJobListing(
        url,
        jobTitle,
        companyName,
        jobListingContent,
      );

      await fetchProfile(true);

      setSuccess(true);
    } catch (err) {
      if (err instanceof ResumeRoverAPIError) {
        if (err.status === 401) {
          logout();
          return;
        }
      }
      Sentry.captureException(err);
      console.error(error);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  }

  const resetImportJobListing = () => {
    setUrl('');
    setUrlIsValid(true);
    setJobTitle('');
    setJobTitleIsValid(true);
    setCompanyName('');
    setCompanyNameIsValid(true);
    setJobListingContent('');
    setSuccess(false);
    setError(null);
    setIsSubmitting(false);
    setShouldShowModal(false);
  }

  useEffect(() => {
    setCanSubmit(urlIsValid && jobTitleIsValid && companyNameIsValid && jobListingContent.length > 250);
  }, [
    urlIsValid,
    jobTitleIsValid,
    companyNameIsValid,
    jobListingContent,
  ]);

  const value: ImportJobListingContextProps = useMemo(() => ({
    shouldShowModal,
    setShouldShowModal,
    url,
    setUrl: handleValidateURL,
    urlIsValid,
    jobTitle,
    setJobTitle: handleValidateJobTitle,
    jobTitleIsValid,
    companyName,
    setCompanyName: handleValidateCompanyName,
    companyNameIsValid,
    jobListingContent,
    setJobListingContent,
    isSubmitting,
    error,
    setError,
    success,
    canSubmit,
    importJobListing,
    resetImportJobListing,
  }), [
    shouldShowModal,
    url,
    jobTitle,
    companyName,
    jobListingContent,
    isSubmitting,
    error,
    canSubmit,
  ]);

  return (
    <ImportJobListingContext.Provider value={value}>
      {children}
    </ImportJobListingContext.Provider>
  );
}

export const useImportJobListing = () =>
  useContext(ImportJobListingContext);
