import React from 'react';
import { Stack, Typography, IconButton } from '@mui/joy';
import { IconX } from '@tabler/icons-react';

interface Props {
  setShowModal: (show: boolean) => void;
}

export default (props: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        borderBottom: '1px solid #CDD7E1',
        pb: 2,
      }}
    >
      <Typography level="h3">
        Resume Rover is generating a customized resume for you
      </Typography>

      <IconButton
        variant="plain"
        onClick={() => props.setShowModal(false)}
      >
        <IconX stroke={1} />
      </IconButton>
    </Stack>
  );
}