import React from 'react';
import {
  Box,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Stack,
  StepIndicator,
  Typography,
  Grid,
  FormLabel,
} from '@mui/joy';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';

import { useWizard } from './hooks/useWizard';
import { useCareerCanvas } from '../../hooks/useCareerCanvas';

export default () => {
  const { steps, activeStep, setActiveStep } = useWizard();
  const { shouldDisableActions } = useCareerCanvas();

  const isComplete = (step: number): boolean => {
    return activeStep > step;
  }

  const isActive = (step: number): boolean => {
    return activeStep === step;
  }

  const determineStepColor = (step: number): 'primary' | 'neutral' => {
    return isComplete(step) || isActive(step) ? 'primary' : 'neutral';
  }

  const determineStepVariant = (step: number): 'solid' | 'outlined' | 'soft' => {
    if (isComplete(step)) {
      return 'soft';
    }

    return isActive(step) ? 'solid' : 'outlined';
  }

  const determineIcon = (step: number): any => {
    if (isComplete(step)) {
      return <IconCheck stroke={1} size={17} />;
    }

    return step.toString();
  }  

  return (
    <Dropdown>
      <Typography level="title-sm">
        Select Step
      </Typography>
      <MenuButton
        variant="outlined"
        disabled={shouldDisableActions}
        endDecorator={<IconChevronRight stroke={1} />}
      >
        {steps[activeStep - 1].label}
      </MenuButton>
      <Menu sx={{ width: "80%" }}>
        {steps.map((step) => (
          <MenuItem
            key={step.step}
            onClick={() => setActiveStep(step.step)}
          >
            <Grid
              container
              direction="row"
              gap={3}
              sx={{
                width: '100%',
              }}
            >
              <Grid
                xs={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <StepIndicator
                  variant={determineStepVariant(step.step)}
                  color={determineStepColor(step.step)}
                >
                  {determineIcon(step.step)}
                </StepIndicator>
              </Grid>

              <Grid xs={6}>
                <Typography level="body-md">
                  {step.label}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
