import { useEffect, useState } from 'react';

import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';

import { useAuth } from './useAuthV2';

interface OnboardingContext {
  onboardingStatus: API.IOnboarding;
  fetchOnboardingStatus: () => Promise<void>;
  onboardingLoading: boolean;
  error: string | null;
  setError: (error: string | null) => void;
}

export enum EventType {
  SUBSCRIPTION_FLOW_COMPLETED = 'subscription_flow_completed',
  CHROME_EXTENSION_DOWNLOADED = 'chrome_extension_downloaded',
  CHROME_EXTENSION_AUTHENTICATED = 'chrome_extension_authenticated',
  RESUME_IMPORTED = 'resume_imported',
  CAREER_CANVAS_COMPLETED = 'career_canvas_completed',
  JOB_LISTING_IMPORTED = 'job_listing_imported',
  RESUME_GENERATED = 'resume_generated',
  ONBOARDING_COMPLETED = 'onboarding_completed',
}

export default (): OnboardingContext => {
  const { logout } = useAuth();
  const [onboardingStatus, setOnboardingStatus] = useState<API.IOnboarding>({} as API.IOnboarding);
  const [onboardingLoading, setOnboardingLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchOnboardingStatus = async () => {
    try {
      setOnboardingLoading(true);
      const response = await API.Profile.getMe();
      setOnboardingStatus(response.onboarding);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        if (error.status === 401) {
          logout();
        }
      }
      setError("Sorry, we weren't able to fetch your onboarding status. Please try again.");
    } finally {
      setOnboardingLoading(false);
    }
  }

  useEffect(() => {
    fetchOnboardingStatus();
  }, []);

  return {
    onboardingStatus,
    fetchOnboardingStatus,
    onboardingLoading: onboardingLoading,
    error,
    setError
  }
}
