import React from 'react';

import {
  Box,
  Stack,
  Typography,
  FormControl,
  FormLabel,
  Tooltip,
  Textarea,
} from '@mui/joy';
import { IconInfoCircle } from '@tabler/icons-react';

import Card from '../../components/containers/Card';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';

export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Professional Summary (Optional)</Typography>
          <Typography
            level="body-md"
            endDecorator={
              <Tooltip
                variant="outlined"
                arrow
                title="Resume Rover will suggest a professional summary based on your experience and skills and each individual job listing you’re working with, so this is optional."
              >
                <IconInfoCircle stroke={1} />
              </Tooltip>
            }
          >
            Enter a professional summary that can be used on your resume.
          </Typography>
        </Stack>

        <Card>
          <form>
            <Stack
              direction="column"
              gap={2}
            >
              <FormControl>
                <FormLabel>Summary</FormLabel>
                <Textarea
                  placeholder="Write text..."
                  minRows={3}
                  value={careerCanvas.summary}
                  name="summary"
                  onChange={(e) => setCareerCanvas({
                    ...careerCanvas,
                    summary: e.target.value,
                  })}
                />
              </FormControl>
            </Stack>
          </form>
        </Card>
      </Stack>
    </Box>
  );
}