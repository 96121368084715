import React from 'react';

import {
  Sheet,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  Typography,
} from '@mui/joy';

import StyledSheet from '../../components/containers/StyledSheet';
import { IconCheck } from '@tabler/icons-react';

interface Props {
  elementRef: React.RefObject<HTMLDivElement> | undefined;
}

export default (props: Props) => {
  return (
    <StyledSheet px={10} py={5}>
      <Stack
        id="mktSheet"
        ref={props.elementRef}
        direction="column"
        gap={2}
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          py: { xs: 2, sm: 15 },
          minHeight: "100%",
        }}
      >
        <Typography level="h1">
          Job hunting sucks.
          <br />
          We make it
          <br />
          <Typography sx={{ color: "#DDCA1B" }}>
            better.
          </Typography>
        </Typography>
        

        <Typography level="body-sm">
          Create a custom-tailored resume for the job you want—in under 2 minutes.
        </Typography>

        <Sheet
          sx={{
            py: 3,
            px: 2, 
          }}
        >
          <Stepper
            orientation="vertical"
            sx={{
              "--Step-connectorThickness": "1px",
              "--Step-connectorInset": "-1px",
            }}
          >
            <Step
              indicator={
                <StepIndicator
                  variant="soft"
                  color="primary"
                  sx={{
                    p: 2,
                  }}
                >
                  1
                </StepIndicator>
              }
            >
              <Stack
                direction="column"
                sx={{
                  ml: 1,
                }}
              >
                <Typography level="title-md">
                  Create your Career Canvas
                </Typography>
                <Typography level="body-sm">
                  Import an existing resume, or use our wizard to fill out your Career Canvas
                </Typography>
              </Stack>
            </Step>

            <Step
              indicator={
                <StepIndicator
                  variant="soft"
                  color="primary"
                  sx={{
                    p: 2,
                  }}
                >
                  2
                </StepIndicator>
              }
            >
              <Stack
                direction="column"
                sx={{
                  ml: 1,
                }}
              >
                <Typography level="title-md">
                  Import job listings
                </Typography>
                <Typography level="body-sm">
                  Easily import job listings directly from your favorite job board
                </Typography>
              </Stack>
            </Step>

            <Step
              indicator={
                <StepIndicator
                  variant="soft"
                  color="primary"
                  sx={{
                    p: 2,
                  }}
                >
                  3
                </StepIndicator>
              }
            >
              <Stack
                direction="column"
              sx={{
                ml: 1,
              }}
              >
                <Typography level="title-md">
                  Create a custom-tailored resume
                </Typography>
                <Typography level="body-sm">
                  Generate a resume custom-tailored to the job in under two minutes
                </Typography>
              </Stack>
            </Step>

            <Step
              indicator={
                <StepIndicator
                  variant="solid"
                  color="primary"
                  sx={{
                    color: "white",
                    height: 24,
                  }}
                >
                  <IconCheck stroke={2} />
                </StepIndicator>
              }
            >
              <Stack
                direction="column"
              sx={{
                ml: 1,
              }}
              >
                <Typography level="title-md">
                  Hear back from recruiters
                </Typography>
                <Typography level="body-sm">
                  Relax knowing your customized resume will land at the top of the recruiter's stack
                </Typography>
              </Stack>
            </Step>

          </Stepper>
        </Sheet>
      </Stack>
    </StyledSheet>
  );
}