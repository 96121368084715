import React from 'react';

import {
  Stack,
  LinearProgress,
  Box,
  Typography,
} from '@mui/joy';

// @ts-ignore
import headerLogo from '../../assets/iconlight128.png';

export default () => {
  return (
    <Stack
      direction="row"
      alignContent="center"
      justifyContent="center"
      sx={{
        width: '100%',
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: 375,
            textAlign: 'center',
          }}
        >
          <img
            src={headerLogo}
            alt="fetching your data"
            style={{ width: 128, marginTop: 100 }}
          />

          <Typography level="body-md">
            We're fetching your data, please wait...
          </Typography>

          <LinearProgress
            size="lg"
            variant="solid"
            sx={{
              mt: 3
            }}
          />
        </Box>
      </Stack>

    </Stack>
  );
}