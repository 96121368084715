import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  Input,
  Stack,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  LinearProgress,
  FormLabel,
  ListItemDecorator,
} from '@mui/joy';
import { IconEye, IconEyeOff } from '@tabler/icons-react';

import { useAuth } from '../../../hooks/useAuthV2';
import { useOTP } from '../hooks/useOTP';
import { usePassword } from '../hooks/usePassword';

interface Props {
  email: string;
  setEmail: (email: string) => void;
  emailIsValid: boolean;
  switchToSignin: () => void;
  setError: (error: string | null) => void;
}

enum SignupState {
  EMAIL = 0,
  OTP = 1,
  PASSWORD = 2,
}

export default (props: Props) => {
  const [ signupState, setSignupState ] = useState<SignupState>(SignupState.EMAIL);

  const {
    isAuthenticating,
    signUpWithPassword,
  } = useAuth();

  const {
    otpCode,
    setOtpCode,
    otpIsValid,
    loading,
    otpAttempts,
    otpSuccess,
    otpError,
    sendOTP,
    verifyOTP,
    resetOTP,
  } = useOTP();

  const {
    password,
    setPassword,
    passwordIsValid,
    passwordFeedback,
    passwordSuggestions,
  } = usePassword();
  
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleEmailSignup = async () => {
    if (!props.emailIsValid) {
      return;
    }

    await sendOTP(props.email);
    setSignupState(SignupState.OTP);
  }

  const handleOTPVerification = async () => {
    if (otpAttempts >= 3 || !otpIsValid) {
      return;
    }

    await verifyOTP();
  }

  useEffect(() => {
    if (otpSuccess) {
      setSignupState(SignupState.PASSWORD);
    }
  }, [otpSuccess]);

  const handleNextStep = async () => {
    switch (signupState) {
      case SignupState.EMAIL:
        await handleEmailSignup();
        break;
      case SignupState.OTP:
        await handleOTPVerification();
        break;
      case SignupState.PASSWORD:
        await signUpWithPassword(password);
        break;
    }
  }

  const renderOTP = () => {
    if (signupState === SignupState.EMAIL) {
      return;
    }

    if (otpAttempts >= 3) {
      return (
        <Stack>
          <Typography level="body-sm">
            Too many failed attempts.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSignupState(SignupState.EMAIL);
              resetOTP();
            }}
            sx={{
              borderRadius: 24,
              width: '100%',
            }}
          >
            Try again?
          </Button>
        </Stack>
      );
    }

    return (
      <FormControl
        error={(!otpIsValid && otpCode.length > 0) || otpError !== null}
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter the Code Sent to Your Email
        </FormLabel>
        <Input
          type="text"
          value={otpCode}
          disabled={loading}
          onChange={(e) => setOtpCode(e.target.value)}
          placeholder="Enter the code sent to your email"
        />
        {
          loading && (
            <FormHelperText>
              <Typography level="body-sm">
                Verifying code...
                <LinearProgress />
              </Typography>
            </FormHelperText>
          )
        }
        {
          otpCode.length > 0 && otpError && (
            <>
              <FormHelperText>
                The code you entered was incorrect. Please try again.
              </FormHelperText>
            </>
          )
        }
      </FormControl>
    );
  }

  const renderPassword = () => {
    if (signupState < SignupState.PASSWORD) {
      return;
    }

    return (
      <FormControl
        error={!passwordIsValid}
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter a Password
        </FormLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="*********"
          endDecorator={
            showPassword ? (
              <IconButton
                onClick={() => setShowPassword(false)}
                variant="plain"
                color="neutral"
              >
                <IconEyeOff stroke={1} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setShowPassword(true)}
                variant="plain"
                color="neutral"
              >
                <IconEye stroke={1} />
              </IconButton>
            )
          }
        />
        {
          isAuthenticating && (
            <FormHelperText>
              <Typography level="body-sm">
                Creating account...
                <LinearProgress />
              </Typography>
            </FormHelperText>
          )
        }
        {
          !passwordIsValid && (
            <>
              <FormHelperText>
                Your password is not strong enough. {passwordFeedback}
              </FormHelperText>
              <List>
                {
                  passwordSuggestions.map((suggestion, index) => (
                    <ListItem key={index}>
                      <ListItemDecorator>
                        <Typography level="body-xs">
                          •
                        </Typography>
                      </ListItemDecorator>
                      <Typography level="body-xs">
                        {suggestion}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </>
          )
        }
      </FormControl>
    );
  }

  const renderButtonText = () => {
    switch (signupState) {
      case SignupState.EMAIL:
        return "Sign Up with Email";
      case SignupState.OTP:
        return "Verify Your Email";
      case SignupState.PASSWORD:
        return "Create Account";
    }
  }

  return (
    <Stack
      direction="column"
      rowGap={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
      }}
    >
      <FormControl
        error={!props.emailIsValid}
        sx={{
          width: '100%',
        }}
      >
        <FormLabel>
          Enter your Email
        </FormLabel>
        <Input
          type="email"
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
          placeholder="Email"
        />
        {
          loading && (
            <FormHelperText>
              <Typography level="body-sm">
                Sending code...
                <LinearProgress />
              </Typography>
            </FormHelperText>
          )
        }
        {
          !props.emailIsValid && (
            <FormHelperText>
              Please enter a valid email address.
            </FormHelperText>
          )
        }
      </FormControl>

      {renderOTP()}

      {renderPassword()}

      <Button
        variant="solid"
        color="primary"
        onClick={handleNextStep}
        disabled={
          signupState === SignupState.EMAIL && (props.email.length === 0 || !props.emailIsValid) ||
          signupState === SignupState.OTP && !otpIsValid ||
          signupState === SignupState.PASSWORD && (!passwordIsValid || isAuthenticating)
        }
        sx={{
          borderRadius: 24,
          width: '100%',
          py: 1,
        }}
      >
        {renderButtonText()}
      </Button>

      <Typography level="body-sm">
        Already have an account?
        {" "}
        <Typography
          color="primary"
          onClick={props.switchToSignin}
          sx={{
            cursor: 'pointer',
          }}
        >
          Sign In
        </Typography>
      </Typography>
    </Stack>
  );
}
