import { restartLogoutTimer, unauthenticateUser } from "../../util/auth"
import { getUserData } from "../../util/user_detection"
import ResumeRoverAPIError from "../../util/error"

export interface IUserData {
  browser: string
  browser_major_version: number
  browser_version: string
  mobile: boolean
  os: string
  os_version: string
  screen: string
}

export interface IFeedback {
  name: string
  email: string
  feedback_type: string
  description: string
  user_data: IUserData
  nps: number
  comments: string
}

export const genEmptyFeedback = (): IFeedback => ({
  name: '',
  email: '',
  feedback_type: '',
  description: '',
  user_data: getUserData(),
  nps: 0,
  comments: '',
})

export default class Communication {
  // @ts-ignore
  static baseUrl: string = `${process.env['API_BASE_URL']}/api/v1/communication`;

  static async signupForBeta(email: string): Promise<void> {
    try {
      const response = await fetch(`${Communication.baseUrl}/beta_signup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        unauthenticateUser();
      }

      throw new ResumeRoverAPIError('Error signing up for beta', response.status);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  static async sendFeedback(feedback: IFeedback, files: File[]): Promise<void> {
    try {
      const formData = new FormData();
      Object.keys(feedback).forEach((key) => {
        if (typeof feedback[key] === 'object') {
          formData.append(key, JSON.stringify(feedback[key]));
        } else {
          formData.append(key, feedback[key]);
        }
      })
      files.forEach((file) => {
        formData.append('files', file, file.name);
      });

      const response = await fetch(`${Communication.baseUrl}/beta_feedback`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const body = await response.json();

      if (response.ok) {
        return body
      }

      if (response.status === 401) {
        unauthenticateUser();
      }

      if (response.status !== 500) {
        throw new ResumeRoverAPIError(body.response, response.status);
      }

      restartLogoutTimer();
      throw new ResumeRoverAPIError(
        'Sorry, we were unable to submit your feedback. Please try again later.',
        response.status
      );
    } catch (error) { 
      console.error('Error:', error);
      throw error;
    }
  }
}