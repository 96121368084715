import React from 'react';
import {
  Box,
  Typography,
  Stack,
} from '@mui/joy';

import StatusLine from './StatusLine';

import type { Status } from '../../../hooks/useGenerateResume';

interface Props {
  error: string | null;
  responseStepStatus: Status[];
}

export default (props: Props) => {
  if (props.error) {
    return null;
  }

  return (
    <>
      <Typography level="title-lg" sx={{ px: 3, }}>
        Progress
      </Typography>

      <Box sx={{ px: 3, py: 1 }}>
        <Stack
          direction="column"
          spacing={2}
        >
          {props.responseStepStatus.map((step) => (
            <StatusLine key={step.key} step={step} />
          ))}

        </Stack>
      </Box>
    </>
  );
}