import React, { useMemo, memo } from 'react';

import {
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/joy';
import Card from '../../../components/containers/Card';
import FormInput from '../../../components/FormFields/Input';
import {
  IconTrash,
  IconCertificate,
  IconChevronRight,
  IconChevronDown,
} from '@tabler/icons-react';

import * as API from '../../../integrations/api';
import { useCareerCanvas } from '../../../hooks/useCareerCanvas';

interface Props {
  certification: API.ICertification;
  certificationIndex: number;
}

export default memo((props: Props): React.ReactNode => {
  const { title, issuer } = useMemo(() => ({
    title: props.certification.title,
    issuer: props.certification.issuer,
  }), []);

  const [certification, setCertification] = React.useState<API.ICertification>(props.certification);
  const [hideCertification, setHideCertification] = React.useState<boolean>(
    title === "New Certification" ? false : true
  );
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleUpdateToCareerCanvas = (name: string, value: string) => {
    setCertification(cert => {
      const newCertification = {
        ...cert,
        [name]: value,
      }

      const newCertificationsList: API.ICertification[] = careerCanvas.certifications
        .map((cert, i) => {
          if (i === props.certificationIndex) {
            return newCertification;
          }
          return cert;
        })

      setCareerCanvas({
        ...careerCanvas,
        certifications: newCertificationsList,
      });

      return newCertification;
    });
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUpdateToCareerCanvas(e.target.name, e.target.value);
  }

  const handleDeleteCertification = () => {
    const newCertificationsList = careerCanvas.certifications
      .filter(cert => {
        return cert.id !== certification.id;
      });

    setCareerCanvas({
      ...careerCanvas,
      certifications: newCertificationsList,
    });
  }

  return (
    <Card>
      <form>
        <Stack
          direction="column"
          gap={2}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            sx={{
              pb: 2,
              borderBottom: "1px solid #d1d1d1",
            }}
          >
            <IconCertificate stroke={1} />
            <Typography level="title-lg">
              {title}
            </Typography>
            <Typography level="body-md" color="neutral">
              |
            </Typography>
            <Typography
              level="body-sm"
              sx={{
                textTransform: "uppercase",
              }}
            >
              {issuer}
            </Typography>
            <IconButton
              sx={{
                ml: "auto",
              }}
              onClick={() => setHideCertification(!hideCertification)}
            >
              {
                hideCertification
                  ? <IconChevronRight stroke={1} />
                  : <IconChevronDown stroke={1} />
              }
            </IconButton>
          </Stack>

          {
            !hideCertification && (
              <>
                <FormInput
                  label="Title"
                  name="title"
                  value={certification.title}
                  onChange={handleUpdateField}
                />

                <FormInput
                  label="Issuer"
                  name="issuer"
                  value={certification.issuer}
                  onChange={handleUpdateField}
                />

                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <FormInput
                      label="Date"
                      type="date"
                      name="date"
                      value={certification.date}
                      onChange={handleUpdateField}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <FormInput
                      label="URL"
                      name="url"
                      value={certification.url}
                      onChange={handleUpdateField}
                    />
                  </Grid>
                </Grid>

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outlined"
                    color="danger"
                    sx={{
                      borderRadius: 24,
                    }}
                    startDecorator={
                      <IconTrash stroke={1} />
                    }
                    onClick={handleDeleteCertification}
                  >
                    Delete Certification
                  </Button>
                </Stack>
              </>
            )
          }

        </Stack>
      </form>
    </Card>
  );
});