import React from 'react';

import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import { IconPlus, } from '@tabler/icons-react';

import ExperienceCard from './Cards/ExperienceCard';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';
import * as API from '../../integrations/api';


export default () => {
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleAddExperience = () => {
    // @ts-ignore
    setCareerCanvas(cc => ({
      ...cc,
      experience: [
        ...cc.experience,
        API.genEmptyExperience(),
      ]
    }));
  }

  return (
    <Box>
      <Stack direction="column" rowGap={3}>
        <Stack direction="column" rowGap={1}>
          <Typography level="h3">Professional Experience</Typography>
        </Stack>

        {
          careerCanvas?.experience?.map((exp, index) => (
            <ExperienceCard
              key={exp.id}
              experience={exp}
              experienceIndex={index}
            />
            )
          )
        }
        

        <Stack direction="row" gap={1} justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            startDecorator={
              <IconPlus stroke={1} />
            }
            sx={{
              borderRadius: 24,
            }}
            onClick={handleAddExperience}
          >
            Add Experience
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}