import React, { memo, useMemo } from 'react';

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Textarea,
  FormControl,
  FormLabel,
  IconButton,
} from '@mui/joy';
import Card from '../../../components/containers/Card';
import FormInput from '../../../components/FormFields/Input';
import {
  IconApps,
  IconChevronRight,
  IconTrash,
  IconChevronDown,
} from '@tabler/icons-react';

import * as API from '../../../integrations/api';
import { useCareerCanvas } from '../../../hooks/useCareerCanvas';
import Accomplishments from '../Accomplishments';

interface Props {
  project: API.IProject;
  projectIndex: number;
}

export default memo((props: Props): React.ReactNode => {
  const { title } = useMemo(() => ({
    title: props.project.title,
  }), []);
  const [ project, setProject ] = React.useState<API.IProject>(props.project);
  const [hideProject, setHideProject] = React.useState<boolean>(
    title === "New Project" ? false : true
  );
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProject(proj => {
      const newProject = {
        ...proj,
        [e.target.name]: e.target.value,
      }

      const newProjectList = careerCanvas.projects.map((proj, i) => {
        if (i === props.projectIndex) {
          return newProject;
        }
        return proj;
      })

      setCareerCanvas({
        ...careerCanvas,
        projects: newProjectList,
      });
    
      return newProject;
    });
  }

  const handleDeleteProject = () => {
    const newProjectsList = careerCanvas.projects
    .filter(proj => proj.id !== project.id);

    setCareerCanvas({
      ...careerCanvas,
      projects: newProjectsList,
    });
  }

  return (
    <Card>
      <form>
        <Stack
          direction="column"
          gap={2}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            sx={{
              pb: 2,
              borderBottom: "1px solid #d1d1d1",
            }}
          >
            <IconApps stroke={1} />
            <Typography level="title-lg">
              {title}
            </Typography>

            <IconButton
              sx={{
                ml: "auto",
              }}
              onClick={() => setHideProject(!hideProject)}
            >
              {
                hideProject
                  ? <IconChevronRight stroke={1} />
                  : <IconChevronDown stroke={1} />
              }
            </IconButton>
          </Stack>

          {
            !hideProject && (
              <>
                <FormInput
                  label="Title"
                  name="title"
                  value={project.title}
                  onChange={handleUpdateField}
                />

                <FormControl>
                  <FormLabel>
                    Description
                  </FormLabel>
                  <Textarea
                    placeholder="Write text..."
                    minRows={3}
                    value={project.description}
                    name="description"
                    onChange={handleUpdateField}
                  />
                </FormControl>

                <Accomplishments
                  accomplishments={project.accomplishments}
                  index={props.projectIndex}
                  typeKey='projects'
                />

                <Stack
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outlined"
                    color="danger"
                    sx={{
                      borderRadius: 24,
                    }}
                    startDecorator={
                      <IconTrash stroke={1} />
                    }
                    onClick={handleDeleteProject}
                  >
                    Delete Project
                  </Button>
                </Stack>
              </>
            )
          }

        </Stack>
      </form>
    </Card>
  );
});