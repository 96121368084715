import React from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from '@mui/joy';

interface Props {
  label: string;
  name?: string;
  value?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  helperText?: string;
  type?: string;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  error?: string;
  minWidth?: string;
}

export default (props: Props) => {

  const renderHelperText = () => {
    if (props.error) {
      return (
        <FormHelperText>
          {props.error}
        </FormHelperText>
      );
    }
    if (props.helperText) {
      return (
        <FormHelperText>
          {props.helperText}
        </FormHelperText>
      );
    }
    return null;
  }

  return (
    <FormControl
      error={!!props.error}
      sx={{ minWidth: props.minWidth || '100%' }}
    >
      <FormLabel>{props.label}</FormLabel>
      <Input
        placeholder={props.label}
        type={
          props.type ? props.type : 'text'
        }
        name={props.name}
        value={props.value || ""}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        startDecorator={props.startDecorator || null}
        endDecorator={props.endDecorator || null}
      />
      {renderHelperText()}
    </FormControl>
  );
}