import React, { useCallback } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { useConfig } from '../../hooks/useConfig';

import * as API from '../../integrations/api';
import { Button, Stack, Box } from '@mui/joy';
import { IconChevronLeft } from '@tabler/icons-react';

const stripePromise = loadStripe(
  useConfig().appEnv === 'production' ?
    useConfig().liveStripePublishableKey :
    useConfig().testStripePublishableKey
);

interface Props {
  hideCheckout: () => void;
}

export default (props: Props) => {
  const fetchClientSecret = useCallback(API.Profile.createUserCheckoutSession, []);
  const options = { fetchClientSecret };

  return (
    <Stack
      direction="column"
      gap={5}
      justifyContent="flex-start"
      alignContent="center"
      sx={{
        minWidth: "100%",
      }}
    >
      <Box>
        <Button
          variant="plain"
          color="neutral"
          onClick={props.hideCheckout}
          startDecorator={<IconChevronLeft stroke={1} />}
        >
          Back to Plans
        </Button>
      </Box>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Stack>
  );
}