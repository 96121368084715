import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import * as API from '../integrations/api';
import ResumeRoverAPIError from '../util/error';
import { useAuth } from './useAuthV2';

export interface CareerCanvasContextProps {
  shouldDisableActions: boolean;
  isFetching: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  isEdited: boolean;
  setIsEdited: (edited: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
  careerCanvas: API.ICareerCanvas;
  setCareerCanvas: (careerCanvas: API.ICareerCanvas) => void;
  fetchCareerCanvas: (background?: boolean) => void;
  saveCareerCanvas: () => void;

  // Resumes and Files
  resumeToUpload: API.IFile | null;
  resumes: API.IFile[];
  isFetchingResumes: boolean;
  isDownloading: boolean;
  isUploading: boolean;
  isImporting: boolean;
  uploadingResumeId: string | null;
  successMessage: string | null;
  setSuccessMessage: (message: string | null) => void;
  fetchResumes: () => void;
  uploadResume: (file: File, override: boolean) => void;
  downloadResume: (resumeId: string, fileName: string) => void;
  deleteResume: (resumeId: string) => void;
}

const CareerCanvasContext = createContext<CareerCanvasContextProps>({} as CareerCanvasContextProps);

export default ({ children }) => {
  const { logout } = useAuth();

  // Career Canvas State
  const [shouldDisableActions, setShouldDisableActions] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [careerCanvas, setCareerCanvas] = useState<API.ICareerCanvas>({} as API.ICareerCanvas);

  // Resumes and Files State
  const [resumeToUpload, setResumeToUpload] = useState<API.IFile | null>(null);
  const [resumes, setResumes] = useState<API.IFile[]>([]);
  const [isFetchingResumes, setIsFetchingResumes] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [uploadingResumeId, setUploadingResumeId] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);

  const handleSetCareerCanvas = (careerCanvas: API.ICareerCanvas) => {
    setCareerCanvas(careerCanvas);
    setIsEdited(true);
  }

  // Career Canvas API Calls
  const fetchCareerCanvas = async (background: boolean = false) => {
    try {
      setIsFetching(!background);
      setError(null);
      setIsEdited(false);

      const canvas = await API.CareerCanvas.getCareerCanvas();
      setCareerCanvas(canvas);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something wen wrong while fetching your Career Canvas. Refresh the page to try again.");
    } finally {
      setIsFetching(false);
    }
  }

  const saveCareerCanvas = async () => {
    try {
      setIsSaving(true);
      setError(null);

      await API.CareerCanvas.updateCareerCanvas(careerCanvas);
      setIsEdited(false);
      setSuccessMessage("Your Career Canvas has been saved successfully!");
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something went wrong while saving your Career Canvas. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  const fetchResumes = async () => {
    try {
      setIsFetchingResumes(true);
      setError(null);

      const resumes = await API.CareerCanvas.listResumes();
      setResumes(resumes);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something went wrong while fetching your resumes. Refresh the page to try again.");
    } finally {
      setIsFetchingResumes(false);
    }
  }

  const uploadResume = async (file: File, override: boolean) => {
    try {
      setIsUploading(true);
      setError(null);

      const resume = await API.CareerCanvas.uploadResume(file, override);
      await fetchResumes();
      setUploadingResumeId(resume._id);
      setIsImporting(true);
      watchImportStatus(resume._id);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something went wrong while uploading your resume. Please try again.");
    } finally {
      setIsUploading(false);
    }
  }

  const downloadResume = async (resumeId: string, fileName: string) => {
    try {
      setIsDownloading(true);
      setError(null);
      const pdfBlob = await API.CareerCanvas.downloadResume(resumeId);
      // Abstract this behavior to a helper function
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something went wrong while downloading your resume. Please try again.");
    } finally {
      setIsDownloading(false);
    }
  }

  const deleteResume = async (resumeId: string) => {
    try {
      setIsDeleting(true);
      setError(null);

      await API.CareerCanvas.deleteResume(resumeId);
      setResumes(resumes.filter((resume) => resume._id !== resumeId));
    } catch (error) {
      if (error instanceof ResumeRoverAPIError && error.status === 401) {
        logout();
      }
      console.error(error);
      setError("Whoops! Something went wrong while deleting your resume. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  }

  const watchImportStatus = (resumeId: string) => {
    if (eventSource) {
      return;
    }

    let success = false;

    const evtSource = new EventSource(
      API.CareerCanvas.getResumeUploadStatusEventSourceURL(resumeId),
      { withCredentials: true }
    );
    setEventSource(evtSource);

    const cleanup = () => {
      evtSource.close();
      setIsImporting(false);
      setUploadingResumeId(null);
      setEventSource(null);
    }

    evtSource.onmessage = async (event) => {
      // log with timestamp
      console.log(`[${new Date().toLocaleTimeString()}] Message`, event.data);
      if (event.data === 'completed') {
        success = true;
        await fetchCareerCanvas(true);
        cleanup();
        setSuccessMessage("Your resume has been imported successfully!");
      }

      if (event.data === 'error') {
        cleanup();
        setError("Something went wrong while importing your resume. Please try again.");
      }
    }

    evtSource.onerror = (error) => {
      if (!success) {
        console.log("Event Source Error:", error);
        setError("Something went wrong while importing your resume. Please try again.");
        cleanup();
      }
    }
  }

  // OnLoad
  useEffect(() => {
    fetchCareerCanvas();
    fetchResumes();
  }, []);

  // We don't want the user to mutate the career canvas while doing
  // any async actions
  useEffect(() => {
    setShouldDisableActions(
      isFetching || isSaving || isDeleting || isUploading || isImporting
    );
  }, [isFetching, isSaving, isDeleting, isUploading, isImporting]);

  const value: CareerCanvasContextProps = useMemo(() => ({
    shouldDisableActions,
    isFetching,
    isSaving,
    isDeleting,
    isEdited,
    setIsEdited,
    error,
    setError,
    careerCanvas,
    setCareerCanvas: handleSetCareerCanvas, // Warning: THis may cause performance issues
    fetchCareerCanvas,
    saveCareerCanvas,

    // Resumes and Files
    resumeToUpload,
    resumes,
    isFetchingResumes,
    isDownloading,
    isUploading,
    isImporting,
    uploadingResumeId,
    successMessage,
    setSuccessMessage,
    fetchResumes,
    uploadResume,
    downloadResume,
    deleteResume,
  }), [
    shouldDisableActions,
    isFetching,
    isSaving,
    isDeleting,
    isEdited,
    error,
    careerCanvas,
    resumeToUpload,
    resumes,
    isFetchingResumes,
    isDownloading,
    isUploading,
    isImporting,
    uploadingResumeId,
    successMessage,
  ]);

  return (
    <CareerCanvasContext.Provider value={value}>
      {children}
    </CareerCanvasContext.Provider>
  );
}

export const useCareerCanvas = () =>
  useContext(CareerCanvasContext);
