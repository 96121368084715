import React from 'react';
import {
  FormControl,
  FormLabel,
  Select,
  Option,
} from '@mui/joy';

interface OptionSet {
  value: string;
  label: string;
}

interface Props {
  label: string;
  value: string;
  onChange: (newValue: string | null) => void;
  options: OptionSet[];
}

export default (props: Props) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>
        {props.label}
      </FormLabel>
      <Select
        value={props.value}
        onChange={(
          event: React.SyntheticEvent | null,
          newValue: string | null) => {
            event ? props.onChange(newValue) : props.onChange(null);
        }}
        variant="outlined"
      >
        {props.options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
}