import React, { useEffect } from 'react';
import { useRouteError, useNavigate } from "react-router-dom";
import Main from '../components/containers/MainNew';
import StyledSheet from '../components/containers/StyledSheet';
import PageTitle from '../components/PageTitle';
import { Stack, Typography } from '@mui/joy';

export default () => {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    if (error && error.status === 404) {
      navigate('/not-found');
    }
  }, [error])

  return (
    <Main>
      <StyledSheet>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={3}
          sx={{
            minHeight: "500px",
            minWidth: "100%",
          }}
        >
          <PageTitle>Oops! Something went wrong</PageTitle>

          <Typography level="body-md">
            We're sorry, but something went wrong. Please refresh the page.
          </Typography>
          
        </Stack>
      </StyledSheet>
    </Main>
  )
}