import React, { PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/joy';

import Navbar from '../navbar/';
import PageLoader from '../PageLoader';
import Confirmation from '../modals/Confirmation.tsx';
import FeedbackModal from '../modals/FeedbackModal.tsx';

import { useAuth } from '../../hooks/useAuthV2';
import HorizontalLayout from './HorizontalLayout.tsx';

interface Props {
  loading?: boolean
}

export default (props: PropsWithChildren<Props>) => {
  const { logout, setShowLogoutConfirmationModal, shouldShowLogoutConfirmationModal } = useAuth();
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  return (
    <Box
      sx={{
        pb: 10,
      }}
    >
      <Navbar
        openFeedbackModal={() => setOpenFeedbackModal(true)}
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <HorizontalLayout
        drawerOpen={drawerOpen}
      >
        {
          props.loading ? (
            <PageLoader />
          ) : (
            props.children
          )
        }
      </HorizontalLayout>

      <Confirmation
        open={shouldShowLogoutConfirmationModal}
        close={() => setShowLogoutConfirmationModal(false)}
        label="Are you sure you want to logout?"
        executeText="Logout"
        executeButtonColor="danger"
        executeAction={logout}
      />
      <FeedbackModal
        open={openFeedbackModal}
        close={() => setOpenFeedbackModal(false)}
      />
    </Box>
  );
}
