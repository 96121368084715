import React from 'react';
import {
  Snackbar,
  Stack,
  Typography,
  IconButton,
  Button,
  Link,
  Checkbox,
  Divider,
  Tooltip,
} from '@mui/joy';
import { InfoOutlined, Close } from '@mui/icons-material';

import { usePreferences } from '../../hooks/usePreferences';
import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';

import theme from '../../util/theme';

interface Props {
  show: boolean;
  hide: () => void;
}

export default (props: Props) => {
  const { preferences, updatePreferences, loading } = usePreferences();
  const [isChecked, setIsChecked] = React.useState(false);

  if (preferences.dont_show_resume_generation_hallucination_warning) {
    return null;
  }

  const handleCloseWithButton = async () => {
    if (isChecked) {
      await updatePreferences({
        dont_show_resume_generation_hallucination_warning: true,
      });
    }

    props.hide();
  }

  return (
    <>
      <Snackbar
        sx={{
          width: { xs: '100%', sm: '60%' },
        }}
        open={props.show}
        color="neutral"
        variant="outlined"
        onClose={props.hide}
      >
        <Stack direction="column" gap={1}>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Tooltip
              title="AI hallucinations are incorrect or misleading results that AI models generate."
              placement="top"
              variant="outlined"
            >
              < InfoOutlined />
            </Tooltip>
            <Typography level="title-md">
              Resume Generation Accuracy Warning
            </Typography>
            <IconButton
              onClick={props.hide}
              size="sm"
              variant="plain"
              color="neutral"
            >
              <Close />
            </IconButton>
          </Stack>

          <Divider />

          <Typography level="body-sm">
            Please note that while our AI technology strives to provide accurate and tailored resume content, there is a possibility for AI-generated outputs to include errors or <Link href="https://cloud.google.com/discover/what-are-ai-hallucinations?hl=en">hallucinations</Link>—incorrect information that seems plausible but is not based on factual data.
          </Typography>
          <Typography level="body-sm">
            We strongly recommend that users thoroughly review and verify all resume content before submission to ensure its accuracy and relevance. The responsibility for the final content lies with the user, and we advise using this service as an aid, not a replacement for careful proofreading and personal judgment.
          </Typography>

          <Divider />

          <Stack direction="row" gap={3} justifyContent="flex-end" alignItems="center">
            <Checkbox
              checked={isChecked}
              label="Don't show me this again"
              onChange={() => setIsChecked(!isChecked)}
            />
            <Button
              variant="solid"
              loading={loading}
              sx={{ backgroundColor: theme.colors.primary }}
              onClick={handleCloseWithButton}
            >
              Got it!
            </Button>
          </Stack>
        </Stack>
      </Snackbar>
    </>
  );
}