import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';

import { useLocalStorage } from './useLocalStorage';
import * as API from '../integrations/api';
import { useProfile } from './useProfile';
import ResumeRoverAPIError from '../util/error';
import ErrorSnackbar from '../components/snackBars/ErrorSnackbar';

export interface PreferencesContextProps {
  preferences: API.IUserPreferences;
  loading: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  updatePreferences: (preferences: API.IUserPreferences) => Promise<void>;
}

const PreferencesContext = createContext<PreferencesContextProps>({} as PreferencesContextProps);

export default ({ children }) => {
  const { profile } = useProfile();
  const [preferences, setPreferences] = useLocalStorage("preferences", profile?.preferences || {} as API.IUserPreferences);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const updatePreferences = async (preferences: API.IUserPreferences): Promise<void> => {
    try {
      setLoading(true);
      const response = await API.Profile.updateUserPreferences(preferences);
      setPreferences(response);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        setError(error.message);
      } else {
        setError("Sorry, we weren't able to update your preferences. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }

  const value: PreferencesContextProps = useMemo(() => ({
    preferences,
    loading,
    error,
    setError,
    updatePreferences,
  }), [preferences, loading, error]);

  return (
    <PreferencesContext.Provider value={value}>
      {children}
      <ErrorSnackbar
        error={error}
        setError={setError}
      />
    </PreferencesContext.Provider>
  );
}

export const usePreferences = () => 
  useContext(PreferencesContext);
