import React from 'react';
import { Button, Stack, Typography } from '@mui/joy';

import { IconUpload } from '@tabler/icons-react';
import { Link } from 'react-router-dom';


export default () => {
  return (
    <Stack
      direction="column"
      gap={3}
      justifyContent="center"
      alignItems="center"
    >
      <Typography level="h1">
        Lets Import Your Resume!
      </Typography>

      <Typography level="body-md">
        Before you can generate customized resumes, we need to get your Career Canvas created. Lets start by importing your resume. Click the button below to navigate to your Career Canvas!
      </Typography>

      <IconUpload size={128} stroke={3} />

      <Link to="/career-canvas">
        <Button
          variant="solid"
          color="primary"
          sx={{
            borderRadius: 24,
          }}
        >
          Import your Resume
        </Button>
      </Link>
    </Stack>
  );
}
