import React, { memo } from 'react';

import {
  Button,
  Stack,
  Typography,
  Textarea,
  IconButton,
} from '@mui/joy';
import { IconPlus, IconTrash, } from '@tabler/icons-react';

import { useCareerCanvas } from '../../hooks/useCareerCanvas';

interface AccomplishmentProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDelete: () => void;
}

const Accomplishment = memo((props: AccomplishmentProps) => {
  {/* https://dndkit.com/ */ }
  return (
    <Textarea
      placeholder="Write text..."
      value={props.value}
      onChange={props.onChange}
      minRows={1}
      endDecorator={
        <IconButton
          sx={{
            ml: "auto",
          }}
          color="danger"
          onClick={props.onDelete}
        >
          <IconTrash stroke={1} />
        </IconButton>
      }
    />
  );
});


interface Props {
  accomplishments: string[];
  index: number;
  typeKey: 'experience' | 'education' | 'projects';
}

export default (props: Props) => {
  const [accomplishments, setAccomplishments] = React.useState<string[]>(props.accomplishments);
  const { careerCanvas, setCareerCanvas } = useCareerCanvas();

  const handleAccomplishmentInputChange = (index: number): (e: React.ChangeEvent<HTMLTextAreaElement>) => void => {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newAccomplishments = accomplishments.map((acc, idx) => {
        if (index === idx) {
          return e.target.value;
        }
        return acc;
      });

      setAccomplishments(newAccomplishments);

      setCareerCanvas({
        ...careerCanvas,
        [props.typeKey]: careerCanvas[props.typeKey].map((item, i) => {
          if (i === props.index) {
            return {
              ...item,
              accomplishments: newAccomplishments,
            }
          }
          return item;
        }),
      });
    }
  }

  const handleDeleteAccomplishment = (index: number) => {
    return () => {
      const newAccomplishments = [...accomplishments];
      newAccomplishments.splice(index, 1);
      setAccomplishments(newAccomplishments);

      setCareerCanvas({
        ...careerCanvas,
        [props.typeKey]: careerCanvas[props.typeKey].map((item, i) => {
          if (i === props.index) {
            return {
              ...item,
              accomplishments: newAccomplishments,
            }
          }
          return item;
        }),
      });
    }
  }

  const handleAddAccomplishment = () => {
    setAccomplishments([...accomplishments, '']);
  }

  return (
    <>
      <Stack direction="column" gap={1}>
        <Typography level="title-lg">
          Accomplishments
        </Typography>
        <Typography level="body-sm">
          The Career Canvas is not your resume. Use this space to list all of the accomplishments and responsibilities you had in this role, education, or project. When you're ready to create a custom-tailored resume, Resume Rover will select the most relevant accomplishments for that specific job. We recommend being as thorough here as possible.
        </Typography>
      </Stack>

      
      <Stack direction="column" gap={0.5}>
        {accomplishments?.map((accomplishment, index) => (
          <Accomplishment
            key={index}
            value={accomplishment}
            onChange={handleAccomplishmentInputChange(index)}
            onDelete={handleDeleteAccomplishment(index)}
          />
        ))}
      </Stack>

      <Button
        variant="soft"
        color="primary"
        sx={{
          borderRadius: 24,
          textAlign: "center",
        }}
        onClick={handleAddAccomplishment}
      >
        <IconPlus stroke={1} />
      </Button></>
  );
}