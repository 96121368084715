import React from 'react';
import { Snackbar, Button } from '@mui/joy';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

interface Props {
  error: string | null;
  setError: (error: string | null) => void
}

const ErrorSnackbar = ({ error, setError }: Props) => {
  if (error === null) return null;

  return (
    <Snackbar
      open={error !== null && error !== ''}
      color="danger"
      variant="soft"
      onClose={() => setError(null)}
      startDecorator={
        <IconInfoCircle stroke={1} />
      }
      endDecorator={
        <Button
          onClick={() => setError(null)}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <IconX stroke={1} />
        </Button>
      }
    >
      {error}
    </Snackbar>
  );
}

export default ErrorSnackbar;