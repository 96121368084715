import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
  Checkbox,
  IconButton,
  Link as Anchor,
  Typography,
  Box,
  Card,
  CardContent,
  Stack,
} from '@mui/joy';
import { IconTrash } from '@tabler/icons-react';

import JobListingStatusChip from './JobListingStatusChip';
import JobListingActionButton from './JobListingActionButton';
import AsyncActionModal from '../../components/modals/AsyncAction';
import ConfirmationModal from '../../components/modals/Confirmation';

import { timeSince, newDate } from "../../util/dates";

import * as API from "../../integrations/api"
import { useJobListings } from '../../hooks/useJobListings';
import { useJobListing } from '../../hooks/useJobListing';
import { useGenerateResume } from '../../hooks/useGenerateResume';

interface Props {
  jobListing: API.IJobListing; 
}

export default (props: Props) => {
  const navigate = useNavigate();
  const isXSScreen = useMediaQuery('(max-width:600px)');

  const {
    fetchJobListings,
    careerCanvasCompleted,
    isJobListingSelected,
    handleAddSelectedJobListing,
    handleRemoveSelectedJobListing,
  } = useJobListings();

  const {
    jobListing,
    fetchJobListing,
    deleteJobListing,
    reImportJobListing,
  } = useJobListing(props.jobListing);

  const {
    isGenerating,
    isGeneratingForJobListing,
    generateResume,
    setShowModal,
    showCareerCanvasRequiredModal,
    setShowCareerCanvasRequiredModal,
    error,
    success,
    jobListing: generatingJobListing,
  } = useGenerateResume();

  const [showIsDeletingModal, setShowIsDeletingModal] = useState(false);

  useEffect(() => {
    // Refetch the job listing on success/error event
    if (jobListing._id === generatingJobListing?._id && (success || error)) {
      fetchJobListing();
    }
  }, [success, error]);

  const handleDeleteJobListing = async () => {
    setShowIsDeletingModal(true);
    await deleteJobListing();
    await fetchJobListings();
    setShowIsDeletingModal(false);
  }

  const handleToggleSelectJobListing = () => {
    isJobListingSelected(jobListing._id) ?
      handleRemoveSelectedJobListing(jobListing._id) :
      handleAddSelectedJobListing(jobListing._id);
  }

  const handleAttemptGenerateResume = async () => {
    if (!careerCanvasCompleted) {
      setShowCareerCanvasRequiredModal(true);
    } else if (isGeneratingForJobListing(jobListing._id)) {
      setShowModal(true);
    } else {
      await generateResume(jobListing);
      await fetchJobListing();
    }
  }

  if (isXSScreen) {
    return (
      <Card key={jobListing._id} sx={{ width: "100%" }}>
        <Box>
          <Typography level="title-lg">
            {jobListing.title}
          </Typography>
          <Typography level="body-sm">
            at {jobListing.company}
          </Typography>
        </Box>

        <CardContent orientation='horizontal'>
          <Box>
            <JobListingStatusChip jobListing={jobListing} />
            <Typography level="body-sm">
              {timeSince(newDate(jobListing.created_at))}
            </Typography>
          </Box>
          <Stack
            direction="row"
            gap={2}
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{
              ml: 'auto',
            }}
          >
            <JobListingActionButton
              jobListing={jobListing}
              isGenerating={isGenerating}
              importJobListing={reImportJobListing}
              generateResume={handleAttemptGenerateResume}
              viewGenerateModal={() => setShowModal(true)}
              viewResume={() => navigate(`/resume-builder/${jobListing.resume_builder_id}`)}
            />

            <IconButton
              color="danger"
              variant="plain"
              onClick={handleDeleteJobListing}
            >
              <IconTrash stroke={1} />
            </IconButton>
          </Stack>
        </CardContent>

        <AsyncActionModal
          open={showIsDeletingModal}
          close={() => setShowIsDeletingModal(false)}
          message="Deleting Job Listing"
          status="This could take a moment"
        />
        <ConfirmationModal
          open={showCareerCanvasRequiredModal}
          close={() => setShowCareerCanvasRequiredModal(false)}
          label="Your Career Canvas is isn't complete"
          message="You haven't completed your Career Canvas and won't be able to generate custom resumes until you do. Would you like to do so now?"
          executeText="To Career Canvas"
          executeAction={() => navigate('/career-canvas')}
        />
      </Card>
    );
  }

  return (
    <tr>
      <td aria-label="Select">
        <Checkbox
          checked={isJobListingSelected(jobListing._id)}
          onChange={() => handleToggleSelectJobListing()}
        />
      </td>
      <td aria-label="Job Title" style={{ paddingRight: 24 }}>
        {
          jobListing.url.length > 0 ? (
            <Anchor
              href={jobListing.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              {jobListing.title}
            </Anchor>
          ) : (
            <Typography level="body-sm">
              {jobListing.title}
            </Typography>
          )
        }
      </td>
      <td aria-label="Company">
        <Typography level="body-sm">
          {jobListing.company}
        </Typography>
      </td>
      <td aria-label="Saved At">
        <Typography level="body-xs">
          {timeSince(newDate(jobListing.created_at))}
        </Typography>
      </td>
      <td aria-label="Status">
        <JobListingStatusChip jobListing={jobListing} />
      </td>
      <td aria-label="Resume Action" style={{ textAlign: "center"}}>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <JobListingActionButton
            jobListing={jobListing}
            isGenerating={isGenerating}
            importJobListing={reImportJobListing}
            generateResume={handleAttemptGenerateResume}
            viewGenerateModal={() => setShowModal(true)}
            viewResume={() => navigate(`/resume-builder/${jobListing.resume_builder_id}`)}
          />
          <IconButton
            color="danger"
            variant="plain"
            disabled={isGenerating}
            onClick={handleDeleteJobListing}
          >
            <IconTrash stroke={1} />
          </IconButton>
        </Stack>
      </td>

      <AsyncActionModal
        open={showIsDeletingModal}
        close={() => setShowIsDeletingModal(false)}
        message="Deleting Job Listing"
        status="This could take a moment"
      />
      <ConfirmationModal
        open={showCareerCanvasRequiredModal}
        close={() => setShowCareerCanvasRequiredModal(false)}
        label="Your Career Canvas is isn't complete"
        message="You haven't completed your Career Canvas and won't be able to generate custom resumes until you do. Would you like to do so now?"
        executeText="To Career Canvas"
        executeAction={() => navigate('/career-canvas')}
      />
    </tr>
  );
}