import React from 'react';
import {
  Modal,
  ModalDialog,
  ModalClose,
  ModalOverflow,
  Typography,
  Stack,
  Divider,
  Link,
  Button,
} from '@mui/joy';

interface Props {
  open: boolean;
  close: () => void;
}

export default (props: Props) => {

  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalOverflow>
        <ModalDialog
          color="neutral"
          variant="outlined"
          sx={{
            maxWidth: { xs: "100%", sm: "80%", md: "60%" },
          }}
        >
          <Stack
            direction="column"
            gap={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography level="h2">
                Welcome to your Career Canvas!
              </Typography>

              <ModalClose />
            </Stack>

            <Divider />

            <Typography level="h4">
              What is my Career Canvas?
            </Typography>

            <Typography level="body-sm" sx={{ px: 2 }}>
              Your Career Canvas is like a personal record of everything you’ve achieved in your career. It’s a place to save all your skills, work experiences, education, certifications, and projects. For each of these sections, you can add as many details as you want about what you’ve accomplished, going far beyond the usual few bullet points you'd include on a resume.
              <br />
              Then, when we're creating custom resumes for you, we can pull the most relevant information from each section.
            </Typography>

            <Typography level="h4">
              Why does this matter?
            </Typography>

            <Typography level="body-sm" sx={{ px: 2 }}>
              Every job and employer looks for specific skills and experiences. The more details you have in your Career Canvas, the better we can customize your resume to fit each job. This helps you stand out to recruiters and improves your chances of landing an interview.
            </Typography>

            <Typography level="h4">
              How do I get started?
            </Typography>

            <Typography level="body-sm" sx={{ px: 2 }}>
              You can start by uploading one or more of your current resumes or by adding your information manually. We'll help you add details like your skills, experience, education, certifications, and projects. Once you've filled in enough information in your Career Canvas, you can add job listings, and we'll begin creating custom resumes for you to use.
            </Typography>

            <Typography level="h4">
              Why would I want to upload more than one resume?
            </Typography>

            <Typography level="body-sm" sx={{ px: 2 }}>
              Each resume you upload adds more information to your Career Canvas. We’ll only add new details, so if your old resumes mention different achievements for the same job, uploading them all helps capture everything in one place.
              <br />
              After you upload a resume, we’ll save its content to our servers. You can delete the file from our servers afterward or keep it for reference, and the information will still stay in your Career Canvas.
            </Typography>

            <Divider />

            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              sx={{
                width: '100%',
              }}
            >
              <Link
                href="mailto: contact@resumerover.io"
                target="_blank"
              >
                I still have questions
              </Link>

              <Button
                variant="solid"
                color="primary"
                onClick={props.close}
              >
                Got it!
              </Button>
            </Stack>
          </Stack>

        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
