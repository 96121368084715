import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from "@mui/joy";
import { useMediaQuery } from '@mui/material';

import MainNew from '../../components/containers/MainNew';
import CentralSheet from '../../components/containers/CentralSheet';
import ErrorSnackbar from '../../components/snackBars/ErrorSnackbar';
import SuccessSnackbar from '../../components/snackBars/SuccessSnackbar';

import NoListingsFound from './NoListingsFound';
import JobListingTable from './JobListingTable';
import ControlPanelRow from './ControlPanelRow';
import PaginationRow from './PaginationRow';
import GeneratingResumeModal from './GeneratingResumeModal';
import BulkActionPanel from './BulkActionPanel';
import GenerationsRemaining from './GenerationsRemaining';

import { useJobListings } from '../../hooks/useJobListings';
import { useGenerateResume } from '../../hooks/useGenerateResume';
import JobListingsCards from './JobListingsCards';

export default () => {
  const {
    noJobListingsAvailable,
    loading,
    error,
    setError,
  } = useJobListings();

  const {
    error: generateResumeError,
    setError: setGenerateResumeError,
    generatingResumeBuilderId,
    jobListing,
    success,
    showModal,
  } = useGenerateResume();

  const [showSuccessSnackbar, setShowSuccessSnackbar] = React.useState(false);
  const isXSScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (success && !showModal) {
      setShowSuccessSnackbar(true);
    }
  }, [success]);

  return (
    <MainNew loading={loading}>
      { isXSScreen ? (
        <CentralSheet
          pageTitle="Imported Job Listings"
          px={2}
          py={2}
        >
          {
            noJobListingsAvailable ? (
              <NoListingsFound />
            ) : (
              <>
                <ControlPanelRow />
                <GenerationsRemaining />
                <JobListingsCards />
              </>
            )
          }
        </CentralSheet>
      ) : (
        <CentralSheet
          pageTitle="Imported Job Listings"
        >

          {
            noJobListingsAvailable ? (
              <NoListingsFound />
            ) : (
              <>
                <ControlPanelRow />
                <BulkActionPanel />
                <GenerationsRemaining />
                <JobListingTable />
                <PaginationRow />
              </>
            )
          }
        </CentralSheet>
      )}

      <GeneratingResumeModal />
      <ErrorSnackbar
        // Job Listings Error Snackbar
        error={error}
        setError={setError}
      />
      <ErrorSnackbar
        // Generate Resume Error Snackbar
        error={generateResumeError}
        setError={setGenerateResumeError}
      />
      <SuccessSnackbar
        open={showSuccessSnackbar}
        close={() => setShowSuccessSnackbar(false)}
      >
        <Typography level="body-md">
          We have successfully generated a customized resume for your saved job {jobListing?.title} at {jobListing?.company}. You can view it <Link to={`/resume-builder/${generatingResumeBuilderId}`}>here</Link>.
        </Typography>
      </SuccessSnackbar>
    </MainNew>
  );
}