import React, { PropsWithChildren } from 'react';
import {
  Stack,
  Typography,
} from '@mui/joy';

interface Props {
  pageTitle: string;
  borderColor?: string;
  px?: number;
  py?: number;
}

import StyledSheet from './StyledSheet';

export default (props: PropsWithChildren<Props>) => {
  return (
    <Stack
      direction="column"
      gap={2}
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography level="h1">
        {props.pageTitle}
      </Typography>
      <StyledSheet
        borderColor={props.borderColor}
        px={props.px}
        py={props.py}
      >
        {props.children}
      </StyledSheet>
    </Stack>
  );
}