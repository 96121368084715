import React from 'react';
import {
  Table,
} from '@mui/joy';

import TableHeader from './TableHeader';
import JobListingRow from './JobListingRow';

import { useJobListings } from '../../hooks/useJobListings';

export default () => {
  const { jobListings } = useJobListings();

  return (
    <Table
      borderAxis="xBetween"
      stickyHeader
      variant='outlined'
      noWrap
      sx={{
        overflow: 'auto',
      }}
    >
      <TableHeader />
      <tbody>
        {
          jobListings?.map((jobListing) => (
            <JobListingRow
              key={jobListing._id}
              jobListing={jobListing}
            />
          ))
        }
      </tbody>
    </Table>
  );
}