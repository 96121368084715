import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Stack,
  IconButton,
  CircularProgress,
} from '@mui/joy';
import { IconRefresh, IconSearch, IconX } from '@tabler/icons-react';

import Input from '../../components/FormFields/Input';
import Select from '../../components/FormFields/Select';

import { useJobListings } from '../../hooks/useJobListings';
import type { StatusFilter } from '../../hooks/useJobListings';
import { JobListingState } from '../../integrations/api';

export default () => {
  const {
    searchQuery,
    setSearchQuery,
    isSearching,
    statusFilter,
    setStatusFilter,
    fetchJobListings,
    isFetching,
  } = useJobListings();

  const MaybeLoadingIcon = () => {
    if (isSearching) {
      return (
        <CircularProgress size="sm" color="neutral" />
      );
    }

    if (searchQuery.length > 0) {
      return (
        <IconButton
          variant="plain"
          color="neutral"
          onClick={() => {
            setSearchQuery(
              { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>
            );
          }}
        >
          <IconX stroke={1} />
        </IconButton>
      );
    }

    return null;
  }

  const isXSScreen = useMediaQuery('(max-width:600px)');

  return (
    <Stack
      direction="row"
      columnGap={1}
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{
        pb: 2,
      }}
    >
      <Input
        label="Search"
        value={searchQuery}
        onChange={setSearchQuery}
        startDecorator={
          <IconSearch stroke={1} />
        }
        endDecorator={
          <MaybeLoadingIcon />
        }
        minWidth="75%"
      />

      { !isXSScreen && (
        <Select
          label="View by Status"
          value={statusFilter}
          onChange={newValue => {
            newValue ? setStatusFilter(newValue as StatusFilter) : setStatusFilter('all');
          }}
          options={[
            { value: 'all', label: 'All' },
            { value: JobListingState.CREATED, label: 'Ready To Import' },
            { value: JobListingState.IMPORTING, label: 'Importing' },
            { value: JobListingState.IMPORTED, label: 'Ready to Generate' },
            { value: JobListingState.IMPORT_ERROR, label: 'Import Error' },
            { value: JobListingState.GENERATING, label: 'Generating' },
            { value: JobListingState.GENERATED, label: 'Ready to View' },
            { value: JobListingState.GENERATE_ERROR, label: 'Generate Error' },
            { value: JobListingState.ERROR, label: 'Error' },
          ]}
        />
      )}

      <IconButton
        variant="outlined"
        color="neutral"
        loading={isFetching}
        onClick={() => fetchJobListings()}
      >
        <IconRefresh stroke={1}/>
      </IconButton>
    </Stack>
  );
}