import React from 'react';
import {
  Typography,
  FormControl,
  FormLabel,
  Textarea,
  Tooltip,
  FormHelperText,
} from '@mui/joy';
import { IconInfoCircle } from '@tabler/icons-react';

interface Props {
  label: string;
  name: string;
  infoText: string;
  value: string;
  warningText?: string;
  onChange: (value: string) => void;
}

export default (props: Props) => {
  return (
    <FormControl sx={{ width: "100%" }} >
      <FormLabel>
        <Typography
          level="title-sm"
          endDecorator={
            <Tooltip
              arrow
              variant="outlined"
              title={props.infoText}
            >
              <IconInfoCircle stroke={1} />
            </Tooltip>
          }
        >
          {props.label}
        </Typography>
      </FormLabel>
      <Textarea
        name={props.name}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        sx={{
          width: "100%",
        }}
        minRows={12}
        maxRows={12}
      />
      {
        props.warningText === undefined ? null : (
          <FormHelperText>
            <Typography level="body-xs" color="danger">
              {props.warningText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
}
