import ResumeRoverAPIError from "../../util/error";
import * as Sentry from "@sentry/browser";

export enum UserType {
  SuperAdmin = "super_admin",
  StandardUser = "user",
  InternalUser = "internal_user",
  AlphaTester = "alpha_tester",
  BetaTester = "beta_tester",
}

export interface IProfile {
  email: string
  first_name: string;
  last_name: string;
  user_type: UserType;
  signed_beta_agreement_version: string | null;
  signed_beta_agreement_at: string | null;
  photo_url: string | null;
  preferences: IUserPreferences;
  onboarding: IOnboarding;
  usage: IUsage;
  created_at: string;
  cache_expiry: number; // new Date().getTime();
}

export interface IUsage {
  user_id: string;
  plan_name: string;
  total_grants: number;
  period_start: string;
  period_end: string;
  total_imports_used: number;
  total_generations_used: number;
}

export interface IUserPreferences {
  dont_show_resume_generation_hallucination_warning: boolean;
}

export interface IOnboarding {
  subscription_flow_completed: string | null;
  chrome_extension_authenticated: string | null;
  resume_imported: string | null;
  career_canvas_completed: string | null;
  job_listing_imported: string | null;
  resume_generated: string | null;
}

export default class Profile {
  // @ts-ignore
  static baseUrl: string = `${process.env['API_BASE_URL']}/api/v1/me`;

  static async getMe(): Promise<IProfile> {
    try {
      const response = await fetch(Profile.baseUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error Fetching Profile, please try again.');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error Fetching Profile, please try again.', 500);
    }
  }

  static async updateMe(
    firstName: string | null,
    lastName: string | null,
  ): Promise<IProfile> {
    try {
      const response = await fetch(Profile.baseUrl, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ first_name: firstName, last_name: lastName }),
      });
      
      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error Updating Profile, please try again.');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error Updating Profile, please try again.', 500);
    }
  }

  static async uploadPhoto(file: File): Promise<IProfile> {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${Profile.baseUrl}/photo`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      throw new Error('Failed to upload photo');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to upload photo', 500);
    }
  }

  static async updateUserPreferences(preferences: IUserPreferences): Promise<IUserPreferences> {
    try {
      const response = await fetch(`${Profile.baseUrl}/preferences`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(preferences),
      });
      
      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Error Updating User Preferences, please try again.');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error Updating User Preferences, please try again.', 500);
    }
  }

  // Only free plan supported
  static async createUserSubscription(): Promise<void> {
    try {
      const response = await fetch(`${Profile.baseUrl}/onboarding/subscribe/free`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      
      throw new ResumeRoverAPIError(`Error creating free Subscription, please try again.`, response.status);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError(`Error creating free Subscription, please try again.`, 500);
    }
  }

  static async createUserCheckoutSession(): Promise<string> {
    try {
      const response = await fetch(`${Profile.baseUrl}/onboarding/checkout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        // @ts-ignore
        const body = await response.json();
        return body.client_secret;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      
      throw new ResumeRoverAPIError('Error starting checkout, please try again.', response.status);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error starting checkout, please try again.', 500);
    }
  }

  static async signBetaAgreement(version: string): Promise<void> {
    try {
      const response = await fetch(`${Profile.baseUrl}/beta_agreement`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ version }),
      });
      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      
      throw new ResumeRoverAPIError('Error Agreeing to Beta Terms, please try again.', response.status);
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      throw new ResumeRoverAPIError('Error Agreeing to Beta Terms, please try again.', 500);
    }
  }
}