import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/joy';

interface Props {
  px?: number;
  py?: number;
}

export default (props: PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        px: props.px || 3,
        py: props.py || 3,
        backgroundColor: "#F9FAFC", //"#F2F3F4",
        borderColor: '#DDE7EE',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 12,
      }}
    >
      {props.children}
    </Box>
  );
}