import React from 'react';
import {
  Stepper,
  Step,
  StepIndicator,
  StepButton,
} from '@mui/joy';
import { IconCheck } from '@tabler/icons-react';

import { useWizard } from './hooks/useWizard';
import { useCareerCanvas } from '../../hooks/useCareerCanvas';

export default () => {
  const { steps, activeStep, setActiveStep } = useWizard();
  const { shouldDisableActions } = useCareerCanvas();

  const isComplete = (step: number): boolean => {
    return activeStep > step;
  }

  const isActive = (step: number): boolean => {
    return activeStep === step;
  }

  const determineStepColor = (step: number): 'primary' | 'neutral' => {
    return isComplete(step) || isActive(step) ? 'primary' : 'neutral';
  }

  const determineStepVariant = (step: number): 'solid' | 'outlined' | 'soft' => {
    if (isComplete(step)) {
      return 'soft';
    }

    return isActive(step) ? 'solid' : 'outlined';
  }

  const determineIcon = (step: number): any => {
    if (isComplete(step)) {
      return <IconCheck stroke={1} size={17}/>;
    }
    
    return step.toString();
  }  

  return (
    <Stepper
      orientation="horizontal"
      sx={{
        width: '100%',
      }}
    >
      {steps.map((step) => (
        <Step
          key={step.step}
          indicator={
            <StepIndicator
              variant={determineStepVariant(step.step)}
              color={determineStepColor(step.step)}
            >
              {determineIcon(step.step)}
            </StepIndicator>
          }
        >
          <StepButton
            disabled={shouldDisableActions}
            onClick={() => setActiveStep(step.step)}
          >
            {step.label}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}