import ResumeRoverAPIError from "../../util/error";
import * as Sentry from "@sentry/browser";

import { objectId } from '../../util/id'

export interface IExperience {
  id: string; // Using id instead of _id because this is not generated by Mongo
  title: string;
  company: string;
  location: string;
  current: boolean;
  start_date: string | null;
  end_date: string | null;
  accomplishments: string[];
}

export interface IEducation {
  id: string; // Using id instead of _id because this is not generated by Mongo
  institution: string;
  degree: string;
  start_date: string | null;
  end_date: string | null;
  accomplishments: string[];
}

export interface ICertification {
  id: string; // Using id instead of _id because this is not generated by Mongo
  title: string;
  issuer: string;
  date: string | null;
  url: string;
}

export interface IProject {
  id: string; // Using id instead of _id because this is not generated by Mongo
  title: string;
  description: string;
  accomplishments: string[];
}

export interface ICanvasProfile {
  user_id: string;
  name: string;
  title: string;
  email: string;
  phone: string;
  location: string;
  linked_in: string;
  skills: string[];
}

export interface ICareerCanvas {
  _id: string;
  user_id: string;
  name: string;
  title: string;
  email: string;
  phone: string;
  location: string;
  linked_in: string;
  skills: string[];
  summary: string;
  experience: IExperience[];
  education: IEducation[];
  projects: IProject[];
  certifications: ICertification[];
  edited: boolean;
  status: CareerCanvasStatus;
  created_at: string;
  updated_at: string;
}

export interface IFile {
  _id: string;
  name: string;
  type: string
  size: number;
  bucket: string;
  key: string;
  created_at: string;
  updated_at: string;
}

export enum CareerCanvasStatus {
  EMPTY = "empty",
  IMPORTING = "importing",
  IMPORT_ERROR = "import_error",
  EDITED = "edited",
  COMPLETED = "completed",
  ERROR = "error",
}

export enum ResumeUploadStatus {
  COMPLETED = 'completed',
  ERROR = 'error',
}

export const genEmptyCareerCanvas = (): ICareerCanvas => ({
  _id: '',
  user_id: '',
  name: '',
  title: '',
  email: '',
  phone: '',
  location: '',
  linked_in: '',
  skills: [],
  summary: '',
  experience: [],
  education: [],
  projects: [],
  certifications: [],
  edited: false,
  status: CareerCanvasStatus.EMPTY,
  created_at: '',
  updated_at: '',
});

export const genEmptyExperience = (): IExperience => ({
  id: objectId(),
  title: "New Experience",
  company: '',
  location: '',
  current: false,
  start_date: null,
  end_date: null,
  accomplishments: [],
});

export const genEmptyEducation = (): IEducation => ({
  id: objectId(),
  degree: "New Education",
  institution: '',
  start_date: null,
  end_date: null,
  accomplishments: [],
});

export const genEmptyCertification = (): ICertification => ({
  id: objectId(),
  title: "New Certification",
  issuer: '',
  date: null,
  url: '',
});

export const genEmptyProject = (): IProject => ({
  id: objectId(),
  title: "New Project",
  description: '',
  accomplishments: [],
});

export default class CareerCanvas {
  // @ts-ignore
  static baseUrl: string = `${process.env['API_BASE_URL']}/api/v1/career_canvas`;

  static getResumeUploadStatusEventSourceURL(resumeId: string): string {
    return `${CareerCanvas.baseUrl}/resume/${resumeId}/status`;
  }
  
  // Old Event Source Stuff
  private static eventSource: EventSource | null = null;

  static getResumeUploadStatusEventSource(resumeId: string): EventSource {
    return CareerCanvas.getEventSource(`${CareerCanvas.baseUrl}/resume/${resumeId}/status`);
  }

  private static getEventSource(url: string): EventSource {
    if (!CareerCanvas.eventSource) {
      CareerCanvas.eventSource = new EventSource(url, { withCredentials: true });
    }

    return CareerCanvas.eventSource;
  }

  static deleteEventSource() {
    if (CareerCanvas.eventSource) {
      CareerCanvas.eventSource.close();
      CareerCanvas.eventSource = null;
    }
  }

  static async getCareerCanvas(): Promise<ICareerCanvas> {
    try {
      const response = await fetch(CareerCanvas.baseUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      
      throw new Error('Failed to get career canvas');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to get career canvas', 500);
    }
  }

  static async updateCareerCanvas(data: ICareerCanvas): Promise<ICareerCanvas> {
    try {
      const response = await fetch(CareerCanvas.baseUrl, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      throw new Error('Failed to update career canvas');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to update career canvas', 500);
    }
  }

  static async uploadResume(file: File, override: boolean): Promise<{ _id: string }> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);

      const response = await fetch(`${CareerCanvas.baseUrl}/resume?override=${override}`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }
      throw new Error('Failed to upload resume');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to upload resume', 500);
    }
  }

  static async listResumes(): Promise<IFile[]> {
    try {
      const response = await fetch(`${CareerCanvas.baseUrl}/resume`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        return await response.json();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Failed to list resume');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Uh-oh, something went wrong. We weren\'t able to fetch your existing resumes. Please reload the page and try again.', 500);
    }
  }

  static async downloadResume(id: string): Promise<any> {
    try {
      const response = await fetch(`${CareerCanvas.baseUrl}/resume/${id}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        return await response.blob();
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Failed to download resume');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to download resume', 500);
    }
  }

  static async deleteResume(id: string): Promise<void> {
    try {
      const response = await fetch(`${CareerCanvas.baseUrl}/resume/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        return;
      }

      if (response.status === 401) {
        throw new ResumeRoverAPIError('Unauthorized', response.status);
      }

      throw new Error('Failed to delete resume');
    } catch (error) {
      if (error instanceof ResumeRoverAPIError) {
        throw error;
      }
      Sentry.captureException(error);
      console.error('Error:', error);
      throw new ResumeRoverAPIError('Failed to delete resume', 500);
    }
  }
}