import React from 'react';
import { Link as Anchor, useLocation } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuthV2';
import {
  AppBar,
  Toolbar,
} from '@mui/material';

import {
  IconButton,
  Box,
  Stack,
} from '@mui/joy';

import { IconAddressBook, IconMenu } from '@tabler/icons-react';
import { IconList } from '@tabler/icons-react';

// @ts-ignore
import headerLogo from '../../../../assets/Header-Black.png';

import ProfileNavItem from './ProfileNavItem';
import StandardNavItem from './StandardNavItem';
import HorizontalLayout from '../../containers/HorizontalLayout';

interface Props {
  openFeedbackModal: () => void;
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
}

export default (props: Props) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return (
    <AppBar
      component="nav"
      sx={{ boxShadow: 'none', }}
    >
      <Toolbar style={{ padding: 0, marginLeft: -7, marginRight: -7, minWidth: '100%', backgroundColor: "white" }}>
        <HorizontalLayout>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >

            <Box sx={{ pl: { xs: 2, sm: 0 } }}>
              <Anchor to="/">
                <img
                  src={headerLogo}
                  alt="header logo"
                  style={{ width: 175, marginTop: 7.5 }}
                />
              </Anchor>
            </Box>

            {
              isAuthenticated && (
                <IconButton
                  aria-label="open drawer"
                  onClick={props.handleDrawerToggle}
                  sx={{ px: 2, display: { sm: 'none', xs: 'block' } }}
                >
                  <IconMenu />
                </IconButton>
              )
            }

            {/* Central Nav Items */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Stack direction="row" spacing={2} alignItems="space-between">

                <StandardNavItem
                  isAuthenticated={isAuthenticated}
                  currentPath={location.pathname}
                  targetPath="/"
                  title="Job Listings"
                >
                  <IconList stroke={1} />
                </StandardNavItem>

                <StandardNavItem
                  isAuthenticated={isAuthenticated}
                  currentPath={location.pathname}
                  targetPath="/career-canvas"
                  title="Career Canvas"
                >
                  <IconAddressBook stroke={1} />
                </StandardNavItem>

              </Stack>
            </Box>

            {/* Right Nav Items */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <ProfileNavItem
                isAuthenticated={isAuthenticated}
                currentPath={location.pathname}
                openFeedbackModal={props.openFeedbackModal}
              />
            </Box>

          </Stack>
        </HorizontalLayout>
      </Toolbar>
    </AppBar>
  );
}
