import React, { createContext, useContext, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';

export type ViewState = 'shared' | 'editorMaximized' | 'jobListingMaximized';
export type Component = 'editor' | 'jobListing';

interface MaximizeContextProps {
  viewState: ViewState;
  isMaximized: (component: Component, viewState: ViewState) => boolean;
  toggleViewState: (component: Component) => void;
}

const MaximizeContext = createContext<MaximizeContextProps>({} as MaximizeContextProps);

export default ({ children }) => {
  const isXSScreen = useMediaQuery('(max-width:600px)');
  const [viewState, setViewState] = useState<ViewState>(
    isXSScreen ? "editorMaximized" : "shared"
  );

  const isMaximized = (component: Component, viewState: ViewState) => {
    return viewState === 'editorMaximized' && component === 'editor' ||
      viewState === 'jobListingMaximized' && component === 'jobListing';
  }

  const toggleViewState = (component: Component) => {
    if (isXSScreen && viewState === "editorMaximized") {
      setViewState("jobListingMaximized");
    } else if (isXSScreen && viewState === "jobListingMaximized") {
      setViewState("editorMaximized");
    } else if (["editorMaximized", "jobListingMaximized"].includes(viewState)) {
      setViewState('shared');
    } else {
      setViewState(`${component}Maximized` as ViewState);
    }
  }

  const value: MaximizeContextProps = useMemo(() => ({
    viewState,
    isMaximized,
    toggleViewState,
  }), [viewState]);

  return (
    <MaximizeContext.Provider value={value}>
      {children}
    </MaximizeContext.Provider>
  );
}

export const useMaximize = () => useContext(MaximizeContext);