import React from 'react';
import {
  Stack,
  IconButton,
  Button,
  Modal,
  ModalDialog,
  Typography,
} from '@mui/joy'
import { IconX } from '@tabler/icons-react'

type acceptableColors = 'primary' | 'neutral' | 'danger' | 'success';

interface Props {
  open: boolean;
  close: () => void;
  label: string;
  message?: string;
  cancelButtonColor?: acceptableColors;
  executeButtonColor?: acceptableColors;
  executeText: string;
  executeAction: () => void;
}

export default (props: Props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
    >
      <ModalDialog>
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="flex-end"
          sx={{
            width: '100%',
          }}
        >
          <IconButton
            variant="plain"
            onClick={() => props.close()}
          >
            <IconX stroke={1} />
          </IconButton>
        </Stack>
        <Stack
          direction="column"
          gap={2}
          alignContent="center"
          justifyContent="center"
        >
          <Typography level="h3" sx={{ textAlign: 'center'}}>{props.label}</Typography>
          {props.message ? (
            <Typography level="body-sm" sx={{ textAlign: 'center' }}>{props.message}</Typography>
          ) : null}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={1}
          sx={{
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            color={props.cancelButtonColor ? props.cancelButtonColor : "primary"}
            sx={{
              borderRadius: 24,
            }}
            onClick={() => {
              props.close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            color={props.executeButtonColor ? props.executeButtonColor : "primary"}
            sx={{
              borderRadius: 24,
            }}
            onClick={() => {
              props.executeAction();
            }}
          >
            {props.executeText}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}