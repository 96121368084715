import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Stack,
  Typography,
  Link,
  Divider,
} from '@mui/joy';

// Components
import StyledSheet from '../../../components/containers/StyledSheet';
import ErrorSnackbar from '../../../components/snackBars/ErrorSnackbar';
import SignInWithGoogle from './SignInWithGoogle';
import EmailSignUp from './EmailSignup';
import EmailSignIn from './EmailSignIn';

import { useAuth } from '../../../hooks/useAuthV2';

import { validateEmail } from '../../../util/email';
import ResetPassword from './ResetPassword';

interface Props {
  elementRef: React.RefObject<HTMLDivElement> | undefined;
}

export default (props: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const nextRoute = searchParams.get('next_route');
  const [stytch_token_type] = useState<string | null>(searchParams.get('stytch_token_type'));
  const [token] = useState<string | null>(searchParams.get('token'));

  // Signup/Signin State
  const [displaySignin, setDisplaySignin] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false);

  const {
    isAuthenticated,
    authenticateOAuth,
    authenticationError,
  } = useAuth();

  const handleRedirectFromStytch = async () => {
    if (stytch_token_type && token && !error) {
      try {
        await authenticateOAuth(token);
        handleRouteToApp();
      } catch (error) {
        setError(error.message);
      }
    }
  }

  const handleRouteToApp = () => {
    if (nextRoute && nextRoute !== '/login') {
      navigate(nextRoute);
    } else {
      navigate('/');
    }
  }

  // Handle Redirects
  useEffect(() => {
    if (stytch_token_type && token) {
      handleRedirectFromStytch();
    }
  }, [token, stytch_token_type]);

  // Log the user in
  useEffect(() => {
    if (isAuthenticated) {
      handleRouteToApp();
    }
  }, [isAuthenticated]);

  // Email Validation
  useEffect(() => {
    setEmailIsValid(email.length <= 0 || validateEmail(email));
  }, [email]);

  // Error Handling
  useEffect(() => {
    if (authenticationError) {
      setError(authenticationError);
    }
  }, [authenticationError]);

  const renderInnerContent = () => {
    if (isResettingPassword) {
      return (
        <Stack
          direction="column"
          gap={3}
          justifyContent={{ xs: "center", sm: "flex-start" }}
          alignItems="center"
          sx={{
            py: { xs: 2, sm: 15 },
            px: 4,
          }}
        >
          <Typography level="h1" sx={{ textAlign: "center"}}>
            Reset your Password
          </Typography>

          <ResetPassword
            email={email}
            setEmail={setEmail}
            emailIsValid={emailIsValid}
            setError={setError}
            switchToSignin={() => setDisplaySignin(true)}
          />
        </Stack>
        
      );
    }

    return (
      <Stack
        direction="column"
        gap={3}
        justifyContent={{ xs: "center", sm: "flex-start" }}
        alignItems="center"
        sx={{
          py: { xs: 2, sm: 15 },
          px: 4,
        }}
      >
        <Typography level="h1">
          Sign Up
        </Typography>
        <Typography level="title-md">
          Already have an account? Sign in below.
        </Typography>

        <SignInWithGoogle nextRoute={nextRoute} />

        <Divider>{" "}or{" "}</Divider>

        {
          displaySignin ? (
            <EmailSignIn
              email={email}
              setEmail={setEmail}
              emailIsValid={emailIsValid}
              setError={setError}
              switchToSignup={() => setDisplaySignin(false)}
              switchToResetingPassword={() => setIsResettingPassword(true)}
            />
          ) : (
            <EmailSignUp
              email={email}
              setEmail={setEmail}
              emailIsValid={emailIsValid}
              switchToSignin={() => setDisplaySignin(true)}
              setError={setError}
            />
          )
        }

        <Typography level="body-xs">
          By signing up, you agree to our
          {" "}
          <Link href="https://www.resumerover.io/terms-and-conditions">Terms of Service</Link>
          {" "}
          and
          {" "}
          <Link href="https://www.resumerover.io/privacy-policy">Privacy Policy</Link>
          .
        </Typography>
      </Stack>
    );
  }

  return (
    <StyledSheet px={2} py={5}>
      <div
        id="signup"
        ref={props.elementRef}
      >
        {renderInnerContent()}
      </div>
      <ErrorSnackbar error={error} setError={setError} />
    </StyledSheet>
  );
}