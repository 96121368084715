import React from 'react';
import {
  IconButton,
 Stack,
} from '@mui/joy';
import { IconTrash } from '@tabler/icons-react';

import ConfirmationModal from '../../components/modals/Confirmation';

import { useJobListings } from '../../hooks/useJobListings';

export default () => {
  const {
    selectedJobListings,
    deleteSelectedListings,
  } = useJobListings();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);


  if (selectedJobListings.length <= 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      columnGap={1}
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        px: 1,
        width: '100%',
        borderRadius: 24,
        backgroundColor: "#F0F4F8",
        mb: 1,
      }}
    >
      <IconButton
        variant="plain"
        color="neutral"
        onClick={() => setShowConfirmDeleteModal(true)}
      >
        <IconTrash stroke={1} />
      </IconButton>

      <ConfirmationModal
        open={showConfirmDeleteModal}
        close={() => setShowConfirmDeleteModal(false)}
        label="Delete Job Listings?"
        message={`Are you sure you want to delete ${selectedJobListings.length} job listing(s)?`}
        executeButtonColor='danger'
        executeText="Delete Listings"
        executeAction={async () => {
          await deleteSelectedListings();
          setShowConfirmDeleteModal(false);
        }}
      />
    </Stack>
  );
}